import ImpressionsContainer from '@components/Analytics/ImpressionsContainer'
import BasicTableGrid from '@components/BasicTableGrid';
import DistributionBar from '@components/Analytics/DistributionBar'
import FilterDate  from '@components/FilterDate';
import FilterIcon from '@assets/common/filter.png';
import DownIcon from '@assets/common/dropDown.png';
import { AgChartsReact } from 'ag-charts-react';
import PopUp from '@components/PopUp';
import SelectionPopUp from '@components/SelectionPopUp';
import PaymentGatewayDetails from '@components/Analytics/PaymentGatewayDetails';

import moment from 'moment';



function LeadsView(props){
    
    return <div className='AdminPageMainContainer bg-white d-flex gap-3 p-4 overflow-scroll'>

              <div className=" d-flex gap-2">
                    <div className='border rounded p-2 d-flex justify-content-center align-items-center' style={{height:'32px'}}
                        onClick={(e)=>props.handlePopUpOpen(e, 'dateSelectPopUp')}
                    >
                        <img style={{height: '15px'}} src={FilterIcon}/>
                    </div> 
                    <div className='filterSelect'><FilterDate title="Start Date" value={props.startDate} 
                    onChange={(event)=>{props.handleChange('startDate', event.target.value)}}/></div>
                    <div className='filterSelect'><FilterDate title="End Date" value={props.endDate} 
                    onChange={(event)=>{props.handleChange('endDate', event.target.value)}} /></div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="row mx-1" style={{height: '500px'}}>
                             
                            <div className={"col-12 border rounded p-0"}>
                            {(props.isLoading)?
                            <div className='w-100 h-100 d-flex'>

                                <div className="spinner-border text-danger m-auto" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div> 
                            </div>
                            :
                            <div className=' pb-4' style={props.viewGraphBy=='Views'?{height:'87%', justifyContent:'flex-end'}:{height:'100%'}}>
                                    <AgChartsReact options={props.chartOptions} />
                            </div>
                            }
                        </div>

                            
                    </div>
                    <div className="d-flex" style={{height: '400px'}}>
                                        
                        <BasicTableGrid 
                            gridId='AnalyticsOverview'
                            data={props.deferredData} // Data to be displayed
                            fixedCols={2} // How many columns are fixed and not scrolling
                            initialSize = {[35,499,183,258,150,133,262]}
                            initialSorting={[{"id": "Date Added","desc": false}]}
                            handleSelect={(selectedRows) => {
                                props.handleCheckBoxes(selectedRows);
                            }}
                            length={20}
                            onSortChange={(data) => {}
                                // props.handleSort(data)
                            }
                            columnsOptionPopUpRef={props.columnsOptionPopUpRef}
                            isColorEnabled={true}
                            isNavigationHidden={true}
                            columns={[
                            {header: "Admin Users",
                                cell: (data) => {
                                const info = data.row.original;
                                return (<div className="d-flex gap-2">
                                            {info.fullName}
                                        </div>);
                                },
                            },
                            {header: 'Times Lead Claimed', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.totalNumberOfClaims}</div>}
                            },
                            {header: 'Unique Leads', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.totalNumberOfUniqueLeads}</div>}
                            },
                            {header: 'Conversions', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.totalNumberOfConverstions}</div>}
                            },
                            {header: 'Conversion rate(%)', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info?.convertionRate?.toFixed(2)}</div>}
                            },
                            {header: 'Revenue generated (INR)', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.revenueGenrated}</div>}
                            },
                            {header: 'Calls done', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div> {info.totalNumberOfCalls}</div>
                                }
                            },
                            {header: 'Lost leads', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.totalNumberOfLost}</div>}
                            },
                            {header: 'Avg lead open time', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.leadOpenTime}</div>}
                            },
                            
                            ]}

                        />
                </div>
                </div>
                {props.popUp === "dateSelectPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={180}
				left={280}
				handleOutSideClick={props.handlePopUpClose}>
				<div style={{height: 'auto', overflow:'hidden'}}>
					{props.mainDateOption.map((option, index) => (
						<div
							key={index}
							onClick={(e) => option !=='Select' ?props.handlePopUpSave(e, option):props.handlePopUpOpen(e, 'dateYearPopUp')}
							className='settingOption d-flex'>
							{option}
							<img className='ms-auto' src={option.img} style={{height:'12px'}}/>
						</div>
					))}
				</div>
                </PopUp>}
                {props.popUp === "dateYearPopUp"  &&<PopUp visible={props.isPopUpVisible}
                    top={180}
                    left={280}
                    handleOutSideClick={props.handlePopUpClose}>
                    <div style={{height: 'auto', overflow:'hidden'}}>
                        {props.years.map((option, index) => (
                            <div
                                key={index}
                                onClick={(e) => props.handlePopUpOpen(e, 'dateMonthPopUp', option)}
                                className='settingOption d-flex'>
                                {option}
                                <img className='ms-auto' src={option.img} style={{height:'12px'}}/>
                            </div>
                        ))}
                    </div>
                </PopUp>}
                {props.popUp === "dateMonthPopUp"  &&<PopUp visible={props.isPopUpVisible}
                    top={180}
                    left={280}
                    handleOutSideClick={props.handlePopUpClose}>
                    <div style={{height: 'auto', overflow:'hidden'}}>
                        {props.months.map((option, index) => (
                            <div
                                key={index}
                                onClick={(e) => props.handlePopUpSave(e, index)}
                                className='settingOption d-flex'>
                                {option}
                                <img className='ms-auto' src={option.img} style={{height:'12px'}}/>
                            </div>
                        ))}
                    </div>
                </PopUp>}
    </div>
}

export default LeadsView