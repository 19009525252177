import React from 'react';
import moment from 'moment';

import './video.css';

import PlusIcon from '@assets/common/plusIcon.png';
import DropDownArrowIcon from '@assets/common/dropDownArrowIcon.png';
import DeleteIcon from '@assets/common/deleteIcon.png';

import RedEye from '@assets/common/redEyeIcon.png';
import GreenEye from '@assets/common/greenEyeIcon.png';

import EditPencilIcon from '@assets/common/editPencilIcon.png';
import ShareIcon from '@assets/common/shareIcon.png';
import CopyIcon from '@assets/common/copyIcon.png';
import AnalyticsIcon from '@assets/common/analyticsIcon.png';
import CommentsIcon from '@assets/common/commentsIcon.png';

import ActiveUsers from '@assets/common/activeUsersIcon.png';
import UploadIcon from '@assets/common/uploadIcon.png';
import ExpandIcon from '@assets/userDashboard/expand.png';

import BasicTableGrid from '@components/BasicTableGrid';
import TextAndLinks from '@components/TextAndLinks';
import DateAndUser from '@components/DateAndUser';
import ButtonBars from '@components/ButtonBars';
import Search from '@components/Search';

function VideoView(props) {
	return (
		<div ref={props.fullscreenElementRef} style={{ maxWidth: '100%', 
		backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', flexGrow:'1'
		}}>
			<div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[
							{
								type: 'simple',
								text: 'New Video',
								onClick: (event) => {
									props.handleRedirectToHome(0, 'none', 'none');
								},
								disabled: false,
							},
							{
								type: 'dropdownOnClick',
								text: 'Visibility',
								onClick: (event) => {
									props.handleVisibilityBtnClick(event, 'bulk');
								},
								disabled: props.disableBulkActionBtn,
							}
						]}
					/>
					<div className='d-flex gap-2 controlsRight'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						{/* <button onClick={props.handleSettingClick}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button> */}
						{/* <button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button> */}
					</div>
			</div>
		    
			<BasicTableGrid 
				gridId='Videos'
				data={props.deferredData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize = {[35,499,183,258,150,133,262]}
				initialSorting={[{"id": props.sortBy === 'createdAt'? "Date Added":"Date Modified","desc": props.isSorted}]}
				handleSelect={(selectedRows) => {
					props.handleCheckBoxes(selectedRows);
				}}
				length={20}
				onSortChange={(data) => props.handleSort(data)}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
				{header: 'Title',
					cell: (data) => {
					const info = data.row.original;
					return (
						
						<div className="d-flex gap-2">
							{true ? <div className="thumbnailContainer" 
								   style={{
									 backgroundImage:`url(${info.thumbnailUrl})`
									}}
									onDoubleClick={(event) => {
										props.handleDoubleClick(event, info._id, info.title);
									}}
								   >
								</div>
							:
							 ''
							}
								
							{props.isCopied && props.copiedId === info._id  ? 
							<div className="copiedText text-success border-success border rounded p-1" 
							style={{height:"25px", margin:'auto'}}>Copied</div>
							:<TextAndLinks
							title= {info?.title}
							subTitle= {''}
							images = {[EditPencilIcon, CommentsIcon, AnalyticsIcon, ShareIcon, CopyIcon]}
					        onClick={(event, index)=>{props.handleRedirectToHome(index, info._id)}}
							/>
							}					
						</div>
					);
					},
				},
				{header: 'Visibility', 
					cell: (data) => {
						const info = data.row.original;
						return <p className='visibility' 
						          onClick={(event) => {
										props.handleVisibilityBtnClick(event, info._id, info);
									}}>
									<img className='icon-small pe-2' src={info.isPublic? GreenEye : RedEye} alt='Visibility' />
									{info.isPublic? 'Public' : 'Purchase required'}
									<img
										style={{ marginLeft: '5px', width: '12px' }}
										src={DropDownArrowIcon}
										alt='ArrowIcon'
									/>
								</p>
					},
			    },
				{header: 'Date Added', accessorFn: (row) => {return row.createdAt},
					cell: (data) => {
						const info = data.row.original;
						return <DateAndUser
						date = {info.createdAt}
						image = {info.createdUserDetails?.displayPhoto}
						imageText = {info.createdUserDetails?.displayName}/>
					},
			    },
				{header: 'Views', 
					cell: (data) => {
						const info = data.row.original;
						return <div>
									<img className="icon-small" src={ActiveUsers} alt='ActiveUsers' />
									<span className="ps-2">{info.viewsCount}</span>
								</div>
					},
			    },
				{header: 'Comments', 
					cell: (data) => {
						const info = data.row.original;
						return <div>
									<img className="icon-small" src={CommentsIcon} alt='CommentsIcon' />
									<span className="ps-2">{info.commentsCount}</span>
								</div>
					},
			    },
				{header: 'Date Modified', accessorFn: (row) => {return row.updatedAt},
				cell: (data) => {
					const info = data.row.original;
					return (<DateAndUser
						date = {info.updatedAt}
						image = {info.lastModifiedUserDetails?.displayPhoto}
						imageText = {info.lastModifiedUserDetails?.displayName}/>
					) 
				},
			    }
				]}
				/>

			<Search
			    value={props.searchText}
				placeholder = {"Search videos"}
				onSearch ={(data)=>props.handleSearch(data)}
			>
				<div>
					No Filters Added
				</div>
			</Search>

				
			{/* Start : visibilityPopupContainer */}
			{props.is_VisibilityPopupContainerRef_Visible && (
				<>
					<div
						ref={props.visibilityPopupContainerRef}
						style={{
							position: 'absolute',
							top: props.visibilityPopupContainerRef_Position.top,
							left: props.visibilityPopupContainerRef_Position.left,
						}}
						className='visibilityPopupContainer'>
						<div className='visibilityPopupContainer_inner m-2'>
							<p>Save video as:</p>

							<div className='form-check'>
								<input
									onChange={(event) => {
										props.handleVisibilityRadioBtns(event, 'unlisted');
									}}
									checked={props.internalIsUnlisted}
									className='form-check-input'
									type='radio'
									name='visibilityRadioBtn'
									id='visibilityRadioBtn'
								/>
								<label className='form-check-label' htmlFor='visibilityRadioBtn'>
									Purchase required
								</label>
							</div>

							<div className='form-check'>
								<input
									onChange={(event) => {
										props.handleVisibilityRadioBtns(event, 'public');
									}}
									checked={props.internalIsPublic}
									className='form-check-input'
									type='radio'
									name='visibilityRadioBtn'
									id='unlistedRadioBtn'
								/>
								<label className='form-check-label' htmlFor='unlistedRadioBtn'>
									Public
								</label>
							</div>
						</div>

						<div style={{ float: 'right', marginTop: '10px' }}>
							<button
								onClick={(event) => {
									props.handleVisibilityCancleBtnClick(event);
								}}
								className='cancleBtn'>
								Cancle
							</button>

							<button
								disabled={props.disableSaveBtnOfVisibility}
								style={{
									cursor: props.disableSaveBtnOfVisibility
										? 'not-allowed'
										: 'pointer',
								}}
								id='saveVisibilityBtn'
								onClick={(event) => {
									props.handleSaveBtnVisibility(event);
								}}
								className='saveBtn'>
								Save
							</button>
						</div>
					</div>
				</>
			)}
			{/* End : visibilityPopupContainer */}

			{/* Start : thubmnailPopupContainer */}
			{props.is_ThubmnailPopupContainerRef_Visible && (
				<div
					ref={props.thubmnailPopupContainerRef}
					style={{
						position: 'absolute',
						top: props.thubmnailPopupContainerRef_Position.top,
						left: props.thubmnailPopupContainerRef_Position.left,
					}}
					className='thubmnailPopupContainer'>
					<div className='thubmnailPopupContainer_inner'>
						<img
							src={UploadIcon}
							id='coverImage_uploadBtn'
							onClick={(event) => props.handleFileUpload(event)}
							alt='UploadIcon'
						/>

						<p id='coverImage_name'>Upload cover picture</p>

						<input
							type='file'
							className='d-none'
							name='proof'
							accept='image/jpeg,image/jpg,image/png'
							id='coverImage'
							onChange={(event) => props.convertToBase64(event)}
						/>
					</div>

					{props.courseUpdateTilteError && (
						<div className='alert-danger'>
							<span>Any one title or cover img is required* </span>
						</div>
					)}

					<div className='form-floating'>
						<input
							value={props.updatedCourseTitle}
							onChange={(event) => {
								props.handleUpdateCourseTitle(event);
							}}
							type='text'
							className='form-control'
							id='floatingInputInvalid'
							placeholder='Title'
						/>

						<label htmlFor='floatingInputInvalid'>Title(required)</label>
					</div>

					<div style={{ float: 'right', marginTop: '10px' }}>
						<button
							onClick={(event) => {
								props.handleThumbnailCancleBtnClick(event);
							}}
							className='cancleBtn'>
							Cancle
						</button>

						<button
							id='saveThumbnailBtn'
							data-course_id=''
							onClick={(event) => {
								props.handleSaveThumbnail_Title(event);
							}}
							className='saveBtn'>
							Save
						</button>
					</div>
				</div>
			)}
			{/* End : thubmnailPopupContainer */}
					
			
		</div>
	);
}

export default VideoView;
