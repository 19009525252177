import { useEffect, useState, useRef } from "react";
import SidebarNavigation from "@components/SidebarNavigation";
import CustomerCareView from "./view";

import { decryptText } from "@library/enc-dec";
import { checkLoginAndRole } from "@helpers/Login/";
import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";

import { navigationData } from "./navigationData";

import { useNavigate, defer, useLocation } from "react-router-dom";

import * as QueriesServices from "@services/Queries";
import * as FolderServices from "@services/Folders";
import Swal from "sweetalert2";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as TabsActions from "@redux/actions/Tabs";
import { getAllTags } from "@helpers/common";
import * as TagsActions from "@redux/actions/Tags";

var pageData = [];
var listOfCheckedRows = [];
var roleCode, role;

function CustomerCare(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));
  const [deferredData, setDeferredData] = useState({});

  const [searchText, setSearchText] = useState("");
  const [isSorted, setIsSorted] = useState(false);
  const [sortBy, setSortBy] = useState("updatedAt");

  const [folders, setFolders] = useState([]);
  const [activeTab, setActiveTab] = useState(props.tab);

  // START : popup stats
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  // END : popup stats

  // START : states for default button bars
  const fullscreenElementRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  // END : states for default button bars

  // START : states for basic table grid
  // END : states for basic table grid

  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);

  useEffect(() => {
    [role, roleCode] = checkLoginAndRole("customerCare");
    document.addEventListener("click", handleClickOutside); // code for handleColumnBtnClick
    getTags();
    return () => {
      document.removeEventListener("click", handleClickOutside); // code for handleColumnBtnClick
    };
  }, []);

  useEffect(() => {
    getAllFolders();
  }, [props.access]);

  useEffect(() => {
    getCustomerCareData();
  }, [page, limit, activeTab, isSorted, sortBy, searchText]);

  const getTags = async () => {
    try {
      const allTags = await getAllTags("customerCare");
      if (allTags) {
        props.updateTags(allTags);
      } else {
        console.log("Error coming while getting all Tags");
      }
    } catch (err) {
      console.log("Error coming while getting all Tags", err);
    }
  };

  const handleClickOutside = (event) => {
    if (
      columnsOptionPopUpRef.current &&
      !columnsOptionPopUpRef.current.contains(event.target)
    ) {
      columnsOptionPopUpRef.current.style.display = "none";
    }
  };
  const getAllFolders = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      pageNo: "0",
      limit: "10000",
      isSorted: true,
      sortBy: "title",
      searchText: "",
      visibility: "active",
    };
    const response = await FolderServices.getAllFolders(payload, authToken);
    var allFolders = response.data;
    allFolders = allFolders
      .map((folder) => (folder.pageType === "customerCare" ? folder : null))
      .filter(Boolean);

    if (roleCode === 2 || roleCode === "2") {
      if (props.access?.customerCareRights) {
        allFolders = allFolders.filter((folder) =>
          props.access.customerCareRights.includes(folder._id)
        );
      } else {
        allFolders = [];
      }
    }

    var defaultFolders = [
      {
        _id: "new",
        title: "New Ticket",
        isActive: true,
        titleTag1: { text: "", bgColor: "#EB4242", fontColor: "white" },
      },
      {
        _id: "underProgress",
        title: "Under Progress",
        isActive: true,
        titleTag1: { text: "", bgColor: "#EB4242", fontColor: "white" },
      },
      { _id: "solved", title: "Solved", isActive: true },
      { _id: "archive", title: "Archive", isActive: true },
    ];

    allFolders = [...defaultFolders, ...allFolders];
    const foldersCount = await getFoldersCount();

    foldersCount.forEach((folderCount) => {
      allFolders = allFolders.map((folder) => {
        if (
          folder._id === folderCount.folderName ||
          folder.title === folderCount.folderName
        ) {
          if (!folder.titleTag1) {
            folder.titleTag1 = {
              text: "",
              bgColor: "#B6B6B6",
              fontColor: "white",
            };
          }
          folder.titleTag1.text = folderCount.count;
        }

        return folder;
      });
    });

    setFolders(allFolders);
  };

  const getFoldersCount = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    let payload = { userId: "" };
    if (props?.userId) {
      payload.userId = props.userId;
    }
    const response = await QueriesServices.getQueriesFoldersCount(
      payload,
      authToken
    );
    if (response.success) {
      return response.data;
    }
    return null;
  };

  const getCustomerCareData = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    let payload = {
      pageNo: page,
      limit: limit ? limit : 20,
      isSorted: isSorted,
      sortBy: sortBy,
      searchText: searchText,
      queryStatus:
        activeTab._id === "new" ||
        activeTab._id == "underProgress" ||
        activeTab._id == "solved"
          ? activeTab._id
          : "archive",
      userId: "",
      startDate: "",
      endDate: "",
    };
    if (props?.userId) {
      payload.userId = props.userId;
    }
    var deferredResponse = defer({
      res: QueriesServices.getAllQueries(payload, authToken),
    });
    setDeferredData(deferredResponse);

    deferredResponse.data.res
      .then((response) => {
        pageData = response;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };
  const updateQueriesStatus = async (data) => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      status: data.ticketStatus,
      queryIds: listOfCheckedRows.map((query) => query._id),
    };
    const response = await QueriesServices.updateQueriesStatus(
      payload,
      authToken
    );
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      if (data.ticketStatus == "new") {
        props.updateTab(folders[0]);
        navigate(".", { replace: true });
      } else if (data.ticketStatus === "underProgress") {
        props.updateTab(folders[1]);
        navigate(".", { replace: true });
      } else if (data.ticketStatus === "solved") {
        props.updateTab(folders[2]);
        navigate(".", { replace: true });
      }else if (data.ticketStatus === "archive") {
        props.updateTab(folders[3]);
        navigate(".", { replace: true });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedRows = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };
  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    columnsOptionPopUpRef.current.style.display = "none";
  };
  const handlePopUpSave = (data) => {
    if (popUp === "changeTicketStatus") {
      updateQueriesStatus(data);
    }
    handlePopUpClose();
    // getAllBssForms();
    setDisableBulkActionBtn(true);
  };
  const handleColumnBtnClick = (event) => {
    handlePopUpClose();
    columnsOptionPopUpRef.current.style.display = "block";
    var position = getEventElementPosition(event);
    columnsOptionPopUpRef.current.style.top = position.top + "px";
    columnsOptionPopUpRef.current.style.left = position.left - 160 + "px";
    event.stopPropagation();
  };
  const handleSearch = (data) => {
    setSearchText(data.text);
  };
  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      getCustomerCareData();
    }
  };
  const handleSort = (data) => {
    const mapping = { "Date/Time": "createdAt", "Date Modified": "updatedAt" };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    setSortBy(id);
    setIsSorted(data[0]?.desc ? data[0].desc : false);
  };

  // START : fn for navigation
  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };
  const handleRedirectToHome = (event, queryDocId, defaultComponent) => {
    //Handle setting default page while navigating
    let finalNavigationData = [];
    finalNavigationData = navigationData;
    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      finalNavigationData = setDefaultNavigation("CustomerCare");
    }

    if (queryDocId === "none") {
      navigate("/customer-care", { state: { finalNavigationData } });
    } else {
      navigate("/query/" + queryDocId, { state: { finalNavigationData } });
    }
  };
  // END : fn for navigation
  const handleTabClick = (tab) => {
    props.updateTab(tab);
    navigate(".", { replace: true });
  };
  useEffect(() => {
    setActiveTab(props.tab);
  }, [props.tab]);

  const moveToArchive=async()=>{
    updateQueriesStatus({ticketStatus:"archive"})
  }

  const handleClearFilter = (index) => {
      if (index === 0) {
        setSearchText("")
         }
  };

  return (
    <>
      {props?.userId === undefined ? <SidebarNavigation /> : ""}
      <CustomerCareView
        searchText={searchText}
        folders={folders}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        // START : states for popup
        popUp={popUp}
        isPopUpVisible={isPopUpVisible}
        popUpPosition={popUpPosition}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        // END : states for popup

        disableBulkActionBtn={disableBulkActionBtn}
        // START : states for default button bars
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        handleColumnBtnClick={handleColumnBtnClick}
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        // END : states for default button bars

        // START : states for basic table grid
        handleCheckBoxes={handleCheckBoxes}
        // END : states for basic table grid

        deferredData={deferredData}
        handleRedirectToHome={handleRedirectToHome}
        handleSort={handleSort}
        handleSearch={handleSearch}
        handleSearchInput={handleSearchInput}

        moveToArchive={moveToArchive}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tab: state.tabs.CustomerCareTab,
    access: state.login.access,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTab: TabsActions.updateCustomerCareTab,
      updateTags: TagsActions.updateTags,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCare);
