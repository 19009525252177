import React, { useState, useEffect } from 'react';
import DoctorProofView from './view';

import * as VerificationServices from '@services/Verification';
import * as VerificationActions from '@redux/actions/Verification';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { decryptText } from '@library/enc-dec';


function DoctorProof(props) {
	let authToken = decryptText(localStorage.getItem('aEmediat'));
	const [selectedUsers, setSelectedUsers] = useState([]);
    const [iscardVisible, setIscardVisible] = useState(false);
    const [selectedDoctorProof, setSelectedDoctorProof] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false)

	const [fromInputDate, setFromInputDate] = useState('');
	const [toInputDate, setToInputDate] = useState('');

	useEffect(() => {
		getPendingDoctorProof();
	}, [fromInputDate, toInputDate]);

	// Get All the verification data from API service 
	const getPendingDoctorProof = async () => {
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		let payload ={
			toInputDate,
			fromInputDate
		}
		let response = await VerificationServices.getPendingDoctorProof(payload, authToken);
		if (response.success) {
			props.updatePendingDoctorProof([...response.data]);
			return;
		}
		alert(response.message);
	};

	const verifySingleDoctorProof = async(userId, verificationType, status)=>{
		closeVerificationPopUp()
		let response = await VerificationServices.updateDocumentStatus(authToken, userId, verificationType, status)
	    if (response.success){
			getPendingDoctorProof()
		}
		alert(response.message)
	}

	const verifyMultipleDoctorProof = async(userIdList, verificationType, status)=>{
		if(selectedUsers.length===0)
		{
			alert("No User selected")
			return
		}
		let response = await VerificationServices.updateMultipleDocumentStatus(authToken, userIdList, verificationType, status)
	    if (response.success){
			getPendingDoctorProof()
		}
		alert(response.message)
	}

	/////////////////////
	const updateSelectedUsers = (id)=>{
		let updatedUsers = [...selectedUsers]
		 const index = updatedUsers.indexOf(id);
		 if (index !== -1) {
			 updatedUsers.splice(index, 1);
		 } else {
			 updatedUsers.push(id);
		 }
		setSelectedUsers(updatedUsers) 
	 }
 
	 const selectAllDocuments = () =>{ 
 
		 if( ! isAllSelected)
		 {
			let allUserIds = []
			 props.pendingDoctorProof.forEach(proof => {
				 allUserIds.push(proof._id)
			 });
 
			 setSelectedUsers(allUserIds)
		 }
		 else{
			 setSelectedUsers([])
		 }
 
		 setIsAllSelected(!isAllSelected)
	 }
 
	 const openVerificationPopUp =(idProof)=>{
		 setSelectedDoctorProof(idProof)
		 setIscardVisible(true)
	 }
 
	 const closeVerificationPopUp =()=>{
		 setIscardVisible(false)
	 }
 
	 const handleMenuVisibility=(type)=>{
		 setIsMenuVisible(!isMenuVisible)
		 if(type=='main' || type== undefined){
			 return
		 }
		 let date = new Date();
		 let firstDay = ''
		 let lastDay = ''
		 if(type=="today"){
			firstDay = date;
			lastDay = firstDay;
			 setFromInputDate(firstDay.toISOString().substring(0, 10));
			 setToInputDate(lastDay.toISOString().substring(0, 10));
 
		 }else if(type=="month"){
			 firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
			 lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
			 setFromInputDate(firstDay.toISOString().substring(0, 10));
			 setToInputDate(lastDay.toISOString().substring(0, 10));
		 }
		 else if(type=="week"){
			 var curr = new Date; // get current date
			 var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
			 var last = first + 6; // last day is the first day + 6
 
			 firstDay = new Date(curr.setDate(first));
			 lastDay = new Date(curr.setDate(last));
			 setFromInputDate(firstDay.toISOString().substring(0, 10));
			 setToInputDate(lastDay.toISOString().substring(0, 10));
 
		 }
		 else if(type=="year"){
			 firstDay = new Date(date.getFullYear(), 0, 2);
			 lastDay = new Date(date.getFullYear(), 12, 1);
			 setFromInputDate(firstDay.toISOString().substring(0, 10));
			 setToInputDate(lastDay.toISOString().substring(0, 10));
		 }
		 else if(type=="all"){
			setFromInputDate('');
			setToInputDate('');
		 } 
	 }
	
	return (
		<>
			<DoctorProofView
			  popUpImg = {selectedDoctorProof.marksheetOrDegreeCert}
			  popUpName = {selectedDoctorProof.fullName}
			  popUpContact = {selectedDoctorProof.countryCode +" "+selectedDoctorProof.contactNumber}
			  popUpWhatsapp = {selectedDoctorProof.whatsAppCountryCode +" "+ selectedDoctorProof.whatsAppNumber}
			  popUpEmail = {selectedDoctorProof.email}
			  popUpQualification={selectedDoctorProof.isUnderGraduate ? selectedDoctorProof.underGraduateDegree : selectedDoctorProof.qualification}
			  popUpCourses = {selectedDoctorProof.courses}
			  handlePopUpVerifyButton = {()=>{verifySingleDoctorProof(selectedDoctorProof._id,'marksheetStatus', 'Verified')}}
			  handlePopUpRejectButton = {()=>{verifySingleDoctorProof(selectedDoctorProof._id,'marksheetStatus', 'Rejected')}}
			  handlePopUpPopUpCheckBox = {()=>{updateSelectedUsers(selectedDoctorProof._id)}}
			  handlePopUpBackButton = {()=>closeVerificationPopUp()}
			  isPopUpVisible = {iscardVisible}
			  isPopUpChecked = { selectedUsers.includes(selectedDoctorProof._id) }

			  cardGridData = {props.pendingDoctorProof}
			  selectedCards= {selectedUsers}
			  handleGridCardClick = {(data)=>{openVerificationPopUp(data)}}
			  handleGridCheckBox = {(id)=>updateSelectedUsers(id)}

			  isFilterChecked = {isAllSelected}
			  handleFilterCheckbox = {()=>{selectAllDocuments()}} 
			  handleVerifyAllButton = {()=>{verifyMultipleDoctorProof(selectedUsers, 'marksheetStatus', 'Verified')}}
			  handleRejectAllButton = {()=>{verifyMultipleDoctorProof(selectedUsers, 'marksheetStatus', 'Rejected')}}
			  
			  isFilterMenuVisible = {isMenuVisible}
			  handleFilterMenuVisibility = {(option)=>{handleMenuVisibility(option)}}
			  toggleFullscreen={props.toggleFullscreen}
			  />
		</>
	);
}



const mapStateToProps = (state) => {
	return {
		pendingDoctorProof: state.verification.pendingDoctorProof
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updatePendingDoctorProof: VerificationActions.updatePendingDoctorProof
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorProof);

