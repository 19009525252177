import React, { useEffect, useState } from "react";

import AddCoursePopUpView from "./view";
import { listofPaymentModesApi } from "./data";
import moment from "moment";

import * as CategoryServices from "@services/Category";
import * as CoursesServices from "@services/Course";
import * as UsersServices from "@services/Users";
import Swal from "sweetalert2";
import * as FormalRequestServices from "@services/FormalRequest";

import { decryptText } from "@library/enc-dec";
const stripeGatewayDetails = {
  type: "stripe",
  title: "International Credit/Debit Card",
  icon: "https://cdn-icons-png.flaticon.com/512/5968/5968382.png",
  discount: 0,
};
const razorPayGatewayDetails = {
  type: "razorpay",
  title: "Domestic and International Credit & Debit cards",
  icon: "https://cdn.iconscout.com/icon/free/png-256/free-razorpay-1649771-1399875.png",
  discount: 0,
};
const eazyPayGatewayDetails = {
  type: "eazypay",
  title: "Domestic and International Credit & Debit cards",
  icon: "https://i.gadgets360cdn.com/large/icici_eazypay_screenshot_small_1482839992737.png",
  discount: 0,
};
const ccavenueGatewayDetails = {
  type: "ccavenue",
  title: "Domestic and International Credit & Debit cards",
  icon: "https://marketplace.appthemes.com/files/2017/09/app-ccavenue-title-screenshot.png",
  discount: 0,
};

function AddCoursePopUp(props) {
  const [listOfCategory, setListOfCategory] = useState([]);
  const [listOfCourses, setListOfCourses] = useState([]);
  const [listOfPaymentModes, setListOfPaymentModes] = useState([]);
  const [category, setCategory] = useState("");
  const [course, setCourse] = useState("");
  const [error, setError] = useState("");
  const [newCourseList, setNewCourseList] = useState([]);

  const [cDate, setcDate] = useState(moment().format("YYYY-MM-DD"));
  const [paymentMode, setPaymentMode] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [spreadSheetLink, setSpreadSheetLink] = useState("");
  const [orderId, setOrderId] = useState("");

  const [isAddCourseOnChecked, setIsAddCourseOnChecked] = useState(false);
  const [courseAddOnDate, setCourseAddOnDate] = useState("");
  const [courseAddOnTime, setCourseAddOnTime] = useState("");

  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const [isFormSaving, setIsFormSaving] = useState(false);
  const [formalRequestText, setFormalRequestText] = useState("");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

  const addNewCourse = (event) => {
    let newCourseData = {};
    listOfCourses.forEach((courseData) => {
      if (courseData._id === course) {
        // courseData.crossedPriceInNumbers = courseData.prices[0].crossedPrice;
        // courseData.offerPriceInNumbers = courseData.prices[0].offerPrice;
        // courseData.crossedPrice = '₹' + courseData.prices[0].crossedPrice + '/-';
        // courseData.offerPrice = '₹' + courseData.prices[0].offerPrice + '/-';
        courseData.courseId = courseData._id;

        newCourseData = courseData;
      }
    });

    if (category === "" || course === "") {
      setError("Category and Course cant be empty");
      setTimeout(function () {
        setError("");
      }, 2000);
    } else {
      setError("");
      setNewCourseList((prevCourseList) => [...prevCourseList, newCourseData]);
    }
  };

  const handleDeleteCourse = (event, courseId) => {
    const updatedCourses = newCourseList.filter(
      (course) => course._id !== courseId
    );
    setNewCourseList(updatedCourses);
    event.stopPropagation();
  };

  const addNewCouseClosePupupBtn = () => {
    setError("");
    if (document.getElementById("addNewCouseClosePupupBtn")) {
      document.getElementById("addNewCouseClosePupupBtn").click();
    }
    props.handleClose();
  };

  const saveNewCouse = async () => {
    setIsFormSaving(true)
    if (
      cDate === "" ||
      paymentMode === "" ||
      (paymentMode !== "free" &&
        paymentMode !== "external" &&
        paidAmount === "") ||
      (paymentMode !== "free" &&
        paymentMode !== "external" &&
        transactionId === "") ||
      (paymentMode !== "free" &&
        paymentMode !== "external" &&
        orderId === "") ||
      newCourseList === "" ||
      newCourseList.length === 0 ||
      (isAddCourseOnChecked &&
        courseAddOnDate === "" &&
        courseAddOnTime === "") ||
      (paymentMode === "external" && spreadSheetLink === "")
    ) {
      setError("All the input files are needed");

      setIsFormSaving(false)
      setTimeout(function () {
        setError("");
      }, 2000);
    } else {
      if (!props.userIds) {
        var isSaved = await props.handleSave({
          cDate: cDate,
          paymentMode: paymentMode,
          paidAmount: paidAmount,
          transactionId: transactionId,
          orderId: orderId,
          newCourseList: newCourseList,
        });    
        setIsFormSaving(false)
      } else {
        // handleSave()

        try {
          let authToken = decryptText(localStorage.getItem("aEmediat"));

          if(props.isFormalRequest)
          {
            var payload = {
              description: formalRequestText,
              users: props.userIds.map((id)=> { 
                return {userId: id, purchaseId : null}
              }),
              bssFromDate: null,
              bssEndDate: null,
              currentCourseIds: [...new Set(newCourseList.map((item) => item._id))],
              replacedCourseIds: [],
              location: "users", // users=> certificate or other admin panel pages
              requestType: "freeCourse",
              courseEnableAt: null,
              bssCourseId: null,
              bssInstituteId: null,
              courses: [],
              coursesMnemonic: [
                ...new Set(newCourseList.map((item) => item.mnemonic)),
              ].join("+"),
              paymentAt: moment(cDate).toISOString(),
              courseAddAt: isAddCourseOnChecked
              ? moment(`${courseAddOnDate}T${courseAddOnTime}:00`).toISOString()
              : moment(cDate).toISOString(),

              };
  
              var response = await FormalRequestServices.addFormalRequest(
              payload,
              authToken
              );
          }
          else{
          const payload = {
            userIds: props.userIds,
            courseIds: [...new Set(newCourseList.map((item) => item._id))],
            paymentMethod: paymentMode==='external'? 'externalLink':paymentMode,
            paymentId: transactionId,
            orderId: orderId,
            amountPaidInINR: paidAmount,
            coursesMnemonic: [
              ...new Set(newCourseList.map((item) => item.mnemonic)),
            ].join("+"),
            gatewayDetails:
              paymentMode === "eazyPay"
                ? eazyPayGatewayDetails
                : paymentMode === "razorPay"
                ? razorPayGatewayDetails
                : paymentMode === "stripe"
                ? stripeGatewayDetails
                : paymentMode === "ccavenue"
                ? ccavenueGatewayDetails
                : null,
            paymentDate: moment(cDate).toISOString(),
            courseAddDate: isAddCourseOnChecked
              ? moment(`${courseAddOnDate}T${courseAddOnTime}:00`).toISOString()
              : moment(cDate).toISOString(),
            externalLink: spreadSheetLink,
          }; 

          const response = await UsersServices.addCourseForUsers(
            payload,
            authToken
          );
        }

          if (response.success) {
            Swal.fire({
              icon: "success",
              title: response.message,
              showConfirmButton: false,
              timer: 2500,
            });
            props.handleSave();
          } else {
            Swal.fire({
              icon: "error",
              title: response.message,
              showConfirmButton: false,
              timer: 2500,
            });
            props.handleClose();
          }
          
        setIsFormSaving(false)

        } catch (err) {
          console.log("Error coming while updating blogs", err);
          
        setIsFormSaving(false)

        }
        props.handleSave();
      }
    }
  };

  useEffect(() => {
    setListOfPaymentModes(listofPaymentModesApi);
    getAllCategories();
    getAllCourses();
  }, [props.visible]);

  const getAllCourses = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CoursesServices.getAllCourses(
        { pageNo: 0, limit: 99999 },
        authToken
      );

      if (response.success) {
        setListOfCourses(response.data);
      }
    } catch (err) {
      console.log("Error coming while fetching courses in category", err);
    }
  };
  const getAllCategories = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CategoryServices.getAllCategories(
        { pageNo: 0, limit: 99999 },
        authToken
      );

      if (response.success) {
        setListOfCategory(response.data);
      }
    } catch (err) {
      console.log("Error coming while fetching categories in categories", err);
    }
  };

  const handleCdate = (event) => {
    setcDate(event.target.value);
  };
  const handlePaymentMode = (event) => {
    setPaymentOption(event.target.value);
  };
  const handlePaidAmount = (event) => {
    setPaidAmount(event.target.value);
  };
  const handleTransactionId = (event) => {
    setTransactionId(event.target.value);
  };
  const handleOrderId = (event) => {
    setOrderId(event.target.value);
  };

  const handlePopUpOpen = (event) => {
    setIsPopUpVisible(true);
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePopUpClose = (event) => {
    setIsPopUpVisible(false);
    if (event) {
      event.stopPropagation();
    }
  };

  // To handleSave buttons on the popup
  const handlePopUpSave = (event) => {
    handlePopUpClose(event);
    setPaymentMode(paymentOption);
    event.stopPropagation();
  };

  return (
    <AddCoursePopUpView
      userIds={props.userIds}
      listOfCategory={listOfCategory}
      listOfCourses={listOfCourses}
      listOfPaymentModes={listOfPaymentModes}
      category={category}
      course={course}
      handleCategoryChange={handleCategoryChange}
      handleCourseChange={handleCourseChange}
      addNewCourse={addNewCourse}
      error={error}
      newCourseList={newCourseList}
      handleDeleteCourse={handleDeleteCourse}
      addNewCouseClosePupupBtn={addNewCouseClosePupupBtn}
      saveNewCouse={saveNewCouse}
      handleCdate={handleCdate}
      handlePaidAmount={handlePaidAmount}
      handleTransactionId={handleTransactionId}
      handleOrderId={handleOrderId}
      cDate={cDate}
      paymentMode={paymentMode}
      paidAmount={paidAmount}
      transactionId={transactionId}
      orderId={orderId}
      spreadSheetLink={spreadSheetLink}
      setSpreadSheetLink={setSpreadSheetLink}
      isAddCourseOnChecked={isAddCourseOnChecked}
      setIsAddCourseOnChecked={setIsAddCourseOnChecked}
      courseAddOnDate={courseAddOnDate}
      setCourseAddOnDate={setCourseAddOnDate}
      courseAddOnTime={courseAddOnTime}
      setCourseAddOnTime={setCourseAddOnTime}
      isPopUpVisible={isPopUpVisible}
      handlePaymentMode={handlePaymentMode}
      handlePaymentClick={handlePopUpOpen}
      handlePopUpClose={handlePopUpClose}
      handlePopUpSave={handlePopUpSave}
      isFormSaving={isFormSaving}
      formalRequestText={formalRequestText}
      setFormalRequestText={setFormalRequestText}
      isFormalRequest={props.isFormalRequest}
    />
  );
}

export default AddCoursePopUp;
