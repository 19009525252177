import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, defer, useLocation} from 'react-router-dom';

import moment from 'moment';


import {colors} from '@assets/colors.js'
import { decryptText } from '@library/enc-dec';
import { produce } from 'immer';
import Swal from 'sweetalert2';
import {getEventElementPosition, toggleFullscreen} from '@helpers/common.js';
import LeadsView from './view';
import * as AnalysisServices from '@services/Analysis';

const mainDateOption=['Today','Yesterday','This Month','This Week', 'This Year', 'Last 7 days','Last 30 days','Last 180 days','Last 360 days', 'This financial year','All time','Select']
const years = ['2022','2023','2024']
const months= ['January','February','March','April','May','June','July','August','September','October','November','December']


var listOfCheckedRows = [];
const currentDate = new Date();

const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

const startDateStr = startDate.toISOString().split('T')[0];
const endDateStr = endDate.toISOString().split('T')[0];


function Leads(props){   
	const fullscreenElementRef = useRef(null);
	const columnsOptionPopUpRef = useRef(null);
	const [selectedYear, setSelectedYear] = useState(null);
	
    const [startDate, setStartDate] = useState(startDateStr)
    const [endDate, setEndDate] = useState(endDateStr)

    useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				columnsOptionPopUpRef.current &&
				!columnsOptionPopUpRef.current.contains(event.target)
			) {
				columnsOptionPopUpRef.current.style.display = 'none';
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);
	const [deferredData, setDeferredData] = useState({});
	const [searchText, setSearchText] = useState('');
	const [isSorted, setIsSorted] = useState(false);
	const [sortBy, setSortBy] = useState('updatedAt');
    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const limit = Number(queryParams.get('limit'));
	const page = Number(queryParams.get('page'));
	const [popUp, setPopUp] =useState('');
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [popUpPosition, setPopUpPosition] = useState({});

	const [pageData, setPageData] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [chartOptions, setChartOptions] =useState({
        // Data: Data to be displayed in the chart
        data: [
            { month: 'Jan', views: 209, uniqueViews: 162 },
            { month: 'Feb', views: 209, uniqueViews: 162 },
            { month: 'Mar', views: 4603, uniqueViews: 3020 },
            { month: 'Apr', views: 1209, uniqueViews: 1162 },
            { month: 'May', views: 8162, uniqueViews: 8000 },  
            { month: 'Jun', views: 3209, uniqueViews: 2162 },
            { month: 'Jul', views: 1228, uniqueViews: 1250 },
            { month: 'Sep', views: 10145, uniqueViews: 9500 },
            { month: 'Nov', views: 2809, uniqueViews: 2000 },
        ],
        // Series: Defines which chart type and data to use
        series: [{ type: 'bar', xKey: 'fullName', yKey: 'totalNumberOfClaims', yName: "Claims", color:'red' }],
		connectMissingData:true,
        axis:[
            {type: 'number', position:'bottom'},
            { type: 'log', position: 'left', nice: false, base:10, tick: {
				minSpacing: 200,
			  },},
    ],
	animation: {
		enabled: true,
		duration:300
	},
	legend:{
		enabled:true,
		position:'top'
	},
	fill: ["red"]
      })

	useEffect(()=>{
        getLeadsData()
    },[startDate, endDate])

	const getLeadsData=async()=>{
		setIsLoading(true)
        let authToken = decryptText(localStorage.getItem('aEmediat'));
		let payload = {
                            startDate:startDate,
                            endDate : endDate
                        };

		

        var deferredResponse = defer({ res: AnalysisServices.getLeadReport(payload, authToken)
			.then(preprocessData).catch(error=>{
				return null
			}) });
        
		setDeferredData(deferredResponse)

        deferredResponse.data.res.then(response => {
            setPageData(response)
			setChartOptions(
				produce(chartOptions,(draft)=>{
					draft.data = response.data
					console.log(response.data,"leads")
				})
			)
			setIsLoading(false)
        })
        .catch(error => {
            Swal.fire({icon: "error",title: error.message,showConfirmButton: false,timer: 2500});
			setIsLoading(false)
        });

		// if(response.success)
		// {
		// 	updateGraphByRevenueData(response.data)
		// }
                                       
    }


	const preprocessData = (response) => {
		if(!response.status===200)
			{ return null}
		response.pageLimit = 1000
		response.currentDocCount=Object.keys(response.data).length
		response.totalDocCount=Object.keys(response.data).length
		response.data = Object.keys(response.data).map((key,index)=>{
			response.data[key].color = colors[index%colors.length]
			return response.data[key]
		})
		return response
	}

       //Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedRows = selectedRows;
		// setDisableBulkActionBtn( selectedRows.length>0 ? false : true)
	};
	// To sort 
	const handleSort = (data) => {
		const mapping = {'Date Added': 'createdAt', 'Date Modified': 'updatedAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};
    const handleColumnBtnClick = (event) => {
		handlePopUpClose();
		columnsOptionPopUpRef.current.style.display = 'block';
		var position = getEventElementPosition(event);
		columnsOptionPopUpRef.current.style.top = position.top + 'px';
		columnsOptionPopUpRef.current.style.left = position.left - 160 + 'px';
		event.stopPropagation();
	};
    	// To close the popup container
	const handlePopUpOpen = (event, popUpName, data) => {
		if(popUpName==='dateMonthPopUp')
			{
				setSelectedYear(data)
			}
		handlePopUpClose()
		setPopUp(popUpName)
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
	};
	const handlePopUpClose = () => {
		setIsPopUpVisible(false)
		columnsOptionPopUpRef.current.style.display = 'none';
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async (e, data) => {

		if(popUp === "dateMonthPopUp"){
			let selectedMonth = data
			const startDate = moment([selectedYear, selectedMonth]); // Month is zero-indexed
			const endDate = startDate.clone().endOf('month');
			const formattedStartDate = startDate.format('YYYY-MM-DD');
			const formattedEndDate = endDate.format('YYYY-MM-DD');
			setStartDate(formattedStartDate)
			setEndDate(formattedEndDate)
		}    else if(mainDateOption.includes(data)) {
        const {formattedStartDate, formattedEndDate} = calculateDates(data)
        setStartDate(formattedStartDate)
         setEndDate(formattedEndDate)
    }
		handlePopUpClose();
		// getAllBssForms();
		// setDisableBulkActionBtn(true);
	};
	
    const handleChange = (field, value) => {
		if(field ==='startDate'){
			setStartDate(value)
		}else if(field==='endDate'){
			setEndDate(value)
		}
	};
	function calculateDates(selectedOption) {
		const today = moment().startOf('day');
		let startDate, endDate;
  
		switch (selectedOption) {
			case 'Today':
				startDate = today.clone().startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Yesterday':
				startDate = today.clone().subtract(1, 'day').startOf('day');
				endDate = today.clone().subtract(1, 'day').endOf('day');
				break;
			case 'This Month':
				startDate = today.clone().startOf('month');
				endDate = today.clone().endOf('month');
				break;
			case 'This Week':
				startDate = today.clone().startOf('isoWeek');
				endDate = today.clone().endOf('isoWeek');
				break;
			case 'This Year':
				startDate = today.clone().startOf('year');
				endDate = today.clone().endOf('year');
				break;
			case 'Last 7 days':
				startDate = today.clone().subtract(6, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 30 days':
				startDate = today.clone().subtract(29, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 180 days':
				startDate = today.clone().subtract(179, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 360 days':
				startDate = today.clone().subtract(359, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'This financial year':
				let date = getCurrentFinancialYear();
				startDate = date.startDate;
				endDate= date.endDate;
				break;
			case 'All time':
				startDate = moment(0); // Unix epoch
				endDate = today.clone().endOf('day');
				break;
			default:
				// Handle 'Select' or unknown option
				startDate = null;
				endDate = null;
				break;
		}
		const formattedStartDate = startDate.format('YYYY-MM-DD');
		const formattedEndDate = endDate.format('YYYY-MM-DD');
		return { formattedStartDate, formattedEndDate };
	}

	function getCurrentFinancialYear() {
		const today = moment();
		const currentMonth = today.month();
		const currentYear = today.year();
  
		let startYear, endYear;
  
		// If current month is April or later, financial year starts in the current year
		if (currentMonth >= 3) { // April is the fourth month (zero-based index)
			startYear = currentYear;
			endYear = currentYear + 1;
		} else { // Otherwise, financial year started in the previous year
			startYear = currentYear - 1;
			endYear = currentYear;
		}
  
		const startDate = moment(`${startYear}-04-01`).startOf('day');
		const endDate = moment(`${endYear}-03-31`).endOf('day');
  
		return { startDate, endDate };
	}

    return <LeadsView 
                deferredData={deferredData}
                handleCheckBoxes={handleCheckBoxes}
                handleSort={handleSort}
                
				mainDateOption={mainDateOption}
				years={years}
				months={months}
				startDate={startDate}
				endDate={endDate}
                setStartDate={setStartDate}
				setEndDate={setEndDate}
				

				// Start: Control Pop Up 
				popUp={popUp}
				popUpPosition = {popUpPosition}
				isPopUpVisible = {isPopUpVisible}
				handlePopUpClose = {handlePopUpClose} 
				handlePopUpOpen = {handlePopUpOpen} 
				handlePopUpSave= {handlePopUpSave}

				// Fullscreen
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
				//ColumnButton and popup
				columnsOptionPopUpRef={columnsOptionPopUpRef}
				handleColumnBtnClick={handleColumnBtnClick}

				handleChange={handleChange}
				
                chartOptions={chartOptions}
    
    />
}


export default Leads