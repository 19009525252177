import React from 'react';

import UserHomeView from './view';

import { useLocation } from 'react-router-dom';

import InnerSidebarNavigation from '@components/InnerSidebarNavigation';
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import {navigationData} from './data';

function UserHome() {
	const location = useLocation();
    var navData = navigationData;
	if (location.state && location.state.finalNavigationData) {
		navData = location.state.finalNavigationData;
	}

	return (
		<>
			<InnerSidebarNavigation navigationData={navData} />
			<UserHomeView />
		</>
	);
}

export default UserHome;
