import './style.css'
import profilePlaceholder from '@assets/common/placeholder.png';

import {colors} from '@assets/colors';

const User = (props)=>{
    let imageSrc = props.image;
    
    return <div className="user-image-container">
                {
                  imageSrc && imageSrc !=="undefined" && imageSrc !== undefined ?  
                    <img src={imageSrc} className="image rounded-circle"  style={props.size? {width: props.size, height:props.size}:{width: '25px', height:'25px'}}/>
                    :
                    <div className='profileIcon rounded-circle justify-content-center align-items-center d-flex text-white'
                    style={{background:colors[props?.imageText? props?.imageText[0].toLowerCase().charCodeAt(0)-97 : 0], 

                        width: props.size? props.size: '25px', height:props.size? props.size:'25px', lineHeight: 'initial',
                        fontSize: props?.fontSize? props?.fontSize:'12px',
                    }}>
                        {props?.imageText? props?.imageText[0]: '-'}			
                    </div>
                }
                <span className="username">{props.imageText}</span>
            </div>
}


export default User