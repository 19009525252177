import React, { useEffect, useState, useRef  } from 'react';
import PopUpContainer from '@components/PopUpContainer';


function RemoveUserPopUp(props) {

	const handleSave=()=>{
		 props.handlePopUpSave()
	 }

	return (
		<PopUpContainer
						title={"Replace courses"}
						isSearchEnabled={false}
						searchPlaceholder={""}
						isButtonsEnabled={true}
						style={{width:'431px', height: '126px'}}
						handleSearch={()=>{}}
						handleCancel={props.handlePopUpClose}
						handleSave={handleSave}
						>
					<div className='d-flex flex-column gap-2'>
						<div>Are you sure you want to remove the user(s) from this folder?</div>
					</div>
				</PopUpContainer>
	);
}

export default RemoveUserPopUp;
