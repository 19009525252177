import Search from '@components/Search';
import DynamicTabs from '@components/DynamicTabs';
import ButtonBars from '@components/ButtonBars';
import PopUp from '@components/PopUp';
import BasicTableGrid from '@components/BasicTableGrid';
import PhoneNumber from '@components/PhoneNumber';
import DateAndUser from '@components/DateAndUser';
import TextAndLinks from '@components/TextAndLinks';

import './style.css';
import BlueDropDownIcon from '@assets/common/blueDropDown.png';
import DropDownIcon from '@assets/common/dropDownArrowIcon.png';
import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';
import ColumnsIcon from '@assets/userDashboard/columns.png';
import FolderIcon from '@assets/userDashboard/folder.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import HideIcon from '@assets/common/hideIcon.png';
import CalenderIcon from '@assets/common/calender.png';
import DeleteIcon from '@assets/common/deleteIcon.png';
import Tags from '@components/Tags';
import EditNote from '@components/EditNote';
import User from '@components/User';
import AddLeadPopUp from '@components/Leads/AddLeadPopUp' ;
import HideLeadPopUp from '@components/Leads/HideLeadPopUp' ;
import LeadCallNotesPopUp from '@components/Leads/LeadCallNotesPopUp' ;
import LeadWaitingPopUp from '@components/Leads/LeadWaitingPopUp' ;
import MoveLeadPopUp from '@components/Leads/MoveLeadPopUp' ;
import TransferLeadPopUp from '@components/Leads/TransferLeadPopUp' ;

import SelectionPopUp from '@components/SelectionPopUp';

import { statusList } from './data';
import AddLeadCommentPopUp from '@components/Leads/AddLeadCommentPopUp';
import ActiveSearchFilters from "@components/ActiveSearchFilters";


function LeadsView(props) {
	return (
		<>
			<div className='contentPageMainContainer customerCareView bg-white' ref={props.fullscreenElementRef}>
				<Search
					value={props.searchText}
					placeholder={"Search Leads"}
					// onChange={(event)=>{props.handleSearchInput(event)}}
					// onKeyDown={(event) => { props.handleSearch(event) }}
					onSearch={(data) => props.handleSearch(data)}
					isDefaultChild={true} >
				</Search>

				<div className="marginLeftContainer d-flex align-items-center mb-2 gap-4">
					<h4 className=' topHeading'>Leads</h4>
					<ActiveSearchFilters
						filters={[
						{ icon: "search", value: props.searchText },
						]}
						handleClick={props.handleClearFilter}
					/>
					</div>
				

				<DynamicTabs
					allTabs={props.folders}
					activeTab={props.activeTab}
					onTabClick={props.handleTabClick}
				/>

				<div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[{
							type: 'round',
							text: '+',
							onClick: (event) => {
								props.handlePopUpOpen(event, "createNew");
							},
						},
						{
							text: props.activeTab._id=='unClaimedLeads'? 'Claim':'Release',
							disabled: props.disableBulkActionBtn,
							onClick: props.activeTab._id=='unClaimedLeads'?
							               (props.handleClaimLead)
							              :(props.handleReleaseLead)
						},
						{
							text: 'Add Call Log',
							disabled: props.disableCallNotes,
							onClick: (event) => {
								props.handlePopUpOpen(event, "addCallLog");
							},
						},
						{
							text: 'Add comment',
							disabled: props.disableCallNotes,
							onClick: (event) => {
								props.handlePopUpOpen(event, "addComment");
							},
						},
						{
							text: 'Move',
							iconEnd: BlueDropDownIcon,
							disabled: props.disableBulkActionBtn,
							onClick: (event) => {
								props.handlePopUpOpen(event, "moveTo");
							},
						},
						{
							text: 'Add to waiting',
							iconEnd: BlueDropDownIcon,
							disabled: props.disableBulkActionBtn,
							onClick: (event) => {
								props.handlePopUpOpen(event, "addToWaiting");
							},
						},
						{
							text: 'Transfer To',
							iconEnd: BlueDropDownIcon,
							disabled: props.disableBulkActionBtn,
							onClick: (event) => {
								props.handlePopUpOpen(event, "transferTo");
							},
						}
						]}
					/>

					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						<button onClick={(event) => props.handlePopUpOpen(event, "hideLeads")} disabled={props.disableBulkActionBtn}>
							<img src={HideIcon}></img>
							<br></br>
							Hide
						</button>
						<button onClick={(event) => props.handlePopUpOpen(event, "setting")}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button>
						<button onClick={props.moveToArchive} disabled={props.disableBulkActionBtn}>
							<img src={FolderIcon}></img>
							<br></br>
							Archive
						</button>
						<button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button>
					</div>
				</div>
				<BasicTableGrid
					gridId='Leads'
					data={props.deferredData} // Data to be displayed
					fixedCols={2} // How many columns are fixed and not scrolling
					initialSize={[35, 187, 150, 150, 150, 150, 150, 300, 150]}
					initialSorting={[{ "id": "Date/Time", "desc": false }]}
					handleSelect={(selectedRows) => { props.handleCheckBoxes(selectedRows); }}
					length={20}
					onSortChange={(data) =>props.handleSort(data)}
					columnsOptionPopUpRef={props.columnsOptionPopUpRef}
					columns={[
						{ header: 'Name',
							cell: (data) => {
								const info = data.row.original;
								return (
									<div className='w-100'>
										{data.row.getCanExpand()? 
										 <div className='d-flex justify-content-start align-items-center w-100 gap-2'
										 onClick={data.row.getToggleExpandedHandler()} 
										 style={{cursor:'pointer', fontWeight: 'bold', fontSize:'16px'}}>
											{info.title} 
											<div className='titleTag1 bg-success pt-1 px-2 text-white'>{info.data.length}</div>
											<img className="icon" src={DropDownIcon} 
											style={data.row.getIsExpanded()? {rotate:'180deg', width:'10px', marginLeft:'auto'}: {rotate:'0deg', width:'10px', marginLeft:'auto'}}/>
										</div>
										:
										<div className="d-flex gap-2 w-100">
											<TextAndLinks
												title={info.displayName? info.displayName
													: info.firstname ? info.firstname 
													: info.userName ? info.userName
													: info.fullName? info.fullName 
													: "Name not found"
												}
												subTitle={'view'}
												images={[EditPencilIcon]}
												onClick={(event, index) => { props.handleRedirectToHome(event, info.userId, 'Lead Details') }}
											/>
										</div>
										}
									</div>
									

								);
							}
						},
						{ header: 'Phone Number',
							cell: (data) => {
								const info = data.row.original;
								return (<PhoneNumber
											countryCode={info.countryCode}
											number={info.contactNumber}
											type='phone'
										/>
								);
							}
						},
						{ header: 'Status',
							cell: (data) => {
								const info = data.row.original;
								return <div className='d-flex gap-2 align-items-baseLine' style={{cursor:'pointer'}}
								   onClick={(event)=>props.handlePopUpOpen(event, "changeStatus", info._id)}
								>
								<img src={statusList[info.status]?.icon} style={{width:'16px'}}/> 
								<div>{statusList[info.status]?.text}</div>
								</div>
							}
						},
						{ header: 'Next FollowUp Date/time', accessorFn: (row) => { return row.createdAt },
							cell: (data) => {
								const info = data.row.original;
								return <div>
										<DateAndUser
										date={info.followUpAt}
										image={''}
										imageText={''} />
									
										<div>
											<image src={CalenderIcon} className="image" />
											<image src={DeleteIcon} className="image" />
										</div>
									</div>
							},
						},
						{ header: 'Notes',
							cell: (data) => {
								const info = data.row.original;
								return <EditNote value={info.notes} 
										onChange = {(notes)=>{
											props.handleChange('notes', notes, data.row.original._id)}
										}
										userId={info.userId}
								/>
							}
						},
						{ header: 'Tags',
							cell: (data) => {
								const info = data.row.original
								const userManualTags = data.row.original.userManualTags;
								return (
									<Tags 
									userId={info.userId}
									automaticTags={[]} // [{key:value}]
									manualTags = {userManualTags}
									onChange = {(manualTags)=>{
										props.handleChange('userManualTags', manualTags, info._id)
									}}
									/>
								);
                           }
						},
						{ header: 'Interests',
							cell: (data) => {
								const info = data.row.original;
								return info?.interests?.map((interest, index)=> interest.title+", ")
							}
						},
						{ header: 'Agent Touched',
							cell: (data) => {
								const info = data.row.original;
								return info.agentsTouched?.map(agent=> {
									 return <User imageText={agent.fullName} image={agent.displayPhoto}/>
								})
								
							}
						},
						{ header: 'Open From', accessorFn: (row) => { return row.createdAt },
							cell: (data) => {
								const info = data.row.original;
								return <div><DateAndUser
									date={info.lastOpenedAt}
									image={''}
									imageText={''} />
									
									</div>
							},
						},
						{ header: 'Type',
							cell: (data) => {
								const info = data.row.original;
								return (
									info.type?.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
								);
							}
						},
						{ header: 'Category',
							cell: (data) => {
								const info = data.row.original;
								return info.category?.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
							}
						}
					]
					}
				/>

				

				<PopUp visible={props.isPopUpVisible && props.popUp === 'createNew'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<AddLeadPopUp
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'hideTill'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<HideLeadPopUp
					 handleCancel={props.handlePopUpClose}
					 handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'addCallLog'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<LeadCallNotesPopUp
					  leadId={props.listOfCheckedRows[0]?._id}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'addToWaiting'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<LeadWaitingPopUp
					  leadIds = {props.listOfCheckedRows?.map(lead=>lead._id).filter(lead=> lead)}
					  currentFolderName={['myLeads','unClaimedLeads','lost','Archived','successfullyClosed', 'waiting', 'irrelevant'].includes(props.activeTab._id)? props.activeTab._id: props.activeTab.title}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'moveTo'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<MoveLeadPopUp
					  leadIds = {props.listOfCheckedRows?.map(lead=>lead._id).filter(lead=> lead)}
					  currentFolderDisplayName = {props.activeTab.title}
					  currentFolderName={['myLeads','unClaimedLeads','lost','Archived','successfullyClosed', 'waiting', 'irrelevant'].includes(props.activeTab._id)? props.activeTab._id: props.activeTab.title}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'addComment'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<AddLeadCommentPopUp
					  leadId={props.listOfCheckedRows[0]?._id}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'transferTo'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<TransferLeadPopUp
					  leadIds = {props.listOfCheckedRows?.map(lead=>lead._id).filter(lead=> lead)}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'hideLeads'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left -300}
					handleOutSideClick={props.handlePopUpClose}>
					<HideLeadPopUp
					  leadIds = {props.listOfCheckedRows?.map(lead=>lead._id).filter(lead=> lead)}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'setting'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left -100}
					handleOutSideClick={props.handlePopUpClose}>
					<div style={{height: '200px', overflow:'auto'}}>
                        <div className="settingOption" onClick={props.handleClaimLead}>Claim again</div>
                        <div className="settingOption" onClick={props.showHiddenLeads}>Show hidden leads</div>
                        <div className="settingOption" onClick={(e)=>props.handlePopUpOpen(e, 'changeStatus', null)}>Change lead status</div>
                    </div>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'changeStatus'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
						<SelectionPopUp 
							data = { Object.keys(statusList)?.map((key, index)=>{ return{key:key, text:statusList[key].text, icon:statusList[key].icon}})}
							optionKey= {"text"}
							title={"Select Status"}
							searchPlaceholder={""}
							isSearchEnabled={false}
							height= {"250px"}
							width= {"auto"}
							// selectedItems={Object.keys(statusList)?.map((key, index)=>{ return{key:key, name:statusList[key].text}}).filter(item => props.selected.subTag ===item.title)}
							dataKey={"key"}
							selectedItemsKey={"key"}
							selectionType={"single"}
							handleCancel ={props.handlePopUpClose}
							handleSave={props.handlePopUpSave}/>
				</PopUp>
			</div>
		</>
	);
}

export default LeadsView;
