import React from "react";
import "./style.css";

import TextInputV2 from "@components/TextInputV2";
import SelectInput from "@components/SelectInput";
import PopUp from "@components/PopUp";
import EditExamPopUp from "@components/Courses/EditExamPopUp";
import EditPaymentGatewayPopUp from "@components/Courses/EditPaymentGatewayPopUp";
import EditQualificationPopUp from "@components/Courses/EditQualificationPopUp";
import PlusIcon from "@assets/common/plusIconGrey.png";
import ImageInput from "@components/ImageInput";
// import ThumbnailPopup from '@components/ThumbnailPopup';

import CloseIcon from "@assets/common/blackCrossIcon.png";

function DetailsView(props) {
  return (
    <div
      style={{ width: "700px", marginLeft: "25px", paddingBottom: "10%" }}
      className="courseDetails mt-4 d-flex gap-3 flex-column"
    >
      <TextInputV2
        id={"displayName"}
        placeholder={"Display Name"}
        required={true}
        value={props.courseDetails.title ? props.courseDetails.title : ""}
        onChange={(event) => props.handleChange(event, "title")}
      />
      <TextInputV2
        id={"InternalName"}
        placeholder={"Internal Name"}
        required={true}
        value={
          props.courseDetails.internalTitle
            ? props.courseDetails.internalTitle
            : ""
        }
        onChange={(event) => props.handleChange(event, "internalTitle")}
      />
      <TextInputV2
        id={"CourseMnemonic"}
        placeholder={"Course Mnemonic"}
        required={true}
        value={props.courseDetails.mnemonic ? props.courseDetails.mnemonic : ""}
        onChange={(event) => props.handleChange(event, "mnemonic")}
      />

      <div className="visibilityContainer">
        <p style={{ fontWeight: "700" }}>
          Visibility<span style={{ color: "red" }}>*</span>{" "}
        </p>
        <div className="form-check">
          <input
            onChange={(event) => props.handleChange(event, "visibility")}
            checked={props.courseDetails.visibility === "unlisted"}
            value="unlisted"
            className="form-check-input"
            type="radio"
            id="unlisted"
          />
          <label htmlFor="unlisted">Unlisted</label>
        </div>
        <div className="form-check">
          <input
            onChange={(event) => props.handleChange(event, "visibility")}
            checked={props.courseDetails.visibility === "public"}
            value="public"
            className="form-check-input"
            type="radio"
            id="public"
          />
          <label htmlFor="public">Public</label>
        </div>

        <div className="form-check">
          <input
            onChange={(event) => props.handleChange(event, "visibility")}
            checked={props.courseDetails.visibility === "private"}
            value="private"
            className="form-check-input"
            type="radio"
            id="private"
          />
          <label htmlFor="private">Private</label>
        </div>
      </div>

      <SelectInput
        title={"Select Exam"}
        required={true}
        inputText={
          props.allExams.some((exam) => exam._id === props.courseDetails.examId)
            ? props.allExams.find(
                (exam) => exam._id === props.courseDetails.examId
              ).title
            : "Select Exam Set"
        }
        onClick={(event) => props.handleOpenPopUp(event, "examSelect")}
      />
      <SelectInput
        title={"Choose payment gateway"}
        required={true}
        inputText={
          props.courseDetails?.paymentGateways
            ? props.courseDetails?.paymentGateways?.map(
                (gateway) =>
                  `${
                    props.paymentGateways.filter(
                      (g) => g.paymentGateway === gateway.paymentGateway
                    )[0].text
                  } (${gateway.discount ? gateway.discount : 0}), `
              )
            : "Select allowed payment gateway set"
        }
        onClick={(event) => props.handleOpenPopUp(event, "paymentSelect")}
      />
      <div className="mt-3 d-flex gap-2 align-items-center">
        <input
          type="checkbox"
          className="colorCheckbox"
          checked={props.courseDetails?.showImageCarousel}
          onChange={(event) =>
            props.handleChange(event.target.checked, "showImageCarousel")
          }
        ></input>
        <span className="inputTitle">Image Carousel</span>
        <span className="inputSubTitle">Upload 5:6 aspect ratio only</span>
      </div>

      <div className="imageCarousel">
        {props.courseDetails?.imageCarousel?.map((image, index) => {
          return (
            <div
              className="carouselImage"
              style={{
                backgroundImage:
                  typeof image === "string"
                    ? `url(${image})`
                    : `url(${URL.createObjectURL(image)})`,
              }}
              key={index}
            >
              <img
                src={CloseIcon}
                className="closeIcon"
                onClick={(event) => {
                  props.handleChange(index, "carouselImageDelete");
                }}
              />
            </div>
          );
        })}
        {props.courseDetails?.imageCarouselFiles?.map((image, index) => {
          return (
            <div
              className="carouselImage"
              style={{
                backgroundImage:
                  typeof image === "string"
                    ? `url(${image})`
                    : `url(${URL.createObjectURL(image)})`,
              }}
              key={index}
            >
              <img
                src={CloseIcon}
                className="closeIcon"
                onClick={(event) => {
                  props.handleChange(index, "carouselImageFilesDelete");
                }}
              />
            </div>
          );
        })}
        <div
          className="addCarouselImage"
          style={{ backgroundImage: `url(${PlusIcon})` }}
          onClick={(event) => props.handleOpenPopUp(event, "carouselSelect")}
        ></div>
      </div>

      <div className="d-flex flex-column gap-3 mt-2 ">
        <div className="inputTitle">Unlock exam when</div>
        <div className="d-flex gap-3 align-items-center">
          <input
            type="checkbox"
            className="colorCheckbox"
            checked={props.courseDetails.seriesWatchedPercentage != 0}
            onChange={(event) =>
              props.handleChange(event, "seriesWatchedPercentageCheck")
            }
          ></input>
          <input
            type="number"
            className="inputSmall rounded-2"
            value={
              props.courseDetails?.seriesWatchedPercentage
                ? props.courseDetails.seriesWatchedPercentage
                : "0"
            }
            onChange={(event) =>
              props.handleChange(event, "seriesWatchedPercentage")
            }
          />
          % of the series watched
        </div>
        <div className="d-flex gap-3 align-items-center">
          <input
            type="checkbox"
            className="colorCheckbox"
            checked={props.courseDetails.exemptedDays != 0}
            onChange={(event) => props.handleChange(event, "exemptedDaysCheck")}
          ></input>
          <input
            type="number"
            className="inputSmall rounded-2"
            value={
              props.courseDetails.exemptedDays
                ? props.courseDetails.exemptedDays
                : "0"
            }
            onChange={(event) => props.handleChange(event, "exemptedDays")}
          />
          days passed after series unlocked
        </div>
      </div>

      <div className="tagsDiv mt-3">
        <div className="sectionSelect">
          <input
            type="checkbox"
            checked={
              props.courseDetails.tags
                ? Object.values(props?.courseDetails?.tags).filter(
                    (value) => value !== null
                  ).length > 1
                : false
            }
            onChange={(event) =>
              props.handleChange(event, "automaticTagsCheck")
            }
          ></input>
          <label className="inputTitle"> Automatic Tags</label>
        </div>
        <div className="tagsContainerBody d-flex align-items-center gap-2">
          <div className="automaticTags">
            <input
              type="checkbox"
              checked={props?.courseDetails?.tags?.year != null}
              onChange={(event) => props.handleChange(event, "yearTag")}
            ></input>
            <span>Year Tag</span>
          </div>
          +
          <TextInputV2
            id={"mainTag"}
            placeholder={"Main Tag"}
            required={true}
            value={props?.courseDetails?.tags?.main}
            onChange={(event) => {
              props.handleChange(event, "mainTag");
            }}
          />
          +
          <input
            type="checkbox"
            checked={props.courseDetails?.tags?.secondary != null}
            onChange={(event) => props.handleChange(event, "secondaryCheck")}
          ></input>
          <TextInputV2
            id={"secondaryTag"}
            placeholder={"Secondary Tag"}
            required={false}
            value={
              props.courseDetails.tags?.secondary
                ? props.courseDetails.tags.secondary
                : ""
            }
            onChange={(event) => {
              props.handleChange(event, "secondaryTag");
            }}
          />
          +
          <div className="automaticTags">
            <input
              type="checkbox"
              checked={props.courseDetails.tags?.month != null}
              onChange={(event) => props.handleChange(event, "monthTag")}
            ></input>
            <span>Month Tag</span>
          </div>
          +
          <div className="automaticTags">
            <input
              type="checkbox"
              checked={props.courseDetails.tags?.status != null}
              onChange={(event) => props.handleChange(event, "statusTag")}
            ></input>
            <span>Status Tag</span>
          </div>
        </div>
      </div>

      <SelectInput
        title={"Exam Eligible For"}
        required={true}
        inputText={
          props.courseDetails?.eligibleExams
            ? props.courseDetails.eligibleExams.map(
                (qualification) => qualification + ", "
              )
            : "Select qualifications eligible for exam"
        }
        onClick={(event) =>
          props.handleOpenPopUp(event, "examEligibleForSelect")
        }
      />

      <SelectInput
        title={"Certificate Eligible for"}
        required={true}
        inputText={
          props.courseDetails?.eligibleCertificates
            ? props.courseDetails.eligibleCertificates.map(
                (qualification) => qualification + ", "
              )
            : "Select qualifications eligible for certificate"
        }
        onClick={(event) =>
          props.handleOpenPopUp(event, "certEligibleForSelect")
        }
      />
      <div className="tagsDiv">
        <div className="sectionSelect">
          <input
            type="checkbox"
            checked={!props?.courseDetails?.allowToCart}
            onChange={(event) => props.handleChange(event, "allowToCart")}
          ></input>
          <label> Do not allow course to be added in the cart</label>
        </div>

        <div className="sectionSelect">
          <input
            type="checkbox"
            checked={
              props.courseDetails?.isIdVerificationRequired
                ? props.courseDetails.isIdVerificationRequired
                : false
            }
            onChange={(event) =>
              props.handleChange(event, "isIdVerificationRequired")
            }
          ></input>
          <label> Make identity verification required</label>
        </div>

        <div className="sectionSelect">
          <input
            type="checkbox"
            checked={
              props.courseDetails?.isLegalInfoRequired
                ? props.courseDetails.isLegalInfoRequired
                : false
            }
            onChange={(event) =>
              props.handleChange(event, "isLegalInfoRequired")
            }
          ></input>
          <label> Make legal information required</label>
        </div>

        <div className="sectionSelect">
          <input
            type="checkbox"
            checked={
              props.courseDetails?.isAddressProofRequired
                ? props.courseDetails.isAddressProofRequired
                : false
            }
            onChange={(event) =>
              props.handleChange(event, "isAddressProofRequired")
            }
          ></input>
          <label> This course has certificate home delivery</label>
        </div>
      </div>
      {/* <div className="bssSection d-flex flex-column gap-2 mt-3">
             <div className="sectionSelect">  
                <input type='checkbox'></input>
                <label> Make BSS Form</label>
             </div> 
             <TextInputV2 id ={"courseDurationBss"} placeholder ={"Course Duration"} required ={false}/>
             <TextInputV2 id ={"examYearBss"} placeholder ={"Examination Year (20xx-20yy)"} required ={false}/>
             <TextInputV2 id ={"nameOfCourseBss"} placeholder ={"Name of course"} required ={false}/>
             <TextInputV2 id ={"instituteApprovalCode"} placeholder ={"Institute Approval Code"} required ={false}/>
            
            </div> */}
      <PopUp
        visible={props.isSelectExamPopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <EditExamPopUp
          selectedExam={props.courseDetails.examId}
          allExams={props.allExams}
          handleClose={props.handlePopUpClose}
          handleSave={(event) => props.handleChange(event, "examId")}
        />
      </PopUp>
      <PopUp
        visible={props.isSelectGatewayPopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <EditPaymentGatewayPopUp
          courseId={props.courseDetails._id}
          paymentGateways={props.courseDetails.paymentGateways}
          allPaymentGateways={props.paymentGateways}
          handleClose={props.handlePopUpClose}
          handleSave={(paymentGateways) =>
            props.handleChange(paymentGateways, "paymentGateways")
          }
        />
      </PopUp>
      <PopUp
        visible={props.isSelectExamQualificationPopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <EditQualificationPopUp
          courseId={props.courseDetails._id}
          currentQualifications={props.courseDetails.eligibleExams}
          allQualifications={props.allQualifications}
          handleClose={props.handlePopUpClose}
          handleSave={(qualifications) =>
            props.handleChange(qualifications, "eligibleExams")
          }
        />
      </PopUp>
      <PopUp
        visible={props.isSelectCertificateQualificationPopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <EditQualificationPopUp
          courseId={props.courseDetails._id}
          currentQualifications={props.courseDetails.eligibleCertificates}
          allQualifications={props.allQualifications}
          handleClose={props.handlePopUpClose}
          handleSave={(qualifications) =>
            props.handleChange(qualifications, "eligibleCertificates")
          }
        />
      </PopUp>
      <PopUp
        visible={props.isCarouselUploadPopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="full-page-overlay">
          <ImageInput
            handleClose={props.handlePopUpClose}
            handleAdd={(event, files) =>
              props.handleChange(files, "imageCarousel")
            }
          />
        </div>
      </PopUp>
    </div>
  );
}

export default DetailsView;
