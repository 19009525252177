import { useEffect, useState, useRef } from 'react';
import { useLocation, defer,useParams, useNavigate,} from 'react-router-dom';

import ErrorView from '@components/ErrorView';
import ReuestDetailsView from './view';

import { decryptText } from '@library/enc-dec';
import { checkLoginAndRole } from '@helpers/Login/';
import { getEventElementPosition, toggleFullscreen } from '@helpers/common.js';

import * as FormalRequestService from '@services/FormalRequest';
import Swal from 'sweetalert2';
import InnerSidebarNavigation from '@components/InnerSidebarNavigation'
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import * as UsersServices from '@services/Users';
import * as FolderServices from '@services/Folders';


import { navigationData }from "@pages/Users/data";
let userNavigationData = navigationData
function LeadDetails() {
	const location = useLocation();
	
	const navigate = useNavigate();
	let navigationData = [{
        "title": "Back to SuperAdmin",
        "route": "/superAdmin",
        "icon": GoBackArrow
    }];
    if (location.state && location.state.navigationData) {
        navigationData = location.state.navigationData;
    }

	const { id } = useParams();

	// START : popup stats
	const [popUp, setPopUp] = useState('');
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [popUpPosition, setPopUpPosition] = useState({});
	// END : popup stats

	const [deferredRequestDetails, setDeferredRequestDetails] = useState({});
	const [requestData, setRequestData] = useState([]);
	
	useEffect(() => {
		checkLoginAndRole('quries');
		if(id){
			getRequestDetails()}
	}, []);

	const getRequestDetails=async()=>{
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		const response = await FormalRequestService.getFormalRequestById(id, authToken);
		if (response.success) {
			setRequestData(response.data)
			var deferredResponse = defer({ res: getRequestPromise(response.data)});
			setDeferredRequestDetails(deferredResponse)
		}
		else{
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
		}
	}

	function getRequestPromise(data) {
		return new Promise((resolve, reject) => {
				resolve({
					currentDocCount: data.allUsers?.length,
					data: data.allUsers.map(user => {
						return {
							...user,
							freeAfttectedCourses: data?.freeAfttectedCourses,
							addRuleAffectedCourses: data?.addRuleAffectedCourses,
							requestType: data?.requestType
						};
					}),
					pageLimit: 0,
					totalDocCount: data.allUsers?.length
				});
		});
	}

	//START : Popup fns
	const handlePopUpOpen = (event, popUpName) => {
		if(requestData.status!=='pending')
		{
			return
		}
		handlePopUpClose()
		setPopUp(popUpName)
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
	};
	const handlePopUpClose = () => {
		setIsPopUpVisible(false)
	};
	const handlePopUpSave = (data) => {
		if(data[0]._id ==='Approve'){
			approveRequest()
		}else if(data[0]._id==='Reject'){
			rejectRequest()
		}
		handlePopUpClose();
	};

	const approveRequest=async()=>{
		let requestType = requestData.requestType
		let payload
		let approvalFunction
		
        // Check replace for replacedCourseId, folderType and check move to archive for payload
        if(requestType==='replaceCourse'){
            payload = {
				purchaseIds: requestData.users.filter(obj => obj.purchaseId).map(obj=>obj.purchaseId),
				replacedCourseId: requestData.replacedCourseId,
				folderType: requestData.folderType,
			};
			approvalFunction= UsersServices.replaceCourseForUsers
		}else if(requestType==='disableAccount'){
			payload = {
				userIds: [...new Set(requestData.users.map(item => item.userId))]
			};
			approvalFunction = UsersServices.disableUserAccount
		}else if(requestType==='enableCourse'){
			payload = {
				purchaseIds: requestData.users.filter(obj => obj.purchaseId).map(obj=>obj.purchaseId),
				updateCourseEnableDate: requestData.courseEnableAt
			};
			approvalFunction = UsersServices.updateCourseEnableDate
		}else if(requestType==='createBSSForm'){
			payload = {
				"users": requestData.users,
				"instituteId": requestData.bssInstituteId,
				"bssCourseId": requestData.bssCourseId,
				"fromYear": requestData.bssFromDate, //Year in number
				"toYear": requestData.bssEndDate // year in number
			}
			approvalFunction = UsersServices.createBSSFormsForUsers
		}else if(requestType==='deleteCourse'){
			payload = {
				purchaseIds: requestData.requestData.users.filter(obj => obj.purchaseId).map(obj=>obj.purchaseId)
			};
			approvalFunction = UsersServices.deleteCourseForUsers
		}else if(requestType==='moveToArchive'){
			payload = {
				foldersIdList: [requestData.replaceFolderId],
				usersList: requestData.users,
				currentFolderId: requestData.currentFolderId,
				pageType: "user",
			};
			approvalFunction = FolderServices.moveToFolders
		} 
		else if(requestType === "addUserRule"){
		   payload={
			userIds : requestData.users.map(obj=>obj.userId),
			courses:  requestData.courses
		  }
	
		 approvalFunction =  UsersServices.createUserSpecificOffer
		}
		else if(requestType==="freeCourse"){
			payload = {
				userIds: requestData.users.map(obj=>obj.userId),
				courseIds: requestData.currentCourseIds,
				paymentMethod: 'free',
				paymentId: "",
				orderId: "",
				amountPaidInINR: 0,
				coursesMnemonic: requestData.coursesMnemonic,
				gatewayDetails:null,
				paymentDate: requestData.paymentAt,
				courseAddDate: requestData.courseAddAt ,
				externalLink: "",
			  }; 
		
			  approvalFunction = UsersServices.addCourseForUsers
		}else{
			Swal.fire({icon: "error",title: 'Approval request type is not valid',showConfirmButton: false,timer: 2500});
			return
		}
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		const response = await approvalFunction(payload, authToken)
		if(response.success)
		{
			FormalRequestService.updateFormalRequestStatus({"requestId": id,"status":"approved"}, authToken);
			Swal.fire({icon: "success",title: response.message,showConfirmButton: false,timer: 2500});
		}else{
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
		}
	}

	const rejectRequest=async()=>{
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		const response = FormalRequestService.updateFormalRequestStatus({"requestId": id,"status":"rejected"}, authToken);
		if(response.success)
		{Swal.fire({icon: "success",title: response.message,showConfirmButton: false,timer: 2500});}
		else{
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
		}
	}

	
	const setDefaultNavigation = (defaultComponent) => {
		return userNavigationData.map((item) => {
		  if (item.component === defaultComponent) {
			return { ...item, default: true };
		  }
		  return item;
		});
	  };

	const handleRedirectToProfilePage = (event, userDocId, defaultComponent) => {
		//Handle setting default page while navigating
		let finalNavigationData = [];
		finalNavigationData = userNavigationData;
		finalNavigationData[0].route = "/requestDetails/"+id;
		finalNavigationData[0].title = "Back to approval"
		if (defaultComponent !== "none") {
		  finalNavigationData = setDefaultNavigation(defaultComponent);

		} else {
		  // VAR x ONLY FOR TESTING
		  // let x = 'VideoComments';
		  // finalNavigationData = setDefaultNavigation(x);
		  finalNavigationData = setDefaultNavigation("UserProfile");
		}
	
		// finalNavigationData[0].route = '/users?page=1&limit=10'
	
		if (userDocId === "none") {
		  navigate("/users_", { state: { finalNavigationData } });
		} else {
		  navigate("/users_/" + userDocId, { state: { finalNavigationData } });
		}
	  };

	return (
		<>
		<InnerSidebarNavigation
                navigationData={navigationData} />
			<ReuestDetailsView

				// START : states for popup
				popUp={popUp}
				isPopUpVisible={isPopUpVisible}
				popUpPosition={popUpPosition}
				handlePopUpClose={handlePopUpClose}
				handlePopUpOpen={handlePopUpOpen}
				handlePopUpSave={handlePopUpSave}
				// END : states for popup

				deferredRequestDetails={deferredRequestDetails}
				requestData={requestData}

				handleRedirectToProfilePage={handleRedirectToProfilePage}
				
			/>
		</>
	);
}

export default LeadDetails;
