import React from 'react';

import './style.css';
import VideoPlayHolder from '@assets/common/videoPlayHolder.png';
import BlackCrossIcon from '@assets/common/blackCrossIcon.png';
import ClockIcon from '@assets/common/clock2.png';

function AddRulePopUpView(props) {
	return (
		<div className="addCourseMain bg-light p-3 rounded">
			<span>Add Rule</span>
			<div className="d-flex gap-1 testSelector">
				<select
					onChange={(event) => {
						props.handleCourseChange(event);
					}}
					className='form-select'>
					<option value=''>Select course</option>
					{props.allCourses &&
						props.allCourses.map((course, index) => (
							<>
								<option data-courseid={course._id} value={course._id} key={course._id}>
									{course.title}
								</option>
							</>
						))}
				</select>
				
				<button className="roundBtn blackBtn"
				   style={{marginLeft:'auto'}}
					onClick={(event) => {
						props.handleAddNewCourse(event);
					}}>
					+
				</button>
			</div>
			

			{props.error && props.error !== '' && (
				<div className='alert alert-danger w-50'>
					{props.error}
				</div>
			)}

			<div className="hr"/>
      
				
			{props.selectedCoursesList && props.selectedCoursesList.length > 0 &&
				props.selectedCoursesList.map((course, index) => (
					<>
						<div className='newTestContainer gap-3' key={index}>
							<img
								className='testThumbnail'
								src={course.squareThumbnailUrl ? course.squareThumbnailUrl : VideoPlayHolder}
								alt='course'
							/>
							<span className='testName text-justify' style={{fontSize:'13px'}}>{course.title}</span>
							<img
								onClick={(event) => {
									props.handleDeleteCourse(event, course._id);
								}}
								className='blackCrossIcon'
								src={BlackCrossIcon}
								alt='BlackCrossIcon'
							/>
							<input className='form-control form-control-sm' style={{width:'120px'}}
							type="number" placeholder='Price in rs' value={course.offerPriceInNumbers}
							 onChange={(e)=>props.handleOfferPrice(index, e.target.value)}
							/>
						</div>
					</>
				))}
            <div className='d-flex gap-2 border-top pt-2' style={{fontSize:'13px'}}>
				 <div className='d-flex align-items-center justify-content-center rounded' 
				 style={{border:'1px dashed #ccc', height:'40px', width:'40px'}}>
					 <img src={ClockIcon} className='h-50'/>
				 </div>
                 <input className='form-control' type='number' style={{fontSize:'13px'}}
                    value={props.offerTime} onChange={(e)=>props.setOfferTime(e.target.value)}
					placeholder='Enter rule validity time in hours'
				  />
			</div>
			{props.isFormalRequest &&
						<div className='border-top d-flex flex-column gap-2 py-2'>
							<div className='d-flex'>
								Submit formal request
								<span className='ms-auto text-muted'>Minimum 100 characters*</span>
							</div>
							
							<div className="form-floating" >
								<textarea className="form-control" placeholder="" id="cardDesc" style={{height:'158px'}}   
									value={props.formalRequestText}
									onChange={(e)=>props.setFormalRequestText(e.target.value)}
							></textarea>
								<label htmlFor="cardDesc">Request will be sent to administrative team for approval<span>*</span></label>
							</div>
						</div>}
            <div className="d-flex justify-content-end gap-1">
				<button onClick={props.handleCloseBtn} className='closeBtn'>
				Cancel
				</button>
				<button onClick={props.handleSaveBtn} className='saveBtn'>
					Save
				</button>
            </div>



		</div>
	);
}

export default AddRulePopUpView;
