import React, { useState, useEffect, useRef, useMemo } from "react";
import { defer, useLocation, useSearchParams } from "react-router-dom";

import AccountsView from "./view";
import SidebarNavigation from "@components/SidebarNavigation";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as AccountsServices from "@services/Accounts";
import * as AccountsActions from "@redux/actions/Accounts";
import * as CategoryServices from "@services/Category";
import * as CoursesServices from "@services/Course";

import { decryptText } from "@library/enc-dec";
import { produce } from "immer";
import XLSX from "sheetjs-style";
import { checkLoginAndRole } from "@helpers/Login/";

import moment from "moment";
import { defaultTabs } from "./data";
import * as FolderServices from "@services/Folders";
import Swal from "sweetalert2";
import {
  getEventElementPosition,
  toggleFullscreen,
  convertToISOFormat,
} from "@helpers/common.js";
import { getAllTags } from "@helpers/common";
import * as TagsActions from "@redux/actions/Tags";

var selectedRows = [];
var tagCount = 0;
function Accounts(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));
  const [isSorted, setIsSorted] = useState(false);

  const [deferedData, setDeferedData] = useState({});
  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [fromInputDate, setfromInputDate] = useState("");
  const [toInputDate, setToInputDate] = useState("");
  const [searchText, setSearchText] = useState("");

  const [isTxnDateAssending, setIsTxnDateAssending] = useState(false);
  const [isSettlementDateAssending, setIsSettlementDateAssending] =
    useState(false);

  const [sortBy, setSortBy] = useState("tnxDate");
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [key, setKey] = useState("");

  const [folders, setFolders] = useState(defaultTabs);
  const [activeTab, setActiveTab] = useState({
    _id: "all", // Provide a unique ID for the new folder
    title: "All",
    isActive: true,
  });

  const [searchFilters, setSearchFilters] = useState(props.filters);
  const [allCourses, setAllCourses] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  // START : popup stats
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  // END : popup stats

  // START : states for default button bars
  const fullscreenElementRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  // END : states for default button bars

  useEffect(() => {
    getAllFolders();
    getTags();
    setSearchParams({
      page: props.filters.page,
      limit: props.filters.limit,
    });
  }, []);

  useEffect(() => {
    if (!Number(limit) && Number(page)) {
      return;
    }
    let updatedFilters = produce(props.filters, (draft) => {
      draft.page = page;
      draft.limit = limit;
    });
    props.updateAccountFilters(updatedFilters);
  }, [page, limit]);

  useEffect(() => {
    if (props.filters.limit > 0) {
      getAllOrders();
    }
  }, [props.filters, activeTab]);

  useMemo(() => {
    let mainTag = props.filters.mainTag;
    let tagStatus = props.filters.tagStatus;
    let subTag = props.filters.subTag;
    let manualTag = props.filters.manualTag;
    let yearTag = props.filters.yearTag;
    let monthTag = props.filters.monthTag;

    tagCount =
      Boolean(mainTag) +
      Boolean(tagStatus) +
      Boolean(subTag) +
      Boolean(manualTag) +
      Boolean(yearTag) +
      Boolean(monthTag);
  }, [props.filters]);

  const getTags = async () => {
    try {
      const allTags = await getAllTags("manual");
      if (allTags) {
        props.updateTags(allTags);
      } else {
        console.log("Error coming while getting all Tags");
      }
    } catch (err) {
      console.log("Error coming while getting all Tags", err);
    }
  };

  useEffect(() => {
    checkLoginAndRole("accounts");
    if (limit > 0) {
      getAllOrders();
    }
  }, [
    page,
    limit,
    isSorted,
    sortBy,
    searchText,
    activeTab,
    fromInputDate,
    toInputDate,
  ]);

  const getAllOrders = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));

      let payload = {
        folderType: [
          "All",
          "razorpay",
          "stripe",
          "eazypay",
          "idfc",
          "axis",
          "ccavenue",
        ].includes(activeTab._id)
          ? "All"
          : activeTab.title,
        pageNo: props.filters.page,
        limit: props.filters.limit,
        isSorted: props.filters.isSorted,
        sortBy: props.filters.sortBy,
        searchText: props.filters.searchText,
        paymentMethod: [
          "razorpay",
          "stripe",
          "eazypay",
          "idfc",
          "axis",
          "ccavenue",
        ].includes(activeTab._id)
          ? activeTab._id
          : "accountsAll",
        fromInputDate: convertToISOFormat(props.filters.startDate),
        toInputDate: convertToISOFormat(props.filters.endDate),
        expenseType: props.filters.expenseType,
        courseIds: props.filters.courseIds,
        categories: props.filters.categories,
        yearTag: props.filters.yearTag,
        monthTag: props.filters.monthTag,
        mainTag: props.filters.mainTag,
        tagStatus: props.filters.tagStatus,
        subTag: props.filters.subTag,
        userManualTags: props.filters.manualTag
          ? [props.filters.manualTag]
          : [],
      };

      var deferedResponse = defer({
        res: AccountsServices.getAllOrders(payload, authToken),
      });
      setDeferedData(deferedResponse);

      deferedResponse.data.res
        .then((response) => {})
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } catch (err) {
      console.log("Error coming while getting all accounts orders", err);
    }
  };

  const getAllFolders = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      pageNo: "0",
      limit: "10000",
      isSorted: true,
      sortBy: "title",
      searchText: "",
      visibility: "active",
    };
    const response = await FolderServices.getAllFolders(payload, authToken);
    var allFolders = response.data;
    allFolders = allFolders
      .map((folder) => (folder.pageType === "accounts" ? folder : null))
      .filter(Boolean);
    allFolders = [...defaultTabs, ...allFolders];
    setFolders(allFolders);
  };

  // To sort data as per date
  // const handleSort = (sortData) => {
  //   if (sortData.length > 0) {
  //     const { id, desc } = sortData[0];
  //     if (id === "Txn. Date/Time") {
  //       setIsTxnDateAssending(desc);
  //       setSortBy("tnxDate");
  //     }
  //     if (id === "Bank Settlement Date") {
  //       setIsSettlementDateAssending(desc);
  //       setSortBy("settledDate");
  //     }
  //   }
  // };

  const handleSort = (data) => {
    const mapping = {
      "Txn. Date/Time": "createdAt",
      "Bank Settlement Date": "settlementAt",
    };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    let updatedFilters = produce(props.filters, (draft) => {
      draft.sortBy = id;
      draft.isSorted = data[0]?.desc ? data[0].desc : false;
    });
    props.updateAccountFilters(updatedFilters);
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (data) => {
    selectedRows = data;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };

  const handleExcelExport = (event) => {
    // Data preparation
    var excelExportData = [];
    if(selectedRows.length <= 0) {
      Swal.fire({
        icon: "warning",
        title: "Please select rows to export",
        showConfirmButton: false,
        timer: 2500,
      })
      return
    }
    selectedRows.forEach((order) => {
      let row = {
        "Invoice Id": order?.invoiceId,
        Name: order?.firstName + order?.lastName,
        "Country\nCode": order?.countryCode,
        "Contact\nNumber": order?.contactNumber,
        "WhatsApp\nCountry\nCode": order?.whatsAppCountryCode,
        "WhatsApp\nNumber": order?.whatsAppNumber,
        "Email ID": order?.email,
        "Product\nName": order?.coursesMnemonic.split(",").join("+"),
        "Product\nFee": parseFloat(((order?.netTotal / 118) * 100).toFixed(2)),
        Discount: order?.discountInNumbers,
        "Net\nReceived\n(N)\nN=GST+A": order?.netTotal,
        "CGST\n(9%)": parseFloat(
          ((order?.netTotal / 118) * 100 * (9 / 100)).toFixed(2)
        ),
        "SGST\n(9%)": parseFloat(
          ((order?.netTotal / 118) * 100 * (9 / 100)).toFixed(2)
        ),
        "GST\n(GST)\n(18% x N)": parseFloat(
          ((order?.netTotal / 118) * 100 * (18 / 100)).toFixed(2)
        ),
        "Actual Fee\n(A)\nA= N/118*100": parseFloat(
          ((order?.netTotal / 118) * 100).toFixed(2)
        ),
        "Payment\nGateway": order?.gatewayDetails?.type,
        "Gateway\nFee\n(G)": order?.paymentDetails?.fee
          ? parseFloat((order?.paymentDetails?.fee / 100).toFixed(2))
          : 0,
        "Mode\nof\nPayment": order?.paymentDetails?.method
          ? order?.paymentDetails?.method
          : "",
        "Gross\nReceived\n(Gross)\nGross= N-G": parseFloat(
          (
            order?.netTotal -
            (order?.paymentDetails?.fee ? order?.paymentDetails?.fee : 0) / 100
          ).toFixed(2)
        ),
        "Transaction\nID": order?.paymentId,
        "Transaction\nDate": moment.utc(order?.createdAt).format("DD/MM/YYYY"),
        "Amount\nSettled\nDate": order?.settlementAt
          ? moment.utc(order?.settlementAt).format("DD/MM/YYYY")
          : "NA",
      };

      excelExportData.push(row);
    });

    //Exporting to excel
    const worksheet = XLSX.utils.json_to_sheet(excelExportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "OrdersData.xlsx");
  };

  const handleMenuVisibility = (event, type) => {
    setIsMenuVisible(!isMenuVisible);
    if (type === "main" || type === undefined) {
      return;
    }
    let date = new Date();
    let firstDay = "";
    let lastDay = "";
    if (type === "today") {
      firstDay = date;
      lastDay = firstDay;
      setfromInputDate(firstDay.toISOString().substring(0, 10));
      setToInputDate(lastDay.toISOString().substring(0, 10));
    } else if (type === "month") {
      firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      setfromInputDate(firstDay.toISOString().substring(0, 10));
      setToInputDate(lastDay.toISOString().substring(0, 10));
    } else if (type === "week") {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6

      firstDay = new Date(curr.setDate(first));
      lastDay = new Date(curr.setDate(last));
      setfromInputDate(firstDay.toISOString().substring(0, 10));
      setToInputDate(lastDay.toISOString().substring(0, 10));
    } else if (type === "year") {
      firstDay = new Date(date.getFullYear(), 0, 2);
      lastDay = new Date(date.getFullYear(), 12, 1);
      setfromInputDate(firstDay.toISOString().substring(0, 10));
      setToInputDate(lastDay.toISOString().substring(0, 10));
    } else if (type === "all") {
      const accountDate = new Date("2023-08-21T20:08:37.000Z");
      firstDay = new Date(
        accountDate.getFullYear(),
        accountDate.getMonth(),
        22
      );
      setfromInputDate(firstDay.toISOString().substring(0, 10));
      setToInputDate("");
      return;
    }

    // const filteredOrders = produce(props.allOrders, (draft) => {
    // 	draft.splice(0, draft.length); // Clear the draft array

    // 	props.allOrders.forEach((order) => {
    // 		const createdAtDate = new Date(order.createdAt);
    // 		if (createdAtDate >= firstDay && createdAtDate <= lastDay) {
    // 			draft.push(order); // Add the order to the draft array
    // 		}
    // 	});
    // });

    // props.updateAllOrders(filteredOrders);
    // getFirstSetOfOrders(filteredOrders);
  };

  const handleFormInputdate = (date, type) => {
    var firstDay = fromInputDate;
    var lastDay = toInputDate;
    if (type === "start") {
      setfromInputDate(date);
      firstDay = date;
    } else {
      setToInputDate(date);
      lastDay = date;
    }
    if (firstDay && lastDay) {
      firstDay = new Date(firstDay);
      lastDay = new Date(lastDay);
      const filteredOrders = produce(props.allOrders, (draft) => {
        draft.splice(0, draft.length); // Clear the draft array

        props.allOrders.forEach((order) => {
          const createdAtDate = new Date(order.createdAt);
          if (createdAtDate >= firstDay && createdAtDate <= lastDay) {
            draft.push(order); // Add the order to the draft array
          }
        });
      });

      props.updateAllOrders(filteredOrders);
    }
  };

  const handleBulkOrderDelete = async () => {
    closeConfirmationBox();
    setKey("");
    if (key != `${process.env.REACT_APP_DELETE_KEY}`) {
      alert("Secret key do not match!");
      return;
    }
    if (selectedRows.length === 0) {
      alert("No order is selected");
      return;
    }

    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      orders: selectedRows.map((row) => row._id),
    };
    const response = await AccountsServices.deleteOrders(payload, authToken);
    if (response.success) {
      selectedRows = [];
      getAllOrders();
    }
    alert(response.message);
  };

  const openConfirmationBox = () => {
    if (disableBulkActionBtn) {
      Swal.fire({
        icon: "error",
        title: "No item is selected!",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    setShowConfirmationBox(true);
  };
  const closeConfirmationBox = () => {
    setShowConfirmationBox(false);
  };

  const setKeyValue = (value) => {
    setKey(value);
  };
  const {
    ordersSet,
    allOrders,
    numOfOrders,
    netReceived,
    grossReceived,
    totalGST,
    totalGatewayFee,
  } = props;

  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    columnsOptionPopUpRef.current.style.display = "none";
  };
  const handlePopUpSave = (data) => {
    handlePopUpClose();
    setDisableBulkActionBtn(true);

    if (popUp === "filterCategory") {
      data = data.map((obj) => obj._id);
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.categories = data;
      });
      setSearchFilters(updatedFilters);
    } else if (popUp === "filterCourse") {
      data = data.map((obj) => obj._id);
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.courseIds = data;
      });
      setSearchFilters(updatedFilters);
    } else if (popUp === "filterExamStatus") {
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.expenseType = data[0]._id;
      });
      setSearchFilters(updatedFilters);
    }
  };
  const handleColumnBtnClick = (event) => {
    handlePopUpClose();
    columnsOptionPopUpRef.current.style.display = "block";
    var position = getEventElementPosition(event);
    columnsOptionPopUpRef.current.style.top = position.top + "px";
    columnsOptionPopUpRef.current.style.left = position.left - 160 + "px";
    event.stopPropagation();
  };
  const handleClearFilter = (index) => {
    try {
      let updatedFilters = produce(props.filters, (draft) => {
        if (index === 0) {
          draft.searchText = "";
        } else if (index === 1) {
          draft.startDate = "";
          draft.endDate = "";
        } else if (index === 2) {
          draft.categories = [];
        } else if (index === 3) {
          draft.courseIds = [];
        } else if (index === 4) {
          draft.expenseType = null;
        } else if (index === 5) {
          draft.yearTag = "";
          draft.monthTag = "";
          draft.mainTag = "";
          draft.tagStatus = "";
          draft.subTag = "";
          draft.manualTag = "";
        }
      });
      setSearchFilters(updatedFilters);
      props.updateAccountFilters(updatedFilters);
    } catch (err) {
      console.log("Error coming while clearing filters", err);
    }
  };

  const handleSearch = (data) => {
    try {
      let updatedFilters = produce(props.filters, (draft) => {
        draft.page = 0;
        draft.searchText = data.text;
        draft.startDate = data.startDate;
        draft.endDate = data.endDate;
        draft.expenseType = searchFilters.expenseType;
        draft.courseIds = [...searchFilters.courseIds];
        draft.categories = [...searchFilters.categories];
        draft.yearTag = data.yearTag;
        draft.monthTag = data.monthTag;
        draft.mainTag = data.mainTag;
        draft.tagStatus = data.tagStatus;
        draft.subTag = data.subTag;
      });
      setSearchFilters(updatedFilters);
      props.updateAccountFilters(updatedFilters);
    } catch (err) {
      console.log("Error coming while searching", err);
    }
  };

  const handleFilterPopUp = async (event, index) => {
    try {
      if (index === 0) {
        getAllCategories();
        handlePopUpOpen(event, "filterCategory", null);
      } else if (index === 1) {
        await getAllCourses();
        handlePopUpOpen(event, "filterCourse", null);
      } else if (index === 2) {
        handlePopUpOpen(event, "filterExamStatus", null);
      }
    } catch (err) {
      console.log("Error coming while opening filter pop up", err);
    }
  };

  const getAllCourses = async () => {
    try {
      if (allCourses.length === 0) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        const response = await CoursesServices.getAllCourses(
          { pageNo: 0, limit: 10000 },
          authToken
        );
        if (response.success) {
          setAllCourses(response.data);
        }
      }
    } catch (err) {
      console.log("Error coming while fetching all course", err);
    }
  };
  const getAllCategories = async () => {
    try {
      if (allCategories.length === 0) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        const response = await CategoryServices.getAllCategories(
          { pageNo: 0, limit: 99999 },
          authToken
        );
        if (response.success) {
          setAllCategories(response.data);
        }
      }
    } catch (err) {
      console.log("Error coming while fetching categories in categories", err);
    }
  };

  return (
    <>
      <SidebarNavigation />
      <AccountsView
        deferedData={deferedData}
        netReceived={netReceived}
        grossReceived={grossReceived}
        totalGST={totalGST}
        totalGatewayFee={totalGatewayFee}
        //Start: Tabs
        folders={folders}
        activeTab={activeTab}
        handleTabClick={setActiveTab}
        //END

        //START : Pagination useStates

        //END : Pagination useStates

        //START : UseStates & fn to control the functionality of checkboxes
        handleCheckBoxes={handleCheckBoxes}
        disableBulkActionBtn={disableBulkActionBtn}
        //END : UseStates & fn to control the functionality of checkboxes

        //END

        // START: Exporting excel data
        handleExcelExport={handleExcelExport}
        //END

        //START: Data filter
        isMenuVisible={isMenuVisible}
        fromInputDate={fromInputDate}
        toInputDate={toInputDate}
        handleMenuVisibility={handleMenuVisibility}
        handleFromInputDate={handleFormInputdate}
        handleSort={handleSort}
        isTxnDateAssending={isTxnDateAssending}
        isSettlementDateAssending={isSettlementDateAssending}
        //START: DELETE BULK
        handleBulkOrderDelete={handleBulkOrderDelete}
        showConfirmationBox={showConfirmationBox}
        openConfirmationBox={openConfirmationBox}
        closeConfirmationBox={closeConfirmationBox}
        keyValue={key}
        setKeyValue={(value) => setKeyValue(value)}
        //END

        // Pop Ups:
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        isPopUpVisible={isPopUpVisible}
        popUpPosition={popUpPosition}
        popUp={popUp}
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        handleColumnBtnClick={handleColumnBtnClick}
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        //Filters
        filters={searchFilters}
        finalFilters={props.filters}
        handleClearFilter={handleClearFilter}
        // START: States & funcions to control search
        handleSearch={handleSearch}
        searchText={searchText}
        handleFilterPopUp={handleFilterPopUp}
        allCategories={allCategories}
        allCourses={allCourses}
        tagCount={tagCount}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    filters: state.accounts.filters,
    allOrders: state.accounts.allOrders,
    ordersSet: state.accounts.ordersSet,
    numOfOrders: state.accounts.numOfOrders,
    netReceived: state.accounts.netReceived,
    grossReceived: state.accounts.grossReceived,
    totalGST: state.accounts.totalGST,
    totalGatewayFee: state.accounts.totalGatewayFee,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAllOrders: AccountsActions.updateAllOrders,
      updateTotalNumOfOrders: AccountsActions.updateTotalNumOfOrders,
      updateOrdersSet: AccountsActions.updateOrdersSet,
      updateNetReceived: AccountsActions.updateNetReceived,
      updateGrossReceived: AccountsActions.updateGrossReceived,
      updateTotalGST: AccountsActions.updateTotalGST,
      updateTotalGatewayFee: AccountsActions.updateTotalGatewayFee,
      updateTags: TagsActions.updateTags,
      updateAccountFilters: AccountsActions.updateAccountFilters,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
