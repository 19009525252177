import { useState, useEffect, useRef, useMemo } from "react";
import {
  useNavigate,
  defer,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";

import UsersView from "./view";
import SidebarNavigation from "@components/SidebarNavigation";

import { navigationData } from "@pages/Users/data";
import { handleExcelExportFn, handleExcelImport } from "@helpers/Users";
import * as UsersServices from "@services/Users";
import * as CategoryServices from "@services/Category";
import * as CoursesServices from "@services/Course";
import * as FolderServices from "@services/Folders";
import * as FormalRequestServices from "@services/FormalRequest";

import { decryptText } from "@library/enc-dec";
import { checkLoginAndRole } from "@helpers/Login/";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "@redux/actions/Users";
import { produce } from "immer";
import { getEventElementPosition } from "@helpers/common.js";
import { toggleFullscreen, convertToISOFormat } from "@helpers/common.js";
import Swal from "sweetalert2";
import SideArrowIcon from "@assets/common/sideArrow.png";
import CalenderIcon from "@assets/common/calender.png";
import * as TagsActions from "@redux/actions/Tags";
import * as TabsActions from "@redux/actions/Tabs";
import * as LoginActions from "@redux/actions/Login";
import { getAllTags } from "@helpers/common";

var listOfCheckedUsers = [];
var listOfPurchaseIds = [];
var listOfUserIds = [];
var roleCode, role;
var tagCount = 0;

function Users(props) {
  const fullscreenElementRef = useRef(null);
  const resultModalRef = useRef(null);
  const userExcelFileInputRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});

  const [resultId, setResultId] = useState("");
  const [purchaseId, setPurchaseId] = useState("");
  const [settingOptions, setSettingOptions] = useState([
    { text: "Show/Hide profile picture", id: 1, img: "" },
    { text: "Export to excel", id: 2, img: "" },
    { text: "Add rule", id: 3, img: SideArrowIcon },
    { text: "Remove user(s)", id: 4, img: "" },
    { text: "Change test status", id: 5, img: SideArrowIcon },
    { text: "Enable course on", id: 6, img: CalenderIcon },
    { text: "Set course end date to", id: 7, img: CalenderIcon },
    { text: "Dashboard form compulsion", id: 8, img: SideArrowIcon },
    { text: "Enable result status", id: 9, img: SideArrowIcon },
    { text: "Test result status", id: 10, img: SideArrowIcon },
    { text: "Disable account(s)", id: 11, img: "" },
    { text: "Replace course(s)", id: 12, img: "" },
    { text: "Delete course", id: 13, img: "" },
    { text: "Create BSS form(s)", id: 14, img: "" },
  ]);
  const [userData, setUserData] = useState({});
  const [deferredData, setDeferredData] = useState({});
  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);
  const [activeTab, setActiveTab] = useState(props.tab);
  const [folders, setFolders] = useState([]);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [isProfilePictureVisible, setIsProfilePictureVisible] = useState(false);
  const [allCourses, setAllCourses] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const [searchFilters, setSearchFilters] = useState(props.filters);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnsOptionPopUpRef.current &&
        !columnsOptionPopUpRef.current.contains(event.target)
      ) {
        columnsOptionPopUpRef.current.style.display = "none";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    [role, roleCode] = checkLoginAndRole("users");
    getTags();
    setSearchParams({
      page: props.filters.page,
      limit: props.filters.limit,
    });
  }, []);

  const getTags = async () => {
    try {
      const allTags = await getAllTags("manual");
      if (allTags) {
        props.updateTags(allTags);
      } else {
        console.log("Error coming while getting all Tags");
      }
    } catch (err) {
      console.log("Error coming while getting all Tags", err);
    }
  };

  useEffect(() => {
    getAllFolders();
  }, [props.access]);

  useEffect(() => {
    setActiveTab(props.tab);
  }, [props.tab]);

  useEffect(() => {
    if (props.filters.limit > 0) {
      getAllUserDetails();
    }
  }, [props.filters, activeTab]);

  useEffect(() => {
    if (!Number(limit) && Number(page)) {
      return;
    }
    let updatedFilters = produce(props.filters, (draft) => {
      draft.page = page;
      draft.limit = limit;
    });
    props.updateFilters(updatedFilters);
  }, [page, limit]);

  const getAllFolders = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      pageNo: "0",
      limit: "10000",
      isSorted: true,
      sortBy: "title",
      searchText: "",
      visibility: "active",
    };

    const response = await FolderServices.getAllFolders(payload, authToken);
    var allFolders = response.data;
    allFolders = allFolders
      .map((folder) => (folder.pageType === "user" ? folder : null))
      .filter(Boolean);

    if (roleCode === 2 || roleCode === "2") {
      if (props.access?.userRights) {
        allFolders = allFolders.filter((folder) =>
          props.access.userRights.includes(folder._id)
        );
      } else {
        allFolders = [];
      }
    }
    var defaultFolder = {
      _id: "All", // Provide a unique ID for the new folder
      title: "All",
      isActive: true,
    };

    allFolders.unshift(defaultFolder);
    setFolders(allFolders);
  };

  const getAllUserDetails = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));

      const payload = {
        folderType: activeTab.title,
        pageNo: props.filters.page,
        limit: props.filters.limit,
        isSorted: props.filters.isSorted,
        sortBy: props.filters.sortBy,
        searchText: props.filters.searchText,
        startDate: convertToISOFormat(props.filters.startDate),
        endDate: convertToISOFormat(props.filters.endDate),
        examStatus: props.filters.examStatus,
        courseIds: props.filters.courseIds,
        categories: props.filters.categories,
        yearTag: props.filters.yearTag,
        monthTag: props.filters.monthTag,
        mainTag: props.filters.mainTag,
        tagStatus: props.filters.tagStatus,
        subTag: props.filters.subTag,
        userManualTags: props.filters.manualTag
          ? [props.filters.manualTag]
          : [],
      };
      var deferredResponse = defer({
        res: UsersServices.getAllUsers(payload, authToken)
        .then(preprocessData).catch(error=>{
          console.log("Error in fetching users", error)
          return null
        })
      });
      setDeferredData(deferredResponse);

      deferredResponse.data.res
        .then((response) => {
          setUserData(response);
        })
        .catch((error) => {});
    } catch (err) {
      console.log("Error coming while fetching All Users", err);
    }
  };

  const preprocessData = (response) => {
		if(!response.status===200)
			{ return null}
    response.data = response.data.map((info) => {
       if(typeof info.courseTags === 'object')
       {info.courseTags.month =
          info?.courseTags.month === "yes"
              ? moment(info.purchaseAt).format("MMM")
              : "no";
      info.courseTags.year =
              info.courseTags.year === "yes"
              ? moment(info.purchaseAt).format("yyyy")
              : "no";
      info.courseTags.status = info.courseTags.status === "yes" ? info.courseStatus : "no";
       }
      return info
    })		
		return response
	}

  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedUsers = selectedRows.map((row) => ({
      userId: row._id,
      purchaseId: row.purchaseId,
      type: row.purchaseId ? "purchases" : "unpurchases",
    }));
    listOfPurchaseIds = selectedRows
      .filter((row) => "purchaseId" in row)
      .map((row) => row.purchaseId);

    listOfUserIds = [...new Set(listOfCheckedUsers.map((item) => item.userId))];

    if (listOfCheckedUsers.length > 0) {
      setDisableBulkActionBtn(false);
    } else {
      setDisableBulkActionBtn(true);
    }
  };

  // START : Code for navigation
  const navigate = useNavigate();

  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };

  const handleRedirectToProfilePage = (event, userDocId, defaultComponent) => {
    //Handle setting default page while navigating
    let finalNavigationData = [];
    finalNavigationData = navigationData;
    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      // VAR x ONLY FOR TESTING
      // let x = 'VideoComments';
      // finalNavigationData = setDefaultNavigation(x);
      finalNavigationData = setDefaultNavigation("UserProfile");
    }

    // finalNavigationData[0].route = '/users?page=1&limit=10'

    if (userDocId === "none") {
      navigate("/users_", { state: { finalNavigationData } });
    } else {
      navigate("/users_/" + userDocId, { state: { finalNavigationData } });
    }
  };
  // END : Code for navigation

  const handleExcelExport = () => {
    let listOfCheckedUsersIds = [];
    listOfCheckedUsers.forEach((user) => {
      listOfCheckedUsersIds.push(user[0]);
    });
    handleExcelExportFn(userData, listOfCheckedUsersIds);
  };

  const handleUserExcelInput = (event) => {
    handleExcelImport(event, (data) => {});
  };

  const handleSearch = (data) => {
    let updatedFilters = produce(props.filters, (draft) => {
      draft.page = 0;
      draft.searchText = data.text;
      draft.startDate = data.startDate;
      draft.endDate = data.endDate;
      draft.examStatus = searchFilters.examStatus;
      draft.courseIds = [...searchFilters.courseIds];
      draft.categories = [...searchFilters.categories];
      draft.yearTag = data.yearTag;
      draft.monthTag = data.monthTag;
      draft.mainTag = data.mainTag;
      draft.tagStatus = data.tagStatus;
      draft.subTag = data.subTag;
    });
    setSearchFilters(updatedFilters);
    props.updateFilters(updatedFilters);
  };

  const handleTabClick = (tab) => {
    props.updateTab(tab);
    // navigate('.', { replace: true });
  };

  const openResultPopUp = (purchaseId, resultId) => {
    // Ensure the modal is shown on top by adjusting z-index
    // resultModalRef.current.style.zIndex = '10001';
    setResultId(resultId);
    setPurchaseId(purchaseId);
    setIsResultModalOpen(true);
  };

  const moveToArchive = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (roleCode == 2) {
        var payload = {
          description: data,
          users: listOfCheckedUsers,
          bssFromDate: null,
          bssEndDate: null,
          currentCourseIds: [],
          replacedCourseIds: [],
          location: "users", // users=> certificate or other admin panel pages
          requestType: "moveToArchive",
          courseEnableAt: null,
          bssCourseId: null,
          bssInstituteId: null,
          currentFolderId: activeTab._id === "All" ? null : activeTab._id,
          replaceFolderId: folders.filter(folder=>folder.title==="Archive" && folder.pageType==="user")[0]._id
        };
        var response = await FormalRequestServices.addFormalRequest(
          payload,
          authToken
        );
      } else {
        var payload = {
          foldersIdList: [folders.filter(folder=>folder.title==="Archive" && folder.pageType==="user")[0]._id],
          usersList: listOfCheckedUsers,
          currentFolderId: activeTab._id === "All" ? null : activeTab._id,
          pageType: "user",
        };
        var response = await FolderServices.moveToFolders(payload, authToken);
      }

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        if (roleCode == 2) {
          return;
        }
        listOfCheckedUsers = [];
        listOfPurchaseIds = [];
        setActiveTab(folders[1]);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };

  const moveToFolder = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      var payload = {
        foldersIdList: data,
        usersList: listOfCheckedUsers,
        currentFolderId: activeTab._id == "All" ? null : activeTab._id,
        pageType: "user",
      };
      const response = await FolderServices.moveToFolders(payload, authToken);

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.success,
          showConfirmButton: false,
          timer: 2500,
        });
        getAllUserDetails();
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };

  // no accounts updated
  const disableAccount = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (roleCode == 2) {
        var payload = {
          description: data,
          users: listOfCheckedUsers, // only user id required
          bssFromDate: null,
          bssEndDate: null,
          currentCourseIds: [],
          replacedCourseIds: [],
          location: "users", // users=> certificate or other admin panel pages
          requestType: "disableAccount",
          courseEnableAt: null,
        };
        var response = await FormalRequestServices.addFormalRequest(
          payload,
          authToken
        );
      } else {
        var payload = {
          userIds: listOfUserIds,
        };
        var response = await UsersServices.disableUserAccount(
          payload,
          authToken
        );
      }

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };

  const enableCourseOn = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (roleCode == 2) {
        var payload = {
          description: data.formalRequestText,
          users: listOfCheckedUsers,
          bssFromDate: null,
          bssEndDate: null,
          currentCourseIds: [],
          replacedCourseIds: [],
          location: "users", // users=> certificate or other admin panel pages
          requestType: "enableCourse",
          courseEnableAt: data.calDate,
        };
        var response = await FormalRequestServices.addFormalRequest(
          payload,
          authToken
        );
      } else {
        var payload = {
          purchaseIds: listOfPurchaseIds,
          updateCourseEnableDate: data.calDate,
        };
        var response = await UsersServices.updateCourseEnableDate(
          payload,
          authToken
        );
      }

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while enabling the course", err);
    }
  };

  // Problem : _id not found error
  const createBSS = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (roleCode == 2) {
        var payload = {
          description: data.formalRequestText,
          users: listOfCheckedUsers,
          bssFromDate: data.fromYear,
          bssEndDate: data.toYear,
          currentCourseIds: [],
          replacedCourseIds: [],
          location: "users", // users=> certificate or other admin panel pages
          requestType: "createBSSForm",
          courseEnableAt: null,
          bssCourseId: data.selectedBssCourse,
          bssInstituteId: data.selectedBssInstitute,
        };
        var response = await FormalRequestServices.addFormalRequest(
          payload,
          authToken
        );
      } else {
        var payload = {
          users: listOfCheckedUsers,
          instituteId: data.selectedBssInstitute,
          bssCourseId: data.selectedBssCourse,
          fromYear: Number(data.fromYear), //Year in number
          toYear: Number(data.toYear), // year in number
        };
        var response = await UsersServices.createBSSFormsForUsers(
          payload,
          authToken
        );
      }

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while generating BSS form", err);
    }
  };

  const dashBoardFormCompulsion = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let selectedOptions = data.map((obj) => obj._id);
      var payload = {
        fields: {
          isDeliveryAddressRequired:
            selectedOptions.includes("deliveryAddress"),
          isBSSDetailsRequired: selectedOptions.includes("bssDetails"),
          isLegalInfoRequired: selectedOptions.includes("legalInformation"),
          isBasicDetailsRequired: selectedOptions.includes("basicDetails"),
          isIdentityVerificationRequired: selectedOptions.includes(
            "identityVerification"
          ),
        },
        userIds: listOfUserIds,
      };
      var response = await UsersServices.updateUsersDashboardFields(
        payload,
        authToken
      );

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };
  const examStatusResult = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      var payload = {
        purchaseIds: listOfPurchaseIds,
        examResultAt: data.showDate,
        examResultHideAt: data.hideDate,
      };

      var response = await UsersServices.updateExamResultStatus(
        payload,
        authToken
      );

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating exam result dates", err);
    }
  };
  const testStatusResult = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      var payload = {
        userIds: listOfUserIds,
        testExamIds: data.testExamIds,
        examResultAt: data.showDate,
        examResultHideAt: data.hideDate,
      };
      var response = await UsersServices.updateTestExamResultStatus(
        payload,
        authToken
      );

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating test dates", err);
    }
  };

  //Working fine

  const removeUser = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      var payload = {
        purchaseIds: listOfPurchaseIds,
        folderName: activeTab.title,
        pageType: "user",
      };
      var response = await UsersServices.removeTheUsers(payload, authToken);

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        getAllUserDetails();
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };
  const replaceCourse = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (roleCode == 2) {
        var payload = {
          description: data.formalRequestText,
          users: listOfCheckedUsers,
          bssFromDate: null,
          bssEndDate: null,
          currentCourseIds: [],
          replacedCourseId: data.newCourse,
          location: "users", // users=> certificate or other admin panel pages
          requestType: "replaceCourse",
          courseEnableAt: null,
          replaceFolderType: activeTab.title,
        };
        var response = await FormalRequestServices.addFormalRequest(
          payload,
          authToken
        );
      } else {
        var payload = {
          purchaseIds: listOfPurchaseIds,
          replacedCourseId: data.newCourse,
          folderType: activeTab.title,
        };
        var response = await UsersServices.replaceCourseForUsers(
          payload,
          authToken
        );
      }

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };
  const deleteCourse = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (roleCode == 2) {
        var payload = {
          description: data,
          users: listOfCheckedUsers,
          bssFromDate: null,
          bssEndDate: null,
          currentCourseIds: [],
          replacedCourseIds: [],
          location: "users", // users=> certificate or other admin panel pages
          requestType: "deleteCourse",
          courseEnableAt: null,
        };
        var response = await FormalRequestServices.addFormalRequest(
          payload,
          authToken
        );
      } else {
        var payload = {
          purchaseIds: listOfPurchaseIds,
        };
        var response = await UsersServices.deleteCourseForUsers(
          payload,
          authToken
        );
      }

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };
  // To sort
  const handleSort = (data) => {
    const mapping = {
      "Purchase Date": "createdAt",
      "Date Modified": "updatedAt",
    };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    let updatedFilters = produce(props.filters, (draft) => {
      draft.sortBy = id;
      draft.isSorted = data[0]?.desc ? data[0].desc : false;
    });
    props.updateFilters(updatedFilters);
  };

  // To close the popup container
  const handlePopUpOpen = (event, popUpName, data) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);

    if (columnsOptionPopUpRef.current) {
      columnsOptionPopUpRef.current.style.display = "none";
    }
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data, event) => {
    handlePopUpClose();
    if (popUp === "addSeries") {
      getAllUserDetails();
    } else if (popUp === "archiveRequest") {
      moveToArchive(data);
    } else if (popUp === "replaceCourse") {
      replaceCourse(data);
    } else if (popUp === "deleteCourse") {
      deleteCourse(data);
    } else if (popUp === "disableAccount") {
      disableAccount(data);
    } else if (popUp === "createBSS") {
      createBSS(data);
    } else if (popUp === "removeUser") {
      removeUser();
    } else if (popUp === "enableCourseOn") {
      enableCourseOn(data);
    } else if (popUp === "dashBoardFormCompulsion") {
      dashBoardFormCompulsion(data);
    } else if (popUp === "examStatusResult") {
      examStatusResult(data);
    } else if (popUp === "testStatusResult") {
      testStatusResult(data);
    } else if (popUp === "moveToFolder") {
      moveToFolder(data);
    } else if (popUp === "filterCategory") {
      data = data.map((obj) => obj._id);
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.categories = data;
      });
      setSearchFilters(updatedFilters);
    } else if (popUp === "filterCourse") {
      data = data.map((obj) => obj._id);
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.courseIds = data;
      });
      setSearchFilters(updatedFilters);
    } else if (popUp === "filterExamStatus") {
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.examStatus = data[0]._id;
      });
      setSearchFilters(updatedFilters);
    }
  };

  const handleSettingOption = (event, value) => {
    handlePopUpClose();

    if (value >= 3 && disableBulkActionBtn) {
      Swal.fire({
        icon: "error",
        title: "No user selected!",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    if (value === 1) {
      // show profile picture
      setIsProfilePictureVisible(!isProfilePictureVisible);
    } else if (value == 2) {
      handleExcelExport();
      // excel export
    } else if (value == 3) {
      handlePopUpOpen(event, "addRule", null);
    } else if (value == 4) {
      handlePopUpOpen(event, "removeUser", null);
    } else if (value == 5) {
      handlePopUpOpen(event, "changeTestStatus", null);
    } else if (value == 6) {
      handlePopUpOpen(event, "enableCourseOn", null);
    } else if (value == 7) {
      handlePopUpOpen(event, "setCourseEndDate", null);
    } else if (value == 8) {
      handlePopUpOpen(event, "dashBoardFormCompulsion", null);
    } else if (value == 9) {
      handlePopUpOpen(event, "examStatusResult", null);
    } else if (value == 10) {
      handlePopUpOpen(event, "testStatusResult", null);
    } else if (value == 11) {
      handlePopUpOpen(event, "disableAccount", null);
    } else if (value == 12) {
      handlePopUpOpen(event, "replaceCourse", null);
    } else if (value == 13) {
      handlePopUpOpen(event, "deleteCourse", null);
    } else if (value == 14) {
      handlePopUpOpen(event, "createBSS", null);
    }
  };

  // To Search filters
  const handleFilterPopUp = async (event, index) => {
    if (index === 0) {
      getAllCategories();
      handlePopUpOpen(event, "filterCategory", null);
    } else if (index === 1) {
      await getAllCourses();
      handlePopUpOpen(event, "filterCourse", null);
    } else if (index === 2) {
      handlePopUpOpen(event, "filterExamStatus", null);
    }
  };

  const getAllCourses = async () => {
    try {
      if (allCourses.length === 0) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        const response = await CoursesServices.getAllCourses(
          { pageNo: 0, limit: 10000 },
          authToken
        );
        if (response.success) {
          setAllCourses(response.data);
        }
      }
    } catch (err) {
      console.log("Error coming while fetching all course", err);
    }
  };
  const getAllCategories = async () => {
    try {
      if (allCategories.length === 0) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        const response = await CategoryServices.getAllCategories(
          { pageNo: 0, limit: 99999 },
          authToken
        );
        if (response.success) {
          setAllCategories(response.data);
        }
      }
    } catch (err) {
      console.log("Error coming while fetching categories in categories", err);
    }
  };

  const setFilters = (key, data) => {
    let updatedFilters = produce(props.filters, (draft) => {
      draft[key] = data;
    });
    props.updateFilters(updatedFilters);
  };

  useMemo(() => {
    let mainTag = props.filters.mainTag;
    let tagStatus = props.filters.tagStatus;
    let subTag = props.filters.subTag;
    let manualTag = props.filters.manualTag;
    let yearTag = props.filters.yearTag;
    let monthTag = props.filters.monthTag;

    tagCount =
      Boolean(mainTag) +
      Boolean(tagStatus) +
      Boolean(subTag) +
      Boolean(manualTag) +
      Boolean(yearTag) +
      Boolean(monthTag);
  }, [props.filters]);

  const handleClearFilter = (index) => {
    let updatedFilters = produce(props.filters, (draft) => {
      if (index === 0) {
        draft.searchText = "";
      } else if (index === 1) {
        draft.startDate = "";
        draft.endDate = "";
      } else if (index === 2) {
        draft.categories = [];
      } else if (index === 3) {
        draft.courseIds = [];
      } else if (index === 4) {
        draft.examStatus = null;
      } else if (index === 5) {
        draft.yearTag = "";
        draft.monthTag = "";
        draft.mainTag = "";
        draft.tagStatus = "";
        draft.subTag = "";
        draft.manualTag = "";
      }
    });
    setSearchFilters(updatedFilters);
    props.updateFilters(updatedFilters);
  };
  return (
    <>
      <SidebarNavigation />
      <UsersView
        userData={userData}
        deferredData={deferredData}
        resultId={resultId}
        purchaseId={purchaseId}
        isProfilePictureVisible={isProfilePictureVisible}
        settingOptions={settingOptions}
        disableBulkActionBtn={disableBulkActionBtn}
        activeTab={activeTab}
        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        // Data
        listOfCheckedUsers={listOfCheckedUsers}
        listOfPurchaseIds={listOfPurchaseIds}
        listOfUserIds={listOfUserIds}
        handleRedirectToProfilePage={handleRedirectToProfilePage}
        handleSort={handleSort}
        handleCheckBoxes={handleCheckBoxes}
        handleExcelExport={handleExcelExport}
        handleTabClick={handleTabClick}
        // Handle tab folders
        folders={folders}
        // Handle result Modal
        resultModalRef={resultModalRef}
        isResultModalOpen={isResultModalOpen}
        onResultEyeClick={openResultPopUp}
        onCloseModal={() => setIsResultModalOpen(false)}
        // Handle PopUp
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        isPopUpVisible={isPopUpVisible}
        popUpPosition={popUpPosition}
        popUp={popUp}
        // Add User popup
        handleUserExcelInput={handleUserExcelInput}
        userExcelFileInputRef={userExcelFileInputRef}
        selectedUserIds={listOfUserIds}
        moveToArchive={moveToArchive}
        roleCode={roleCode}
        handleSettingOption={handleSettingOption}
        filters={searchFilters}
        finalFilters={props.filters}
        handleSearch={handleSearch}
        handleFilterPopUp={handleFilterPopUp}
        allCategories={allCategories}
        allCourses={allCourses}
        tagCount={tagCount}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tab: state.tabs.UserTab,
    filters: state.user.filters,
    access: state.login.access,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTab: TabsActions.updateUserTab,
      updateFilters: UserActions.updateFilters,
      updateAccess: LoginActions.updateAccess,
      updateTags: TagsActions.updateTags,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
