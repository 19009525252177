import React from 'react';

import './style.css';
import BasicTableGrid from '@components/BasicTableGrid';
import DateAndUser from '@components/DateAndUser';
import ButtonBars from '@components/ButtonBars';
import PopUp from '@components/PopUp';
import Search from '@components/Search';

import SelectionPopUp from '@components/SelectionPopUp';

import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';
import ColumnsIcon from '@assets/userDashboard/columns.png';

import {pages} from '@pages/SuperAdmin/AdminUsers/Main/data'


function AdminUsersView(props){
    return(
        <div className='AdminPageMainContainer bg-white' ref={props.fullscreenElementRef}>
            <Search
				value={props.searchText}
				placeholder = {"Search user"}
				onSearch={(event)=>{props.handleSearch(event)}}
				isDefaultChild={false}
				/>
            <div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[{type: 'round',
                                    text: '+',
                                    onClick: (event) => {
										props.handleRedirectToProfilePage(event, 'none', 'none');
									},
                                },
						]}
					/>
					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						<button onClick={(event)=>props.handlePopUpOpen(event, "setting")}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button>
						<button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button>
					</div>
            </div>

            <BasicTableGrid 
				gridId='AdminUsers'
				data={props.deferredData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,206,188,167,161,147,169,131,199,189]}
				initialSorting={[{"id": "Acc Created","desc": true}]}
				handleSelect={(selectedRows) => {props.handleCheckBoxes(selectedRows);}}
				length={20}
				// pagination={props.pagination}
				// setPagination={props.setPagination}
				onSortChange={(data) =>props.handleSort(data)}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
                    {header: 'Name',
					cell: (data) => {
					const info = data.row.original;
					return (<div className="d-flex gap-2 w-100">
                                {false ? <div className="thumbnailContainer" style={{backgroundImage:`url(${info.thumbnailUrl})`}}>
                                    </div>
                                : null}
                               		 <div onDoubleClick={(event) => {
										props.handleRedirectToProfilePage(event, info._id, 'AdminUserProfile', info);
									}}
									style={{cursor:'pointer', width:'100%', minHeight:'20px', display:'flex', alignItems:'center'}}
									>
									{info.fullName}
									</div>
								</div>);}
				},
				{header: 'Username', cell : (data)=>{return data.row.original.username}},
				{header: 'Password', cell : (data)=>{return data.row.original.password}},
				{header: 'Page rights', cell : (data)=>{
					const info = data.row.original
					return <div style={{cursor:'pointer'}} onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'pageRights', info)}}>
						{info.pageRights?.map((right)=> right+", ")}
						</div>
				}},
				// {header: 'User rights', cell : (data)=>{return data.row.original.userRights}},
                {header: 'User rights', 
					cell : (data)=>{
						const info = data.row.original
						return <div style={{cursor:'pointer'}} onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'userRights', info)}}>
							{info.userRights?.map((right)=> right?.title+", ")}
							</div>
					}
				},
                {header: 'BSS rights', 
					cell : (data)=>{
						const info = data.row.original
						return <div style={{cursor:'pointer'}} onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'bssRights', info)}}>
							{info.bssRights?.map((right)=> right?.title+", ")}
							</div>
					}
				},
                {header: 'Lead rights', 
					cell : (data)=>{
						const info = data.row.original
						return <div style={{cursor:'pointer'}} onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'leadsRights', info)}}>
							{info.leadsRights?.map((right)=> right?.title+", ")}
							</div>
					}
				},
                // {header: 'Customer care rights', 
				// 	cell : (data)=>{
				// 		const info = data.row.original
				// 		return <div style={{cursor:'pointer'}} onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'customerCareRights', info)}}>
				// 			{info.customerCareRights?.map((right)=> right?.title+", ")}
				// 			</div>
				// 	}
				// },
                {header: 'Role', 
				  cell : (data)=>{
					const info = data.row.original
					return <div style={{cursor:'pointer'}} onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'role', info)}}>
							{info.role}
							</div>
				}},
				{header: 'Acc Created', accessorFn: (row) => {},
                 cell: (data) => {
					    const info = data.row.original;
						return <DateAndUser date = {info.createdAt} image = {''} imageText = {''}/>},
			    },
                {header: 'Last active', cell: (data) => {
					const info = data.row.original;
					return <DateAndUser date = {info.lastActive} image = {''} imageText = {''}/>},
			    },	]}
				/>
				
			<PopUp visible={props.isPopUpVisible && props.popUp=='pageRights'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {pages}
				  optionKey= {"name"}
				  title={""}
				  searchPlaceholder={"Search for page"}
				  isSearchEnabled={true}
				  height= {"310px"}
				  width= {"285px"}
				  selectionType={"multiple"}
				  selectedItems={pages?.filter(obj => props?.selectedRow?.pageRights?.includes(obj.name))}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>
			<PopUp visible={props.isPopUpVisible && props.popUp=='userRights'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {props.allFolders.map((folder)=>folder.pageType ==='user' ? folder:'')}
				  optionKey= {"title"}
				  title={"Select User Rights"}
				  searchPlaceholder={"Search for 'User' folder"}
				  isSearchEnabled={true}
				  height= {"310px"}
				  width= {"285px"}
				  selectedItems={props.selectedRow?.userRights}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"multiple"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>
			<PopUp visible={props.isPopUpVisible && props.popUp=='bssRights'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {props.allFolders.map((folder)=>folder.pageType ==='bss' ? folder:'')}
				  optionKey= {"title"}
				  title={""}
				  searchPlaceholder={"Search for 'BSS' folder"}
				  isSearchEnabled={true}
				  height= {"310px"}
				  width= {"285px"}
				  selectedItems={props.selectedRow?.bssRights}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"multiple"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>
			<PopUp visible={props.isPopUpVisible && props.popUp=='leadsRights'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {props.allFolders.map((folder)=>folder.pageType ==='leads' ? folder:'')}
				  optionKey= {"title"}
				  title={""}
				  searchPlaceholder={"Search for 'Leads' folder"}
				  isSearchEnabled={true}
				  height= {"310px"}
				  width= {"285px"}
				  selectedItems={props.selectedRow?.leadsRights}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"multiple"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>
			<PopUp visible={props.isPopUpVisible && props.popUp=='customerCareRights'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {props.allFolders.map((folder)=>folder.pageType ==='customerCare' ? folder:'')}
				  optionKey= {"title"}
				  title={""}
				  searchPlaceholder={"Search for 'Customer care' folder"}
				  isSearchEnabled={true}
				  height= {"310px"}
				  width= {"285px"}
				  selectedItems={props.selectedRow?.customerCareRights}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"multiple"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>
			<PopUp visible={props.isPopUpVisible && props.popUp=='role'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {[{_id:2, type: 'standard'},{_id:1, type: 'admin'},]}
				  optionKey= {"type"}
				  title={"Set role as"}
				  isSearchEnabled={false}
				  height= {"147px"}
				  width= {"210px"}
				  selectedItems={[{_id: 1, type: props.selectedRow?.role}]}
				  dataKey={"type"}
				  selectedItemsKey={"type"}
				  selectionType={"single"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>

          
        </div>
    )
}

export default AdminUsersView