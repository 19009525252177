import React from "react";

import "./style.css";

import { Tabs, Tab } from "react-bootstrap";

import UserPurchases from "@pages/Users/Activity/Purchases";
import UserVideoHistory from "@pages/Users/Activity/VideoHistory";
import UserComments from "@pages/Users/Activity/Comments";
import UserSeries from "@pages/Users/Activity/Series";
import UserTests from "@pages/Users/Activity/Tests";

import ButtonBars from "@components/ButtonBars";

import PopUp from "@components/PopUp";
import AddCoursePopUp from "@components/Users/AddCoursePopUp";
import AddSeriesPopUp from "@components/Users/AddSeriesPopUp";
import AddTestPopUp from "@components/Users/AddTestPopUp";
import MoveToPopUp from "@components/Users/MoveToPopUp";
import SeriesStatusPopUp from "@components/Users/SeriesStatusPopUp";
import ExamStatusPopUp from "@components/Users/ExamStatusPopUp";

function UserActivityView(props) {
  return (
    <>
      <div className="contentPageMainContainer">
        <h4 style={{ marginBottom: "10px", marginLeft: "20px" }}>
          {" "}
          User Activity{" "}
        </h4>

        {props.purchasesTabSelected ||
        props.seriesTabSelected ||
        props.testsTabSelected ? (
          <ButtonBars
            buttons={[
              {
                type: "simple",
                text: "Add Course",
                onClick: (event) => {
                  props.handlePopUpOpen(event, "addCourse", null);
                },
                disabled: !props.userId,
              },
              {
                type: "simple",
                text: "Add Series",
                onClick: (event) => {
                  props.handlePopUpOpen(event, "addSeries", null);
                },
                disabled: props.selectedPurchaseIds.length === 0,
              },
              {
                type: "simple",
                text: "Add Test",
                onClick: (event) => {
                  props.handlePopUpOpen(event, "addTest", null);
                },
                disabled: !props.userId,
              },
              {
                type: "simple",
                text: "Series Status",
                onClick: (event) => {
                  props.handlePopUpOpen(event, "seriesStatus", null);
                },
                disabled: props.selectedPurchaseIds.length === 0,
              },
              {
                type: "simple",
                text: "Exam Status",
                onClick: (event) => {
                  props.handlePopUpOpen(event, "examStatus", null);
                },
                disabled: props.selectedPurchaseIds.length === 0,
              },
              {
                type: "simple",
                text: "Move to",
                onClick: (event) => {
                  props.handlePopUpOpen(event, "moveTo", null);
                },
                disabled: !props.userId,
              },
            ]}
          />
        ) : (
          ""
        )}

        <Tabs
          defaultActiveKey="purchases"
          id="myTabs"
          onSelect={props.handleTabSelect}
        >
          <Tab eventKey="purchases" title="Purchases">
            {props.purchasesTabSelected && (
              <>
                <UserPurchases />
              </>
            )}
          </Tab>

          <Tab eventKey="videoHistory" title="Video History">
            {props.videoHistoryTabSelected && <UserVideoHistory />}
          </Tab>

          <Tab eventKey="comments" title="Comments">
            {props.commentsTabSelected && <UserComments />}
          </Tab>

          <Tab eventKey="series" title="Series">
            {props.seriesTabSelected && (
              <UserSeries handlePopUpOpen={props.handlePopUpOpen} />
            )}
          </Tab>
          <Tab eventKey="tests" title="Tests">
            {props.testsTabSelected && <UserTests />}
          </Tab>
        </Tabs>
      </div>
      {props.popUp === "addCourse" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <AddCoursePopUp
            userIds={[props.userId]}
            handleClose={props.handlePopUpClose}
            handleSave={props.handlePopUpClose}
            
            isFormalRequest={props.roleCode == 2}
          />
        </PopUp>
      )}

      {props.popUp === "addSeries" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <AddSeriesPopUp
            purchaseIds={props.selectedPurchaseIds}
            handleClose={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
          />
        </PopUp>
      )}
      {props.popUp === "addTest" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <AddTestPopUp
            userIds={[props.userId]}
            handleClose={props.handlePopUpClose}
            handleSave={props.handlePopUpClose}

          />
        </PopUp>
      )}

      {props.popUp === "seriesStatus" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <SeriesStatusPopUp
            handleCancel={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
            purchaseIds={props.selectedPurchaseIds}
            seriesIds={props.seriesIds}
          />
        </PopUp>
      )}
      {props.popUp === "examStatus" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <ExamStatusPopUp
            handleCancel={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
            purchaseIds={props.selectedPurchaseIds}
          />
        </PopUp>
      )}
      {props.popUp === "moveTo" && (
        <PopUp
          visible={props.isPopUpVisible}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <MoveToPopUp
            folders={props.folders}
            handleCancel={props.handlePopUpClose}
            handleSave={(event, data) => props.handlePopUpSave(data, event)}
          />
        </PopUp>
      )}
    </>
  );
}

export default UserActivityView;
