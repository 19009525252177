
import {useState} from 'react'
import { Skeleton } from '@mui/joy'
import  './style.css'
import TextInputV2 from '@components/TextInputV2';

import {getEventElementPosition, toggleFullscreen} from '@helpers/common.js';
import PopUpContainer from '@components/PopUpContainer';
import PopUp from '@components/PopUp';
function ImpressionsContainer(props){
	const [popUp, setPopUp] =useState('');
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [popUpPosition, setPopUpPosition] = useState({});

  const [currencies, setCurrencies] = useState(props.currencies);

  const handlePopUpOpen = (event, popUpName, data) => {
		handlePopUpClose()
		setPopUp(popUpName)
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
    console.log("popUpPosition", popUpPosition, popUpName)
	};

	const handlePopUpClose = () => {
		setIsPopUpVisible(false)
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async (e) => {
		handlePopUpClose();
    props.setCurrencies(currencies)
	};

  const handleSelectedCurrencyChange = (e, index) => {
    const newCurrencies = currencies.map((currency, i) => {
      if (i === index) {
        return {
          ...currency,
          selected: true
        };
      } else {
        return {
          ...currency,
          selected: false
        };
      }
    });
    setCurrencies(newCurrencies);     
  }

  const handleValueChange = (e, index) => {
    const newCurrencies = currencies.map((currency, i) => {
      if (i === index) {
        return {
          ...currency,
          value: e.target.value
        };
      } else {
        return currency;
      }
    });
    setCurrencies(newCurrencies);   
  }


    return <div className="d-flex flex-column flex-grow-1 border rounded bg-white impressionContainer align-items-center p-2">
                <div className='d-flex flex-row w-100 justify-content-between align-items-center'>
                  <div className="d-flex flex-column w-100">
                    <span className="title w-100">{props.title}</span>
                    <span className="subTitle w-100">{props.subTitle}</span>
                </div>
                
                  {props.isCurrencyPopUp &&
                    <div style={{cursor: "pointer", border:"1px solid #bbb", borderRadius:"15px", 
                    margintVertical:"5px", padding:"5px", justifyContent:"center", alignItems:"center", gap:4, 
                    flex:"0.5", height: "70%", paddingLeft: "15px", paddingRight: "15px", fontSize:"14px"}}
                      onClick ={(e)=>handlePopUpOpen(e,"currencyPopUp")}
                     >
                      {props.currencies.filter(currency => currency.selected)[0].name}
                    </div>}
                </div>
                <div className={
                  props.funnel?
                  "d-flex flex-grow-1 border rounded trapaziod align-items-center w-100"
                  : "d-flex flex-grow-1 border rounded align-items-center w-100 bg-transparent"
                  }  style={{backgroundColor:'#efefef', padding:1}} >
                  {!props.isLoading? 
                    (props.funnel? 
                      <div className="trapaziod mt-auto d-flex flex-column h-100">
                      <div className='bg-light flex-grow-1'>
                          <div className='content'>
                            {props.child1} 
                          </div> 
                      </div>
                      <div className='bg-white flex-grow-1'>
                      <div className='content'>
                          {props.child2} 
                      </div> 
                      </div>
                      <div className='bg-light flex-grow-1'>
                          <div className='content'>
                            {props.child3} 
                          </div> 
                      </div>
                      <div className='bg-white flex-grow-1'>
                          <div className='content'>
                            {props.child4} 
                          </div> 
                      </div>
                      <div className='flex-grow-1' style={{backgroundColor:'#F3FBFF'}}>
                        <div className='content'>
                          {props.child5}
                        </div> 
                      </div>
                      </div>
                    : <div style={{width:'100%', height:'100%', padding:'8px', justifyContent:"space-between"}}>
                        {props.child1}
                    </div>)
      
                  :
                  <div className="trapaziod mt-auto d-flex flex-column h-100 trapaziod">
                      {[1,2,3,4,5].map(()=>{

                        return <div className='bg-light flex-grow-1'>
                            <div className='content'>
                              <Skeleton variant="rectangular" width="100%" height="95%">
                              </Skeleton> 
                            </div> 
                        </div>
                      })}
                      
                  </div>}
        </div>
        {props.isCurrencyPopUp && popUp === "currencyPopUp"  &&
        <PopUp visible={isPopUpVisible}
            top={popUpPosition.top+10}
            left={popUpPosition.left-150}
            handleOutSideClick={handlePopUpClose}>
              <PopUpContainer
                title={"Set currency"}
                isSearchEnabled={false}
                searchPlaceholder={""}
                isButtonsEnabled={true}
                style={{width:'200px', height:'240px'}}
                handleSearch={()=>{}}
                handleCancel={()=>{handlePopUpClose()}}
                handleSave={handlePopUpSave}
              >
            <div className='d-flex flex-column gap-2'>
               {currencies.map((currency, index)=>
               <div className="d-flex align-items-center gap-2">
                <input type="radio" checked={currency.selected} 
                onChange={(event)=>{handleSelectedCurrencyChange(event, index)}}/>
                <div>{currency.name}</div>

                {currency.name !== "INR" &&<TextInputV2 
                 size={'small'}
                    placeholder={"Value"}
                    value={currency.value}
                    required={true}
                    onChange={(event)=>{handleValueChange(event, index)}}
                /> }
                </div>)}
            </div>
            </PopUpContainer>
          </PopUp>}
      </div>
}


export default ImpressionsContainer
