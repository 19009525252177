import React from "react";
import { useState, useEffect, useRef } from 'react';
import CoverView from './view';

var editorTextEdit = false;
function Cover(props){
   
  const [courseDetails, setCourseDetails] = useState(props.courseDetails);
	const coverPointerEditorRef = useRef(null);
   
    useEffect(()=>{
      setCourseDetails(props.courseDetails)
  },[props.courseDetails])

   useEffect(()=>{
      props.handleFormDataChange(courseDetails)
   },[courseDetails])

   const handleChange=(value, field)=>{
      var updatedCourseDetails = {...courseDetails}
      if(field==='coverPointers')
      {
        if(!updatedCourseDetails.coverPointers || typeof(updatedCourseDetails.coverPointers) ==='string')
        {
          updatedCourseDetails.coverPointers={
              "icon": "",
            "pointer": ""
          }
        }
         updatedCourseDetails.coverPointers.icon = value.icon
      }else{
         updatedCourseDetails[field] = value
      }
      setCourseDetails(updatedCourseDetails)
    }

    const handleCoverPointers = (content) => {
      var updatedCourseDetails = {...courseDetails}
      if(!updatedCourseDetails.coverPointers || typeof(updatedCourseDetails.coverPointers) ==='string')
      {
        updatedCourseDetails.coverPointers={
            "icon": "",
          "pointer": ""
        }
      }
      if ( editorTextEdit) {
        // setFormValuesEmpty(false);
        const editorInstance = coverPointerEditorRef.current.getEditor();
        const text = editorInstance.getText().trim();
        if (text.length !== 0) {
          updatedCourseDetails.coverPointers.pointer = content;
          setCourseDetails(updatedCourseDetails);
          editorTextEdit = false;
        }

        if(text?.length === 0){
          updatedCourseDetails.coverPointers.pointer = "";
          setCourseDetails(updatedCourseDetails);
          editorTextEdit = false;
        }
      }
    };

    
  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debounceHandleCoverPointers = debounce(handleCoverPointers, 1000);

  
    const handleEditorTextEdit = (keyType) => {
      if (!editorTextEdit) {
        editorTextEdit = true;
      }
    };

   return(
    <CoverView
      courseDetails= {courseDetails}
      handleChange={handleChange}

      coverPointerEditorRef={coverPointerEditorRef}
      handleCoverPointers={debounceHandleCoverPointers}
      handleEditorTextEdit={handleEditorTextEdit}
    />
   )

}

export default Cover;