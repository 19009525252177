import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import "./style.css";

import UploadIcon from "@assets/common/uploadIcon.png";
import EyeIcon from "@assets/common/eye2.png";
import UploadImgPlaceHolder from "@assets/common/uploadImgPlaceHolder.png";
import DeleteIcon from "@assets/common/deleteIcon.png";
import ButtonBars from "@components/ButtonBars";
import FormFieldStatus from "@components/FormFieldStatus";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@assets/common/downArrowAccordion.png";
import PopUp from "@components/PopUp";
import SaveBtn from "@components/SaveBtn";
import AddCoursePopUp from "@components/Users/AddCoursePopUp";
import AddSeriesPopUp from "@components/Users/AddSeriesPopUp";
import AddTestPopUp from "@components/Users/AddTestPopUp";
import MoveToPopUp from "@components/Users/MoveToPopUp";

import {
  verificationStatus,
  underGraduateDegree,
  qualification,
  bloodGroup,
} from "./data";

function ProfileView(props) {
  const countries = require("@assets/countryCode.json");
  const bssDetails = props.userDetails.bssDetails;
  const isBssDetails = bssDetails?.length ? bssDetails.length : false;
  return (
    <>
      <div className="rightHandRightPageMainContainer userPageMainContainer d-flex flex-column">
        <div
          className="topContainer flex-column w-100"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div
            className="d-flex justify-content-between"
            style={{ maxWidth: "800px" }}
          >
            <h4 className="mb-3 topHeading">User Details</h4>
            <div>
              <Link
                style={{ textDecoration: "none" }}
                className="cancelBtn"
                to="/users"
              >
                Cancel
              </Link>

              <SaveBtn
                onClick={props.handleSave}
                text={"Save"}
                altText={"Saving..."}
                disabled={props.isFormSaving}
              />
            </div>
          </div>
          <div className="d-flex align-items-center w-100 justify-content-between">
            <ButtonBars
              buttons={[
                {
                  type: "simple",
                  text: "Add Course",
                  onClick: (event) => {
                    props.handlePopUpOpen(event, "addCourse");
                  },
                  disabled: !props.id,
                },
               
                {
                  type: "simple",
                  text: "Add Test",
                  onClick: (event) => {
                    props.handlePopUpOpen(event, "addTest");
                  },
                  disabled: !props.id,
                },
                {
                  type: "simple",
                  text: "Move to",
                  onClick: (event) => {
                    props.handlePopUpOpen(event, "moveTo");
                  },
                  disabled: !props.id,
                },
              ]}
            />
          </div>
        </div>
        <div className="row flex-grow-1 overflow-scroll">
          <div className="col-7">
            <div className="userProfileContentMainContainer">
              <Accordion
                defaultExpanded
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} className="accIcon" />}
                  style={{
                    flexDirection: "row-reverse",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <b>Basic Information</b>
                  <FormFieldStatus
                    status={
                      props.userDetails.isPersonalInfoCompleted
                        ? "completed"
                        : "pending"
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div className="formMainContainer">
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Username
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="username"
                        value={props.userDetails.username}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "username");
                        }}
                        placeholder="Username"
                      />
                    </div>
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Display Name
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="lastName"
                        value={props.userDetails.displayName}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "displayName");
                        }}
                        placeholder="Display Name"
                      />
                    </div>
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable d-flex">
                        Email ID
                      </p>
                      <div className="col-7 col-sm-8 col-md-9 col-lg-8 border border-top-0 border-bottom-0 border-right-0 d-flex px-2 gap-2 align-items-center">
                        <input
                          className="input flex-grow-1 border-0"
                          type="email"
                          name="email"
                          value={props.userDetails.email}
                          onChange={(event) => {
                            props.handleChange(event.target.value, "email");
                          }}
                          placeholder="Email"
                        />

                        <FormFieldStatus
                          status={
                            props.userDetails.isEmailVerified === true
                              ? "verified"
                              : "pending"
                          }
                        />
                      </div>
                      <select
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "isEmailVerified"
                          );
                        }}
                        value={props.userDetails.isEmailVerified ? true : false}
                        className="col- col-sm-8 col-md-9 col-lg-2"
                      >
                        <option value="">Select Status</option>
                        <option value={true}>Verified</option>
                        <option value={false}>Unverified</option>
                      </select>
                    </div>
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Contact Number
                      </p>

                      <select
                        value={props.userDetails.country}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "countryCode");
                        }}
                        name="country"
                        className="col-my-3 col-my-sm-3 col-my-md-2 col-my-lg-1 col-lg-2 form-select dropdown-drop-focus-effect"
                      >
                        <option value=""></option>
                        {countries.map((country, index) => (
                          <option
                            key={index}
                            countrycode={country.countryCode}
                            value={country.countryName}
                          >
                            {props.userDetails.country === country.countryName
                              ? `${country.countryCode} ${country.countryName}`
                              : `${country.countryName} ${country.countryCode} `}
                          </option>
                        ))}
                      </select>
                      <input
                        className="col-4 col-sm-5 col-md-7 col-contact-number-lg-9 col-lg-8 input"
                        type="number"
                        name="contactNumber"
                        value={props.userDetails.contactNumber}
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "contactNumber"
                          );
                        }}
                        placeholder="Contact Number"
                      />
                      <hr className="inputHr" />
                    </div>
                    <div className="formRow">
                      <p
                        style={{ fontSize: "0.8rem" }}
                        className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable"
                      >
                        WhatsApp number
                      </p>
                      <select
                        value={props.userDetails.whatsAppCountry}
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "whatsAppCountryCode"
                          );
                        }}
                        name="country"
                        className="col-my-3 col-my-sm-3 col-my-md-2 col-my-lg-1 form-select dropdown-drop-focus-effect"
                      >
                        <option value=""></option>

                        {countries.map((country, index) => (
                          <option
                            key={index}
                            countrycode={country.countryCode}
                            value={country.countryName}
                          >
                            {props.userDetails.whatsAppCountry ===
                            country.countryName
                              ? `${country.countryCode} ${country.countryName}`
                              : `${country.countryName} ${country.countryCode} `}
                          </option>
                        ))}
                      </select>
                      <input
                        className="col-4 col-sm-5 col-md-7 col-contact-number-lg-9 input"
                        type="number"
                        name="whatsappNumber"
                        placeholder="Whatsapp Number"
                        value={props.userDetails.whatsAppNumber}
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "whatsAppNumber"
                          );
                        }}
                      />
                    </div>
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Birthday
                        <span id="dobError">
                          Invalid <br /> data
                        </span>
                      </p>

                      <input
                        onChange={(event) => {
                          props.handleChange(event.target.value, "dob");
                        }}
                        style={{
                          color: props.userDetails.dob ? "black" : "#757575",
                        }}
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="date"
                        id="dob"
                        name="dob"
                        placeholder="Birthday"
                        value={moment(props.userDetails.dob).format(
                          "YYYY-MM-DD"
                        )}
                      />
                      <hr className="inputHr" />
                    </div>
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Notes
                      </p>
                      <textarea
                        className="col-7 col-sm-8 col-md-9 col-lg-10"
                        type="text"
                        name="notes"
                        value={props.userDetails.notes}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "notes");
                        }}
                        placeholder="Notes"
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} className="accIcon" />}
                  style={{ flexDirection: "row-reverse", gap: "20px" }}
                >
                  <b>Legal Information</b>
                  <FormFieldStatus
                    status={
                      props.userDetails.isLegalInfoCompleted
                        ? "completed"
                        : "pending"
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div className="formMainContainer">
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        First Name<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="firstname"
                        value={props.userDetails.firstName}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "firstName");
                        }}
                        placeholder="First Name"
                      />
                    </div>
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Middle Name
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="middleName"
                        value={props.userDetails.middleName}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "middleName");
                        }}
                        placeholder="Middle Name"
                      />
                    </div>
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Last Name<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="lastName"
                        value={props.userDetails.lastName}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "lastName");
                        }}
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} className="accIcon" />}
                  style={{ flexDirection: "row-reverse", gap: "20px" }}
                >
                  <b>Delivery Address</b>
                  <FormFieldStatus
                    status={
                      props.userDetails.isAddressInfoCompleted
                        ? "completed"
                        : "pending"
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div className="formMainContainer">
                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Flat/House No<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="flatHouseNo"
                        value={props.userDetails.flatHouseNumber}
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "flatHouseNumber"
                          );
                        }}
                        placeholder="Flat/House No."
                      />
                    </div>

                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Building Name<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="buildingName"
                        value={props.userDetails.buildingName}
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "buildingName"
                          );
                        }}
                        placeholder="Building Name"
                      />
                    </div>

                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Colony Name<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="colonyName"
                        value={props.userDetails.colonyName}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "colonyName");
                        }}
                        placeholder="Colony Name"
                      />
                    </div>

                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Area Name<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="areaName"
                        value={props.userDetails.areaName}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "areaName");
                        }}
                        placeholder="Area Name"
                      />
                    </div>

                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Landmark<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="landmark"
                        value={props.userDetails.landmark}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "landmark");
                        }}
                        placeholder="Landmark"
                      />
                    </div>

                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Country<span className="required">*</span>
                      </p>
                      <select
                        onChange={(event) => {
                          props.handleChange(event.target.value, "country");
                        }}
                        name="country"
                        className="col-7 col-sm-8 col-md-9 col-lg-10"
                      >
                        <option value={""}>
                          {props.userDetails.country
                            ? props.userDetails.country
                            : `Select country`}
                        </option>

                        {countries.map((country, index) => (
                          <option key={index} value={country.countryName}>
                            {country.countryName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        Pin Code<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="pinCode"
                        value={props.userDetails.pincode}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "pincode");
                        }}
                        placeholder="Pin Code"
                      />
                    </div>

                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        City<span className="required">*</span>
                      </p>
                      <input
                        className="col-7 col-sm-8 col-md-9 col-lg-10 input"
                        type="text"
                        name="city"
                        value={props.userDetails.city}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "city");
                        }}
                        placeholder="City"
                      />
                    </div>

                    <div className="formRow">
                      <p className="col-5 col-sm-4 col-md-3 col-lg-2 inputLable">
                        State<span className="required">*</span>
                      </p>
                      <select
                        id="allStates"
                        onChange={(event) => {
                          props.handleChange(event.target.value, "state");
                        }}
                        name="state"
                        className="col-7 col-sm-8 col-md-9 col-lg-10"
                      >
                        <option value={""}>
                          {props.userDetails
                            ? props.userDetails.state
                            : "Select state"}
                        </option>

                        {props.allStates &&
                          props.allStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} className="accIcon" />}
                  style={{ flexDirection: "row-reverse", gap: "20px" }}
                >
                  <b>Identity Verification</b>
                  <div>{props.userDetails.isIdentityInfoCompleted}</div>
                  <FormFieldStatus
                    status={
                      props.userDetails.isIdentityInfoCompleted
                        ? "completed"
                        : "pending"
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div className="formMainContainer">
                    <div className="formRow">
                      <p className="col-9  inputLable">
                        Have You finished your undergraduate degree?
                      </p>

                      <select
                        className="col-3"
                        value={props.userDetails.isUnderGraduate}
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "isUnderGraduate"
                          );
                        }}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                    <>
                      {props.userDetails.isUnderGraduate ? (
                        <div className="formRow">
                          <p className="col-9  inputLable">
                            Select Under-graduate Degree
                          </p>
                          <select
                            className="col-3"
                            value={props.userDetails.underGraduateDegree}
                            onChange={(event) => {
                              props.handleChange(
                                event.target.value,
                                "underGraduateDegree"
                              );
                            }}
                          >
                            <option value={""}>Select Degree</option>

                            {props.allUGDegrees.map((degree, index) => (
                              <option value={degree.title} key={index}>
                                {degree.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className="formRow">
                          <p className="col-9  inputLable">
                            Select Latest Qualification
                          </p>
                          <select
                            className="col-3 "
                            value={props.userDetails.qualification}
                            onChange={(event) => {
                              props.handleChange(
                                event.target.value,
                                "qualification"
                              );
                            }}
                          >
                            <option value={""}>Select Qualification</option>
                            {props.allQualifications.map(
                              (qualification, index) => (
                                <option value={qualification} key={index}>
                                  {qualification}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      )}
                    </>

                    <div className="formRow">
                      <div className="col-9 inputLable d-flex px-2">
                        Marksheet/Degree Certificate
                      </div>
                      <div className="col-3">
                        <button
                          id="marksheet_uploadBtn"
                          className="gridUploadButton"
                          onClick={(e) =>
                            props.handleFileUpload(
                              "imageAttachInput",
                              "marksheetOrDegreeCert"
                            )
                          }
                        >
                          Attach
                          <img
                            className="uploadIcon"
                            src={UploadIcon}
                            alt="UploadIcon"
                          />
                          {props.userDetails?.marksheetOrDegreeCert ? (
                            <img
                              className="gridViewDocButton"
                              src={EyeIcon}
                              onClick={(e) =>
                                props.showDocument(
                                  e,
                                  "marksheetOrDegreeCert",
                                  false
                                )
                              }
                            />
                          ) : null}
                        </button>
                      </div>
                    </div>
                    <div className="formRow">
                      <p className="col-9 inputLable">
                        Name as on Marksheet/ Degree Certificate
                      </p>
                      <input
                        className="col-3 input"
                        type="text"
                        name="fullName"
                        value={props.userDetails.fullName}
                        onChange={(event) => {
                          props.handleChange(event.target.value, "fullName");
                        }}
                      />
                    </div>
                    <div className="formRow">
                      <div className="col-9 d-flex px-2 inputLable">
                        ID Proof
                      </div>
                      <div className="col-3 ">
                        <button
                          id="marksheet_uploadBtn"
                          className="gridUploadButton"
                          onClick={(e) =>
                            props.handleFileUpload(
                              "imageAttachInput",
                              "addharOrIdProof"
                            )
                          }
                        >
                          Attach
                          <img
                            className="uploadIcon"
                            src={UploadIcon}
                            alt="UploadIcon"
                          />
                          {props.userDetails?.addharOrIdProof ? (
                            <img
                              className="gridViewDocButton"
                              src={EyeIcon}
                              onClick={(e) =>
                                props.showDocument(e, "addharOrIdProof", false)
                              }
                            />
                          ) : null}
                        </button>
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="col-9 d-flex px-2 inputLable">
                        Legal Photo
                      </div>
                      <div className="col-3">
                        <button
                          id="marksheet_uploadBtn"
                          className="gridUploadButton"
                          onClick={(e) =>
                            props.handleFileUpload(
                              "imageAttachInput",
                              "passportPhoto"
                            )
                          }
                        >
                          Attach
                          <img
                            className="uploadIcon"
                            src={UploadIcon}
                            alt="UploadIcon"
                          />
                          {props.userDetails?.passportPhoto ? (
                            <img
                              className="gridViewDocButton"
                              src={EyeIcon}
                              onClick={(e) =>
                                props.showDocument(e, "passportPhoto", false)
                              }
                            />
                          ) : null}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="formMainContainer mt-2">
                    <div className="formRow">
                      <p className="col-9 inputLable">
                        I acknowledge that I have provided correct information
                        and documents.
                      </p>
                      <select
                        className="col-3"
                        value={props.userDetails.identityAcknowledged}
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "identityAcknowledged"
                          );
                        }}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} className="accIcon" />}
                  style={{ flexDirection: "row-reverse", gap: "20px" }}
                >
                  <b>BSS Requirements</b>
                  <FormFieldStatus
                    status={
                      isBssDetails
                        ? bssDetails[0]?.isBssInfoCompleted
                          ? "completed"
                          : "pending"
                        : "pending"
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <h6>FORM DETAILS</h6>
                  <div className="formMainContainer">
                    <div className="formRow">
                      <p className="col-3 inputLable">
                        Name of the father/guardian
                      </p>
                      <input
                        className="col-6 input"
                        type="text"
                        name="fullName"
                        value={isBssDetails ? bssDetails[0]?.guardianName : ""}
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "guardianName"
                          );
                        }}
                      />
                      <select
                        className="col-3"
                        value={
                          isBssDetails ? bssDetails[0]?.guardianNameStatus : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "guardianNameStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable">Undergraduate Degree</p>
                      <select
                        className="col-6"
                        value={
                          isBssDetails ? bssDetails[0]?.underGraduateDegree : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "underGraduateDegree"
                          );
                        }}
                      >
                        <option value={""}>Select status</option>
                        {props.allUGDegrees.map((degree, index) => (
                          <option value={degree.title} key={index}>
                            {degree.title}
                          </option>
                        ))}
                      </select>
                      <select
                        className="col-3"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.underGraduateDegreeStatus
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "underGraduateDegreeStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable text-truncate">
                        Name of Institute for Undergraduate Degree
                      </p>
                      <input
                        className="col-6 input"
                        type="text"
                        name="underGraduateInstituteName"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.underGraduateInstituteName
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "underGraduateInstituteName"
                          );
                        }}
                      />
                      <select
                        className="col-3"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.underGraduateInstituteNameStatus
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "underGraduateInstituteNameStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable text-truncate">
                        Address of Institute for Undergraduate Institute
                      </p>
                      <input
                        className="col-6 input"
                        type="text"
                        name="underGraduateInstituteAddress"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.underGraduateInstituteAddress
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "underGraduateInstituteAddress"
                          );
                        }}
                      />
                      <select
                        className="col-3"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.underGraduateInstituteAddressStatus
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "underGraduateInstituteAddressStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable text-truncate">
                        Blood Group
                      </p>
                      <select
                        className="col-9"
                        value={isBssDetails ? bssDetails[0]?.bloodGroup : ""}
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "bloodGroup"
                          );
                        }}
                      >
                        <option value={""}>Select blood group</option>
                        {bloodGroup.map((group, index) => (
                          <option value={group.value} key={index}>
                            {group.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable text-truncate">
                        Registration Number
                      </p>
                      <input
                        className="col-6 input"
                        type="text"
                        name="registrationNumber"
                        value={
                          isBssDetails ? bssDetails[0]?.registrationNumber : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "registrationNumber"
                          );
                        }}
                      />
                      <select
                        className="col-3"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.registrationNumberStatus
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "registrationNumberStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable text-truncate">
                        Year of passing
                      </p>
                      <input
                        className="col-6 input"
                        type="text"
                        name="passingYear"
                        value={isBssDetails ? bssDetails[0]?.passingYear : ""}
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "passingYear"
                          );
                        }}
                      />
                      <select
                        className="col-3"
                        value={
                          isBssDetails ? bssDetails[0]?.passingYearStatus : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "passingYearStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <h6 className="pt-4">DOCUMENTS</h6>
                  <div className="formMainContainer">
                    <div className="formRow">
                      <div className="col-6 d-flex justify-content-end px-2 inputLable">
                        <span className="me-auto">Aadhaar Card</span>

                        {isBssDetails ? (
                          <FormFieldStatus
                            status={
                              bssDetails[0]?.aadhaarVerificationStatus ===
                              "unVerified"
                                ? "pending"
                                : bssDetails[0]?.aadhaarVerificationStatus.toLowerCase()
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <select
                        className="col-3"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.aadhaarVerificationStatus
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "aadhaarVerificationStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                      <div className="col-3">
                        <button
                          id="aadhaarCard_uploadBtn"
                          className="gridUploadButton"
                          onClick={(e) =>
                            props.handleFileUpload(
                              "imageAttachInput",
                              "aadhaarCard",
                              true
                            )
                          }
                        >
                          Attach
                          <img
                            className="uploadIcon"
                            src={UploadIcon}
                            alt="UploadIcon"
                          />
                          {isBssDetails ? (
                            <>
                              {bssDetails[0]?.aadhaarCard ? (
                                <img
                                  className="gridViewDocButton"
                                  src={EyeIcon}
                                  onClick={(e) =>
                                    props.showDocument(e, "aadhaarCard", true)
                                  }
                                />
                              ) : null}
                            </>
                          ) : null}
                        </button>
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="col-6 d-flex px-2 inputLable justify-content-end">
                        <span className="me-auto">Pan Card</span>

                        {isBssDetails ? (
                          <FormFieldStatus
                            status={
                              bssDetails[0]?.panVerificationStatus ===
                              "unVerified"
                                ? "pending"
                                : bssDetails[0]?.panVerificationStatus.toLowerCase()
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <select
                        className="col-3"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.panVerificationStatus
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "panVerificationStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                      <div className="col-3">
                        <button
                          id="panCard_uploadBtn"
                          className="gridUploadButton"
                          onClick={(e) =>
                            props.handleFileUpload(
                              "imageAttachInput",
                              "panCard",
                              true
                            )
                          }
                        >
                          Attach
                          <img
                            className="uploadIcon"
                            src={UploadIcon}
                            alt="UploadIcon"
                          />
                          {isBssDetails ? (
                            bssDetails[0]?.panCard ? (
                              <img
                                className="gridViewDocButton"
                                src={EyeIcon}
                                onClick={(e) =>
                                  props.showDocument(e, "panCard", true)
                                }
                              />
                            ) : null
                          ) : null}
                        </button>
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="col-6 d-flex px-2 inputLable justify-content-end">
                        <span className="me-auto">Other documents</span>
                        {isBssDetails ? (
                          <FormFieldStatus
                            status={
                              bssDetails[0]?.otherDocsVerificationStatus ===
                              "unVerified"
                                ? "pending"
                                : bssDetails[0]?.otherDocsVerificationStatus.toLowerCase()
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <select
                        className="col-3"
                        value={
                          isBssDetails
                            ? bssDetails[0]?.otherDocsVerificationStatus
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "otherDocsVerificationStatus"
                          );
                        }}
                      >
                        {verificationStatus.map((status, index) => (
                          <option value={status.value} key={index}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                      <div className="col-3">
                        <button
                          id="otherDocs_uploadBtn"
                          className="gridUploadButton"
                          onClick={(e) =>
                            props.handleFileUpload(
                              "imageAttachInput",
                              "otherDocs",
                              true
                            )
                          }
                        >
                          Attach
                          <img
                            className="uploadIcon"
                            src={UploadIcon}
                            alt="UploadIcon"
                          />
                          {isBssDetails ? (
                            bssDetails[0]?.otherDocs ? (
                              <img
                                className="gridViewDocButton"
                                src={EyeIcon}
                                onClick={(e) =>
                                  props.showDocument(e, "otherDocs", true)
                                }
                              />
                            ) : null
                          ) : null}
                        </button>
                      </div>
                    </div>
                  </div>

                  <h6 className="pt-4">Signature</h6>
                  <div className="formMainContainer">
                    <div className="formRow">
                      <div className="col-3 inputLable">
                        Signature of the candidate
                      </div>
                      <div className="col-9">
                        <button
                          id="signature_uploadBtn"
                          className="gridUploadButton"
                          onClick={(e) =>
                            props.handleFileUpload(
                              "imageAttachInput",
                              "signature",
                              true
                            )
                          }
                        >
                          Attach
                          <img
                            className="uploadIcon"
                            src={UploadIcon}
                            alt="UploadIcon"
                          />
                          {isBssDetails ? (
                            bssDetails[0]?.signature ? (
                              <img
                                className="gridViewDocButton"
                                src={EyeIcon}
                                onClick={(e) =>
                                  props.showDocument(e, "signature", true)
                                }
                              />
                            ) : null
                          ) : null}
                        </button>
                      </div>
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable">Place of the signature</p>
                      <input
                        className="col-9 input"
                        type="text"
                        name="placeOfSignature"
                        value={
                          isBssDetails ? bssDetails[0]?.placeOfSignature : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "placeOfSignature"
                          );
                        }}
                      />
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable">Date of signature</p>
                      <input
                        className="col-9 input"
                        type="date"
                        name="dateOfSignature"
                        value={
                          isBssDetails
                            ? moment(bssDetails[0]?.dateOfSignature).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "dateOfSignature"
                          );
                        }}
                      />
                    </div>
                    <div className="formRow">
                      <p className="col-9 inputLable">
                        I hereby declare that the entries made above are correct
                        and that they have been made...
                      </p>
                      <select
                        className="col-3"
                        value={
                          isBssDetails ? bssDetails[0]?.bssAcknowledged : ""
                        }
                        onChange={(event) => {
                          props.handleBSSChange(
                            event.target.value,
                            "bssAcknowledged"
                          );
                        }}
                      >
                        <option value="">Select status</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                defaultExpanded
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} className="accIcon" />}
                  style={{ flexDirection: "row-reverse", gap: "20px" }}
                >
                  <b>Other Settings</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="formMainContainer">
                    <div className="formRow">
                      <p className="col-3 inputLable">OTP required</p>
                      <select
                        className="col-9"
                        value={props.userDetails?.isOTPRequired}
                        onChange={(event) => {
                          props.handleChange(
                            event.target.value,
                            "isOTPRequired"
                          );
                        }}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                    <div className="formRow">
                      <p className="col-3 inputLable text-truncate">
                        Change password
                      </p>
                      <input
                        className="col-6 input"
                        type="text"
                        name="password"
                        value={props.password}
                        onChange={props.handlePassword}
                      />
                      <div className="col-3 d-flex align-items-center">
                        <button
                          className="saveBtn"
                          onClick={props.updatePassword}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>

                  <h6 className="pt-3">DASHBOARD FORM SECTIONS COMPULSORY</h6>

                  <div className="formMainContainer">
                    <div className="formRow">
                      <div className="col-6 inputLable border-right ps-2">
                        <input
                          className="me-2"
                          type="checkbox"
                          checked={props.userDetails.isBasicDetailsRequired}
                          onChange={(e) =>
                            props.handleChange(
                              !props.userDetails.isBasicDetailsRequired,
                              "isBasicDetailsRequired"
                            )
                          }
                        />
                        Basic details
                      </div>
                      <div className="col-6 inputLable border-right ps-2">
                        <input
                          className="me-2"
                          type="checkbox"
                          checked={
                            props.userDetails.isIdentityVerificationRequired
                          }
                          onChange={(e) =>
                            props.handleChange(
                              !props.userDetails.isIdentityVerificationRequired,
                              "isIdentityVerificationRequired"
                            )
                          }
                        />
                        Identity verification
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="col-6 inputLable ps-2">
                        <input
                          className="me-2"
                          type="checkbox"
                          checked={props.userDetails.isLegalInfoRequired}
                          onChange={(e) =>
                            props.handleChange(
                              !props.userDetails.isLegalInfoRequired,
                              "isLegalInfoRequired"
                            )
                          }
                        />
                        Legal Information
                      </div>
                      <div className="col-6 inputLable border-right ps-2">
                        <input
                          className="me-2"
                          type="checkbox"
                          checked={props.userDetails.isBSSDetailsRequired}
                          onChange={(e) =>
                            props.handleChange(
                              !props.userDetails.isBSSDetailsRequired,
                              "isBSSDetailsRequired"
                            )
                          }
                        />
                        BSS Details
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="col-6 inputLable ps-2 border-right">
                        <input
                          className="me-2"
                          type="checkbox"
                          checked={props.userDetails.isDeliveryAddressRequired}
                          onChange={(e) =>
                            props.handleChange(
                              !props.userDetails.isDeliveryAddressRequired,
                              "isDeliveryAddressRequired"
                            )
                          }
                        />
                        Delivery Address
                      </div>
                      <div className="col-6 inputLable border-right"></div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <input
            type="file"
            className="d-none"
            accept="image/jpeg,image/jpg,image/png"
            id="imageAttachInput"
            key={props.inputKey}
            onChange={props.convertToBase64}
          />
          <div className="col-3">
            <div
              className="w-100 pt-4"
              style={{ position: "sticky", top: "0" }}
            >
              <div className="userProfileImgMainContainer mt-4">
                <img
                  id="profileImage_uploadBtn"
                  onClick={(e) =>
                    props.handleFileUpload("imageAttachInput", "displayPhoto")
                  }
                  src={
                    props.userDetails?.displayPhoto
                      ? props.userDetails.displayPhoto
                      : UploadImgPlaceHolder
                  }
                  className="uploadIcon"
                  alt="UploadImgPlaceHolder"
                />

                <img
                  onClick={(event) => {
                    props.handleDeleteProfilePicture(event);
                  }}
                  className="deleteIcon"
                  src={DeleteIcon}
                  alt="DeleteIcon"
                />
              </div>

              <span className="w-100 text-center" style={{ color: "#898989" }}>
                Display Picture
              </span>
            </div>
          </div>
        </div>
        <PopUp
          visible={props.isPopUpVisible && props.popUp === "docView"}
          top={100}
          left={100}
          handleOutSideClick={props.handlePopUpClose}
        >
          <div
            className="full-page-overlay p-4 overflow-scroll"
            onClick={props.handlePopUpClose}
          >
            <img
              src={props.previewDocument}
              onClick={(event) => event.stopPropagation()}
              className="bg-white"
              style={{ maxWidth: "70%", maxHeight: "70%" }}
            />
          </div>
        </PopUp>

        {props.popUp === "addCourse" && (
          <PopUp
            visible={props.isPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={props.handlePopUpClose}
          >
            <AddCoursePopUp
              userIds={[props.id]}
              handleClose={props.handlePopUpClose}
              handleSave={props.handlePopUpClose}
              
            isFormalRequest={props.roleCode == 2}
            />
          </PopUp>
        )}

        {/* {props.popUp === "addSeries" && (
          <PopUp
            visible={props.isPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={props.handlePopUpClose}
          >
            <AddSeriesPopUp
              purchaseIds={props.listOfCheckedUsers.map(
                (user) => user.purchaseId
              )}
              handleClose={props.handlePopUpClose}
              handleSave={props.handlePopUpSave}
            />
          </PopUp>
        )} */}

        {props.popUp === "addTest" && (
          <PopUp
            visible={props.isPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={props.handlePopUpClose}
          >
            <AddTestPopUp
              userIds={[props.id]}
              handleClose={props.handlePopUpClose}
              handleSave={props.handlePopUpClose}

            />
          </PopUp>
        )}

        {props.popUp === "moveTo" && (
          <PopUp
            visible={props.isPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={props.handlePopUpClose}
          >
            <MoveToPopUp
              folders={props.folders}
              handleCancel={props.handlePopUpClose}
              handleSave={(event, data) => props.handlePopUpSave(data, event)}
            />
          </PopUp>
        )}
      </div>
    </>
  );
}

export default ProfileView;
