import React from 'react';
import "./style.css";
import Logo from "@assets/common/hdfcLogo.png";
import moment from "moment";
import { RobotoRegular } from "@assets/Roboto-Regular-normal.js"

function StripeInvoice(
    {data}

) {
    var timeZoneName = moment.tz.guess();

    return <div className="invoiceFormStripe">
            <div className='d-flex gap-2'>
                <div>
                    <img src={Logo} height="55px"/>
                </div>
                <div style={{flexGrow:1}} className='d-flex flex-column gap-2 py-2'>
                    <h3 style={{color:"#434343", fontSize:'25px'}}>INTELEGENT SERVICES FZC</h3>
                    <div>
                    Buisness Centre, Sharjah <br/>
                    Publishing City Free Zone, Sharjah<br/>
                    United Arab Emirates
                </div>
                </div>
                <div style={{flex:1, justifyContent:'flex-end', 
                alignItems:'flex-end', marginTop:'10px', 
                    fontSize: 25, fontWeight: 700, 
                    color:'#5c709e', textAlign: 'right', paddingTop:'50px'}}>
                    INVOICE
                </div>
            </div>
           
            <div className='d-flex flex-row py-2 gap-2' style={{borderTop:'1px solid #5c709f'}}>
                <div style={{flex:0.62}}>
                    <b>BILLED TO</b>
                    <div style={{lineHeight: 1.5, marginTop:'7px'}}>
                        {data.firstName} {data.lastName}
                        <br></br>
                        Country Name: {data.countryName}
                        <br></br>
                        Country Code + Mobile: {data.countryCode}
                        {data.contactNumber}
                    </div>
                </div>
                <div style={{flex:0.38}}>
                    <div className='d-flex flex-column gap-2 '>
                        <div className='d-flex justify-content-between'>
                            <span>Invoice #</span>
                            <span style={{fontWeight: 700}}>{data.invoiceId}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Invoice Date</span>
                            <span style={{fontWeight: 700}}>{moment(data.createdAt).format("MMMM DD, YYYY")}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Invoice Amount</span>
                            <span style={{fontWeight: 700, fontFamily: 'Roboto, sans-serif'}}>{data.orderAmount}</span>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-2 pt-2 mt-2' style={{borderTop:'1px solid #5c709f'}}>
                        <div className='d-flex justify-content-between'>
                            <span>Transaction Date</span>
                            <span style={{fontWeight: 700}}>{moment(data.createdAt).format("DD-MM-YYYY")}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Transaction ID</span>
                            <span style={{fontWeight: 700, textWrap: 'wrap', wordWrap: 'break-word', width:'110px'}}>{data.paymentId}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Payment Mode</span>
                            <span style={{fontWeight: 700}}>{data.paymentMethod}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Transaction Time</span>
                            <span style={{fontWeight: 700}}>{`${moment(data.createdAt).tz(timeZoneName).format('LT (z)')}`}</span>
                        </div>
                    </div>
                </div>
            </div>
            <table>
                <tr>
                    <td style={{borderTop:'1px solid #5c709f', borderBottom:'1px solid #5c709f'}}>Sr. No.</td>
                    <td style={{borderTop:'1px solid #5c709f', borderBottom:'1px solid #5c709f'}}>Description</td>
                    <td style={{borderTop:'1px solid #5c709f', borderBottom:'1px solid #5c709f'}}>AMOUNT</td>
                </tr>
                {data.courses.map((course,index)=>{
                    return <tr key={index}>
                        <td style={{fontWeight:"bold"}}>{index+1}</td>
                        <td style={{fontWeight:"bold"}}>{course.title}</td>
                        <td style={{fontFamily: 'Roboto, sans-serif'}}>{course.isInOffer?
                      course.offerPrice:
                      course.crossedPrice    
                    }</td>
                    </tr>
                })}
                <tr>
                    <td></td>
                    <td style={{textAlign:"right", fontWeight:"bold"}}>Discount Coupon</td>
                    <td style={{fontFamily: 'Roboto, sans-serif'}}>{data.currencySymbol} {data.discountInNumbers+ data.gatewayDiscountInNumber}</td>
                </tr>
                <tr>
                    <td></td>
                    <td style={{textAlign:"right", fontWeight:"bold"}}>VAT 0%</td>
                    <td style={{fontFamily: 'Roboto, sans-serif'}}>{data.currencySymbol} 0.0</td>
                </tr>
                <tr>
                    <td style={{borderTop:'1px solid #5c709f', borderBottom:'1px solid #5c709f'}}></td>
                    <td  style={{textAlign:"right", fontWeight:"bold",borderTop:'1px solid #5c709f', borderBottom:'1px solid #5c709f'}}>TOTAL AMOUNT</td>
                    <td style={{borderTop:'1px solid #5c709f', borderBottom:'1px solid #5c709f', fontFamily: 'Roboto, sans-serif'}}>{data.orderAmount}</td>
                </tr>
            </table>

            <div className="mt-auto gap-2 d-flex flex-column align-items-center pb-1" 
            style={{ marginTop:'auto'}}>
                <h3 style={{textAlign:"center", color:'#5c709e'}}>
                    Thank you for trusting us
                </h3>
                <div style={{textAlign:"center", color:"grey", fontSize:'14px'}}>
                    For any queries related to this document please contact us
                </div>
                <span style={{textAlign:"center", color:"#5c709e", 
                fontWeight:"bold"}}>
                   intelegentservicesfzc@gmail.com
                </span>
                <div style={{textAlign:'center', color:'grey', fontWeight:'bold', marginTop:'5px'}}>
                    eMediskill Institute of Science & Technology Pvt. Ltd.<br/>
                    A subsidairy of Intelegent Services FZE
                </div>
            </div>

            <div style={{backgroundColor: '#5c709f', width:"100%", height:'15px',
                }}>

            </div>


        </div>

}


export default StripeInvoice;