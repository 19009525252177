import React from 'react';
import './userPurchases.css';

import BasicTableGrid from '@components/BasicTableGrid';

import EditPencilIcon from '@assets/common/editPencilIcon.png';
import ShareIcon from '@assets/common/shareIcon.png';
import CopyIcon from '@assets/common/copyIcon.png';
import AnalyticsIcon from '@assets/common/analyticsIcon.png';
import CommentsIcon from '@assets/common/commentsIcon.png';
import DateAndUser from '@components/DateAndUser';
function UserPurchasesView(props) {
	return (
		<>

           <BasicTableGrid gridId= "userVideoHistory" // this must be unique for each grid
				        data={props.deferedVideoHistory}  // Data to be displayed 
						fixedCols = {3} // How many columns are fixed and not scrolling
						initialSize={[35,782,497]}
						// handleSelect = {(selectedRows)=>{props.handleCheckBoxes(selectedRows)}} 
						length = {20}
						handleSelect = {()=>{}}
						pagination = {props.pagination}
						setPagination = {props.setPagination}
						onSortChange = {(data)=>{}}
						// columnsOptionPopUpRef = {props.columnsOptionPopUpRef}

						// Columns to be extracted from data
						columns = {[
							{   header: 'Title', cell: (data)=>{
															   const videoData = data.row.original
								                               return <div className="d-flex gap-4">
																			<img
																					style={{width:'140px'}}
																					src={videoData?.thumbnailUrl}
																					alt='VideoThumbnail'
																				/>
																	        <div className = 'd-flex flex-column justify-content-center gap-2'>
																				<span>{videoData?.title} </span>
																				<span>{videoData?.course} </span>
																				<div className="hidden">
																				<div className='hiddenDiv'>
																					<div className='d-flex gap-1'>
																					<img onClick={(event) => {
																								props.handleRedirectToHome(event, videoData._id, 'VideoDetails');
																							}}
																							src={EditPencilIcon}
																							alt='EditPencilIcon'
																						/>

																						<img
																							onClick={(event) => {
																								props.handleRedirectToHome(event, videoData._id, 'VideoComments');
																							}}
																							src={CommentsIcon}
																							alt='CommentsIcon'
																						/>

																						<img
																							onClick={(event) => {
																								props.handleRedirectToHome(event, videoData._id, 'VideoAnalytics');
																							}}
																							src={AnalyticsIcon}
																							alt='AnalyticsIcon'
																						/>

																						<a
																							href={
																								`${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
																								'/video/v/' +
																								videoData._id
																							}
																							target='_blank'>
																							<img src={ShareIcon} alt='ShareIcon' />
																						</a>

																						<img
																							src={CopyIcon}
																							alt='CopyIcon'
																							className='clipboard'
																							onClick={() => {
																								props.handleCopyClick(
																									`${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
																										'/video/v/' +
																										videoData._id
																								);
																							}}
																						/>

																				    </div>							
																			</div>
																		</div>
																	</div>
																</div>
															}
						    },
							{header: 'Date and time', accessorFn: (row) => {return row.createdAt},
								cell: (data) => {
									const info = data.row.original;
									return <DateAndUser
									date = {info.createdAt}
									image = {''}
									imageText = {''}/>
								},
							},
							
						]}
            />
			
		</>
	);
}

export default UserPurchasesView;
