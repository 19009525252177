import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import InfoIcon from "@assets/common/infoIcon.png";
import ProfileView from "./view";
import moment from "moment";

import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";
import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";
import { useNavigate } from "react-router-dom";

import { produce } from "immer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as UserServices from "@services/Users";
import * as UserActions from "@redux/actions/Users";
import * as FolderServices from "@services/Folders";

function Profile(props) {
  let navigate = useNavigate();
  let { id } = useParams();
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [allFolders, setAllFolders] = useState([]);

  let authToken = decryptText(localStorage.getItem("aEmediat"));

  useEffect(() => {
    if (id) {
      getUserDetails(id);
    }
    getAllFolders();
  }, []);

  const getUserDetails = async (userId) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await UserServices.getUserDetails(userId, authToken);
      if (response.success) {
        setUserDetails(response.data);
        return;
      }
      alert(response.message);
    } catch (err) {
      console.log("Error coming while fetching  user details", err);
    }
  };
  const getAllFolders = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: "0",
        limit: "1000000",
        isSorted: "true",
        sortBy: "updatedAt",
        searchText: "",
        visibility: "active",
      };

      var response = await FolderServices.getAllFolders(payload, authToken);
      if (response.success) {
        setAllFolders(response.data);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while getting all Folders", err);
    }
  };

  const handleSave = async () => {
    try {
      let requiredFields = [
        "fullName",
        "username",
        "password",
        "pageRights",
        "userRights",
        "bssRights",
        // "leadsRights",
        // "customerCareRights",
        "role",
      ];

      const payload = {
        userId: id ? id : null,
        role: userDetails.role,
        roleCode: userDetails.roleCode,
        fullName: userDetails.fullName,
        username: userDetails.username,
        password: userDetails.password,
        confirmPassword: userDetails.password,
        pageRights: userDetails.pageRights ? userDetails.pageRights : [],
        userRights: userDetails.userRights?.map((right) => right?._id),
        bssRights: userDetails.bssRights?.map((right) => right?._id),
        leadsRights: userDetails.leadsRights?.map((right) => right?._id),
        // customerCareRights: userDetails.customerCareRights?.map(
        //   (right) => right._id
        // ),
        displayPhoto: userDetails.displayPhoto,
      };

      for (let item in payload) {
        if (requiredFields.includes(item) && !payload[item]) {
          Swal.fire({
            icon: "warning",
            title: `${item.charAt(0).toUpperCase() + item.slice(1)} Required!`,
            showConfirmButton: false,
            timer: 2500,
          });
          return;
        }
      }
      const response = await UserServices.addAndUpdateUser(payload, authToken);

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {

       if(response.message.includes("E11000 duplicate key error collection"))
        {
          response.message ="Username already exists. Please try with different username"
        }

        Swal.fire({
          icon: "warning",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while adding new user", err);
    }
  };

  const handleChange = (value, key) => {
    var updatedUserDetails = { ...userDetails };
    updatedUserDetails[key] = value;
    if (key === "role") {
      updatedUserDetails["roleCode"] = value === "admin" ? "1" : "2";
    }

   
    setUserDetails(updatedUserDetails);
  };
  // To close the popup container
  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data) => {
    if (popUp === "pageRights") {
      data = data.map((right) => right.name);
    } else if (popUp === "displayPhoto") {
      // const reader = new FileReader();
      // reader.onload = () => {
      //   data = reader.result;
      //   var updatedUserDetails = { ...userDetails };
      //   updatedUserDetails[popUp] = data;
      //   setUserDetails(updatedUserDetails);
      //   handlePopUpClose();
      //   return;
      // };

      if(typeof(data) === "object"){
        uploadProfileImage(data);
      }else if(typeof(data) === "string"){
        var updatedUserDetails = { ...userDetails };
        updatedUserDetails[popUp] = data;
        setUserDetails(updatedUserDetails);
        handlePopUpClose();
      }
      
    }

    // data = data.map((right)=> right._id)
    var updatedUserDetails = { ...userDetails };
    updatedUserDetails[popUp] = data;
    setUserDetails(updatedUserDetails);
    handlePopUpClose();
  };


  const uploadProfileImage = async (file) => {
    if (file) {
      const authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = { fieldName: "displayPhoto", thumbnailUrl: file };
      let response = await UserServices.uploadUserDocuments(payload, authToken);
      if (response.success) {
        let updatedUserDetails = produce(userDetails, (draft) => {
            draft['displayPhoto'] = response.data;
        });
        setUserDetails(updatedUserDetails);
      } else {
      }
    }
  };

  return (
    <>
      <ProfileView
        userDetails={userDetails}
        handleChange={handleChange}
        handleSave={handleSave}
        popUp={popUp}
        popUpPosition={popUpPosition}
        isPopUpVisible={isPopUpVisible}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        allFolders={allFolders}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.user.userDetails,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserDetails: UserActions.updateUserDetails,
      resetUsersReducer: UserActions.resetUsersReducer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
