import React, { useState, useEffect } from "react";
import PopUpContainer from "@components/PopUpContainer";
import { decryptText } from "@library/enc-dec";

import * as FolderServices from "@services/Folders";
import * as LeadsServices from "@services/Leads";
import moment from "moment";
import Swal from "sweetalert2";

const LeadWaitingPopUp = (props) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [isOnReleaseMove, setIsOnReleaseMove] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    getAllFolders();
  }, []);

  const getAllFolders = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      pageNo: "0",
      limit: "10000",
      isSorted: true,
      sortBy: "title",
      searchText: "",
      visibility: "active",
    };
    const response = await FolderServices.getAllFolders(payload, authToken);
    var allFolders = response.data;
    allFolders = allFolders
      .map((folder) => (folder.pageType === "leads" ? folder : null))
      .filter(Boolean);
    setFolders(allFolders);
  };

  const handleDate = (event) => {
    setDate(event.target.value);
  };
  const handleTime = (event) => {
    setTime(event.target.value);
  };
  const handleCheckbox = (event) => {
    setIsOnReleaseMove(!isOnReleaseMove);
  };
  const handleFolderSelect = (event) => {
    setSelectedFolder(event.target.value);
  };

  const handleSave = async () => {
    if (isOnReleaseMove && !selectedFolder) {
      Swal.fire({
        icon: "error",
        title: "Please select a folder",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    let authToken = decryptText(localStorage.getItem("aEmediat"));

    const leadList = props.leadIds.map((id) => {
      return {
        leadId: id,
        status: "waitingTime",
        readableStatus: "Waiting Time",
        waitingTime: formatDateTime(date, time),
        onReleaseFolder: !isOnReleaseMove
          ? props.currentFolderName
          : selectedFolder,
      };
    });
    const payload = {
      leadList,
    };

    const response = await LeadsServices.moveToWaiting(payload, authToken);
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      props.handleSave();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  function formatDateTime(date, time) {
    const dateTimeString = date + " " + time;
    const momentDateTime = moment(dateTimeString);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedString = momentDateTime.tz(timeZone).toISOString();
    return formattedString;
  }

  return (
    <PopUpContainer
      title={"Add waiting time"}
      isSearchEnabled={false}
      style={{ width: "387px", height: "246px" }}
      isButtonsEnabled={true}
      handleCancel={props.handleCancel}
      handleSave={handleSave}
    >
      <div className="d-flex flex-column gap-2 w-100">
        <div className="d-flex gap-2">
          <input
            type="date"
            className="date-input w-100"
            value={date}
            onChange={handleDate}
          />
          <input
            type="time"
            className="time-input w-100"
            value={time}
            onChange={handleTime}
          />
        </div>
        <div className="hr mt-2 mb-2"></div>
        <div>
          <input
            type="checkbox"
            checked={isOnReleaseMove}
            onChange={handleCheckbox}
          />
          On release, move to
        </div>
        <div>
          <select
            value={selectedFolder}
            onChange={handleFolderSelect}
            className="w-100 select-new-drop-icon"
          >
            <option value="" default>
              Select Folder
            </option>
            {folders.map((folder) => {
              return <option value={folder.title}>{folder.title}</option>;
            })}
          </select>
        </div>
      </div>
    </PopUpContainer>
  );
};

export default LeadWaitingPopUp;
