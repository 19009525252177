import './style.css';
import ButtonBars from '@components/ButtonBars';
import PopUp from '@components/PopUp';

import BlueDropDownIcon from '@assets/common/blueDropDown.png';
// import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';
import FolderIcon from '@assets/userDashboard/folder.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@assets/common/downArrowAccordion.png';
import UploadImgPlaceHolder from '@assets/common/uploadImgPlaceHolder.png';

import BasicTableGrid from '@components/BasicTableGrid';
import TextAndLinks from '@components/TextAndLinks';
import SelectionPopUp from '@components/SelectionPopUp';
import User from '@components/User';
function RequestDetailsView(props) {

	return (
		<>
			<div className='userPageMainContainer queriesDetailsView bg-white'>
               <div style={{borderBottom: '1px solid #ddd'}}>
					<h4 className='mb-3 topHeading mt-2'>Request Details</h4>
					<div style={{ maxWidth: '1000px' }}
						className='d-flex align-items-center w-100 justify-content-between'>
						<ButtonBars
						buttons={[
						{
							text: props.requestData.status==='pending'? 'Select status':props.requestData.status,
							iconEnd: props.requestData.status==='pending'?BlueDropDownIcon:'',
							disabled: props.disableBulkActionBtn,
							onClick: (event) => {
								props.handlePopUpOpen(event, "requestStatus");
							},
						}
						]}
					/>

					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={(event) => props.handlePopUpOpen(event, "setting")}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button>
						<button onClick={props.moveToArchive}>
							<img src={FolderIcon}></img>
							<br></br>
							Archive
						</button>
					</div>
					</div>
			   </div>
			   <div className='row flex-grow-1 overflow-scroll'>
					<div className='col-7'>
						<div className='userProfileContentMainContainer'>
						<Accordion defaultExpanded style={{border: 'none', boxShadow:'none'}}>
							<AccordionSummary  expandIcon={<img src = {ExpandMoreIcon} className='accIcon'/>} 
											style={{flexDirection:'row-reverse', gap: '20px', alignItems:'center'}}>
								<b>Basic Information</b> 
							</AccordionSummary>
							<AccordionDetails>
								{/* START : Personal Section */}
								<div className='formMainContainer'>
									<div className='formRow'>
										<p className='col-5 col-sm-4 col-md-3 col-lg-2 inputLable'>Name</p>
										<input className='col-7 col-sm-8 col-md-9 col-lg-10 input'
											type='text' name='name' value={props.requestData?.agentDetails?.fullName}
											onChange={()=>{}}
											placeholder='Name'
										/>
									</div>

									<div className='formRow'>
										<p className='col-5 col-sm-4 col-md-3 col-lg-2 inputLable'>User name</p>
										<input
											className='col-7 col-sm-8 col-md-9 col-lg-10 input'
											type='text'
											name='username'
											value={props.requestData?.agentDetails?.username}
											onChange={()=>{}}
											placeholder='User name'
										/>
									</div>
									<div className='formRow'>
										<p className='col-5 col-sm-4 col-md-3 col-lg-2 inputLable d-flex'>
											Password
										</p>
										<div className='col-7 col-sm-8 col-md-9 col-lg-10 border border-top-0 border-bottom-0 border-right-0 d-flex px-2 gap-2 align-items-center'>
										<input className='input flex-grow-1 border-0'
											type='text'
											name='password'
											value={props.requestData?.agentDetails?.password}
											onChange={() => {}}
											placeholder='Password'
										/>
										</div>

									</div>
									{/* END : Email */}

								</div>
								{/* END : Personal Section */}
							</AccordionDetails>
						</Accordion>

						<Accordion defaultExpanded style={{border: 'none', boxShadow:'none'}}>
							<AccordionSummary  expandIcon={<img src = {ExpandMoreIcon} className='accIcon'/>} 
											style={{flexDirection:'row-reverse', gap: '20px', alignItems:'center'}}>
								<b>Formal Request / Proposal</b> 
							</AccordionSummary>
							<AccordionDetails>
							{props.requestData?.description}
							</AccordionDetails>
						</Accordion>
                        </div>
					</div>

					<div className='col-3' >
						<div className='w-100 pt-4'  style={{position: 'sticky', top:'0'}}>
						<div className='userProfileImgMainContainer mt-4' id='profileImage_uploadBtn'
								// onClick={(e) => props.handleFileUpload('imageAttachInput', 'displayPhoto')}
								>
							
							<User 
							   imageText={props.requestData?.agentDetails?.fullName} 
							   image={props.requestData?.agentDetails?.displayPhoto} 
							   size={'180px'}
							   fontSize={'40px'}/>

						</div>
						
						
						</div>
					</div>

				</div>
				<Accordion defaultExpanded style={{border: 'none', boxShadow:'none'}}>
					<AccordionSummary  expandIcon={<img src = {ExpandMoreIcon} className='accIcon'/>} 
									style={{flexDirection:'row-reverse', gap: '20px', alignItems:'center'}}>
						<b>User(s) affected</b> 
					</AccordionSummary>
					<AccordionDetails>
					<div className='d-flex' style={{minHeight:'500px'}}>
					<BasicTableGrid 
						gridId='RequestsUsersDetails'
						data={props.deferredRequestDetails} // Data to be displayed
						fixedCols={2} // How many columns are fixed and not scrolling
						initialSize={[306,288,267,261]}
						initialSorting={[{"id": "Acc Created","desc": true}]}
						handleSelect={() => {}}
						length={20}
						onSortChange={() =>{}}
						columnsOptionPopUpRef={null}
						isCheckBoxVisible={false}
						columns={[
							{header: 'Name',
							cell: (data) => {
							const info = data.row.original;
							return (<div className="d-flex gap-2 w-100 align-items-center">
										{true ? 
										<div className="thumbnailContainer" 
										style={{
										backgroundImage:`url(${info?.displayPhoto})`, 
										height:'30px', 
										width: '30px',
										borderRadius:'15px',
										border: '0px solid black'
										}}>
											</div>
										: null}
										<TextAndLinks
											title= {info?.fullName ? info?.fullName : info?.username}
											subTitle= {'Options'}
											images = {[EditPencilIcon]}
											onClick={(event, index)=>{props.handleRedirectToProfilePage(event, info._id, 'UserProfile')}}
											/>	
										</div>);												
									}
						},
						{header: 'Course(s)', cell : (data)=>{return data.row.original?.purchasedCourses?.map(course=>course?.mnemonic).join(", ")}},
						{header: 'Qualification', cell : (data)=>{
							const info = data.row.original
							return info.isUnderGraduate? info.underGraduateDegree : info.qualification}},
						
						{header: 'Courses affected', 
						cell : (data)=>{
							const info = data.row.original
							return info?.requestType === "freeCourse" ?
									info?.freeAfttectedCourses?.map(course=>course?.mnemonic).join(", ") :
								 info?.requestType === "addUserRule" ?
									info?.addRuleAffectedCourses?.map(course=>course?.mnemonic).join(", ")
							     : info?.affectedCourses?.map(course=>course?.mnemonic).join(", ")
						}},	
					]}
						/>
					</div>		
					</AccordionDetails>
				</Accordion>
            
				{props.popUp === "requestStatus"  && <PopUp visible={props.isPopUpVisible}
						top={props.popUpPosition.top}
						left={props.popUpPosition.left}
						handleOutSideClick={props.handlePopUpClose}
						>
						<SelectionPopUp 
						data = {[{_id : 'Approve', name: 'Approve'},
						      {_id : 'Reject', name: 'Reject'}
						]}
						optionKey= {"name"}
						title={""}
						searchPlaceholder={""}
						isSearchEnabled={false}
						height= {"auto"}
						width= {"auto"}
						selectedItems={[]}
						dataKey={"_id"}
						selectedItemsKey={"_id"}
						selectionType={"single"}
						handleCancel ={props.handlePopUpClose}
						handleSave={props.handlePopUpSave}/>
					</PopUp>}
			</div>
		</>
	);
}

export default RequestDetailsView;
