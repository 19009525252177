
import {colors} from '@assets/colors'
import './styles.css'
function DistributionBar(props){

    return <div className='w-100 d-flex distBar flex-column'>
            <div className="w-100 d-flex rounded overflow-hidden">
                {props.data?.map((obj, index)=>
                <div  style={{flex: obj?.percentage/100, height:'6px', backgroundColor: colors[index]}}></div>)}
                
            </div>
            <div className="hide flex-column gap-1 ">
                    {props.data?.map((obj, index)=>
                    <div className='d-flex flex-row justify-content-center gap-1 alig-items-center'>
                        <div className='circle' style={{backgroundColor: colors[index]}}></div>
                        <div>{obj.name} </div>
                        <div className="ms-auto">{Math.trunc(obj.percentage)}% </div> 
                        </div>)}
            </div>
        </div>
}

export default DistributionBar