import SidebarNavigation from "@components/SidebarNavigation";
import { useEffect, useState, useRef } from "react";
import { defer, useLocation } from "react-router-dom";

import * as CertificatesServices from "@services/Certificates";
import * as FolderServices from "@services/Folders";

import { decryptText } from "@library/enc-dec";
import CertificatesView from "./view";
import XLSX from "sheetjs-style";
import moment from "moment";
import { checkLoginAndRole } from "@helpers/Login/";
import Swal from "sweetalert2";
import {
  getEventElementPosition,
  toggleFullscreen,
  convertToISOFormat,
} from "@helpers/common.js";

import { produce } from "immer";
import * as CategoryServices from "@services/Category";
import * as CoursesServices from "@services/Course";

var listOfCheckedRows = [];
var pageData = {};

function Certificates() {
  const fullscreenElementRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnsOptionPopUpRef.current &&
        !columnsOptionPopUpRef.current.contains(event.target)
      ) {
        columnsOptionPopUpRef.current.style.display = "none";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [deferredData, setDeferredData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isSorted, setIsSorted] = useState(false);
  const [sortBy, setSortBy] = useState("updatedAt");
  const [folders, setFolders] = useState([]);
  const [activeTab, setActiveTab] = useState({
    _id: "ready", // Provide a unique ID for the new folder
    title: "Ready",
    isActive: true,
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  var page = Number(queryParams.get("page"));
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});

  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);

  const [selectedTab, setSelectedTab] = useState("ready");
  const [listOfCheckedCertificates, setListOfCheckedCertificates] = useState(
    []
  );
  let authToken = decryptText(localStorage.getItem("aEmediat"));
  const [allCertificates, setAllCertificates] = useState([]);
  const [undoCertificates, setUndoCertificates] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [filterCourses, setFilterCourses] = useState([]);
  const [filterCategories, setFilterCategories] = useState([]);

  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    examStatus: null,
    courseIds: [],
    categories: [],
    yearTag: "",
    monthTag: "",
    mainTag: "",
    tagStatus: "",
    subTag: "",
    manualTag: "",
    isAllActiveCourseChecked: "false",
  });

  useEffect(() => {
    checkLoginAndRole("certificates");
    getAllFolders();
  }, []);

  useEffect(() => {
    if(limit>0)
    {
      getCertificates();
    }
  }, [activeTab, limit, sortBy, searchText, filters, page]);

  const getCertificates = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: page,
        limit: limit,
        isSorted: isSorted,
        sortBy: sortBy,
        searchText: searchText? searchText : "",
        // folderType:
        //   activeTab._id === "ready" ||
        //   activeTab._id == "dispatch" ||
        //   activeTab._id == "delivered"
        //     ? "All"
        //     : activeTab.title,
        certificateStatus:
          activeTab._id === "ready" ||
          activeTab._id == "dispatch" ||
          activeTab._id == "delivered"
            ? activeTab._id
            : "archive",

        startDate: convertToISOFormat(filters.startDate),
        endDate: convertToISOFormat(filters.endDate),
        examStatus: filters.examStatus,
        courseIds: filters.courseIds,
        categories: filters.categories,
        yearTag: filters.yearTag,
        monthTag: filters.monthTag,
        mainTag: filters.mainTag,
        tagStatus: filters.tagStatus,
        subTag: filters.subTag,
        userManualTags: filters.manualTag ? [filters.manualTag] : [],
      };

      var deferredResponse = defer({
        res: CertificatesServices.getAllCertificates(payload, authToken)
        .then(preprocessData).catch(error=>{
          console.log("Error in fetching users", error)
          return null
        })
      });
      setDeferredData(deferredResponse);
      deferredResponse.data.res
        .then((response) => {
          pageData = response;
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } catch (err) {
      console.log("Error coming while fetching all certificates", err);
    }
  };

  const preprocessData = (response) => {
		if(!response.status===200)
			{ return null}
    response.data = response.data.map((info) => {
       if(typeof info.tags === 'object')
       {
        info.tags.month= info.tags.month==='yes'? moment(info.createdAt).format('MMM'): 'no';
        info.tags.year= info.tags.year==='yes'? moment(info.createdAt).format('yyyy'): 'no';
        info.tags.status= info.tags.status==='yes'? info.courses[0].courseStatus: 'no';
       }
      return info
    })		
		return response
	}

  const getAllFolders = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      pageNo: "0",
      limit: "10000",
      isSorted: true,
      sortBy: "title",
      searchText: "",
      visibility: "active",
    };
    const response = await FolderServices.getAllFolders(payload, authToken);
    var allFolders = response.data;
    allFolders = allFolders
      .map((folder) => (folder.pageType === "certificate" ? folder : null))
      .filter(Boolean);
    var defaultFolders = [
      {
        _id: "ready",
        title: "Ready",
        isActive: true,
        titleTag1: { text: 0, bgColor: "#ff0000", fontColor: "white" },
      },
      {
        _id: "dispatch",
        title: "For Dispatch",
        isActive: true,
        titleTag1: { text: 0, bgColor: "#97B2DC", fontColor: "white" },
      },
      {
        _id: "delivered",
        title: "Delivered",
        isActive: true,
        titleTag1: { text: 0, bgColor: "#97B2DC", fontColor: "white" },
      },
      {
        _id: "archive",
        title: "Archive",
        isActive: true,
        titleTag1: { text: 0, bgColor: "#97B2DC", fontColor: "white" },
      },
    ];

    allFolders = [...defaultFolders, ...allFolders];

    const foldersCount = await getCertificateFoldersCount();

    foldersCount.forEach((folderCount) => {
      allFolders = allFolders.map((folder) => {
        if (
          folder._id === folderCount.folderName ||
          folder.title === folderCount.folderName
        ) {
          if (!folder.titleTag1) {
            folder.titleTag1 = {
              text: "",
              bgColor: "#B6B6B6",
              fontColor: "white",
            };
          }
          folder.titleTag1.text = folderCount.count;
        }

        return folder;
      });
    });

    setFolders(allFolders);
  };
  const getCertificateFoldersCount = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const response = await CertificatesServices.getCertificateFoldersCount(
      authToken
    );
    if (response.success) {
      return response.data;
    }
    return null;
  };

  const handleSearch = (data) => {
    let updatedFilters = produce(filters, (draft) => {
      draft.startDate = data.startDate;
      draft.endDate = data.endDate;
      draft.courseIds = filterCourses;
      draft.categories = filterCategories;
      draft.yearTag = data.yearTag;
      draft.monthTag = data.monthTag;
      draft.mainTag = data.mainTag;
      draft.tagStatus = data.tagStatus;
      draft.subTag = data.subTag;
    });
    page = 0;
    setSearchText(data.text);
    setFilters(updatedFilters);
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedRows = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };
  // To sort
  const handleSort = (data) => {
    const mapping = { "Date Added": "createdAt", "Date Modified": "updatedAt" };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    setSortBy(id);
    setIsSorted(data[0]?.desc ? data[0].desc : false);
  };
  const handleColumnBtnClick = (event) => {
    handlePopUpClose();
    columnsOptionPopUpRef.current.style.display = "block";
    var position = getEventElementPosition(event);
    columnsOptionPopUpRef.current.style.top = position.top + "px";
    columnsOptionPopUpRef.current.style.left = position.left - 160 + "px";
    event.stopPropagation();
  };
  const handleTabSelect = (eventKey) => {
    setSelectedTab(eventKey);
  };
  const handleExcelExport = (event) => {
    handlePopUpClose();
    let excelExportDataForCertificateList = [];
    let excelExportDataForAddressList = [];

    if (listOfCheckedRows.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "No certificate is selected",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    let userIds=[]
    // Data preparation
    listOfCheckedRows.forEach((certificate) => {
      let addressRow = {
        "id": certificate.userId,
        "Name as per marksheet": certificate.fullName,
        "Qualification Category":
           certificate?.profession? certificate?.profession : '-',
        Qualification: certificate.isUnderGraduate
          ? certificate.underGraduateDegree
          : certificate.qualification,
        "Flat name, Building name, Colony name, Area Name, Landmark":
          certificate.flatHouseNumber +
          ", " +
          certificate.buildingName +
          ", " +
          certificate.colonyName +
          ", " +
          certificate.areaName +
          ", " +
          certificate.landmark,
        City: certificate.city,
        PINCODE: certificate.pincode,
        State: certificate.state,
        Country: certificate.country,
        "Contact Number":
          certificate.countryCode + " " + certificate.contactNumber,
        "WhatsApp Number":
          certificate.whatsAppCountryCode + " " + certificate.whatsAppNumber,
        "Course name of exam passed": certificate.clearedExam,
        "Exam passing date": moment(certificate.passingDate).format(
          "Do MMMM, YYYY"
        ),
      };
      var activeCourses = (certificate.courses || []).filter(
        (course) => course.courseStatus === "active"
      );
      var activeCourseCount = activeCourses.length;
      var completedActiveCourseCount = certificate.courses?.map(
        (course) => course.courseStatus === "completed"
      ).length;

      let certificateRow= {
        "Name as per marksheet": certificate.fullName,
        "Qualification Category":
          certificate.profession ? certificate.profession : 'Not found',
        Qualification: certificate.underGraduateDegree? certificate.underGraduateDegree : certificate.qualification,
        "Course name of exam passed": certificate.clearedExam,
        "Course mnemonic of exam passed": certificate.clearedExam,
        "Exam passing date": moment(certificate.passingDate).format(
          "Do MMMM, YYYY"
        ),
        "Exam passing time": moment(certificate.passingDate).format("h:mm A"),
        "Contact Number":
          certificate.countryCode + " " + certificate.contactNumber,
        "WhatsApp Number":
          certificate.whatsAppCountryCode + " " + certificate.whatsAppNumber,
        "Completed x out of active courses": completedActiveCourseCount,
        "Active courses": activeCourseCount,
        "All Active Courses Completed?":
          activeCourseCount === completedActiveCourseCount ? "Yes" : "No",
      };

      excelExportDataForCertificateList.push(certificateRow);

      if(certificate.clearedExam)
      {
      if(userIds.includes(certificate.userId)){
        excelExportDataForAddressList = excelExportDataForAddressList.map((data,index)=>{
          if(data.id === certificate.userId){
            data['Course name of exam passed'] = data['Course name of exam passed']+ ", " + certificate.clearedExam
          }
          return data
        })
      }else{
        userIds.push(certificate.userId)
        excelExportDataForAddressList.push(addressRow);
      }
    }
      
    });

    //Exporting to excel
    const worksheet = XLSX.utils.json_to_sheet(
      excelExportDataForCertificateList
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "CertificateList.xlsx");

    const worksheet1 = XLSX.utils.json_to_sheet(excelExportDataForAddressList);
    const workbook1 = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook1, worksheet1, "Sheet1");
    XLSX.writeFile(workbook1, "AddressList.xlsx");

    // changeStatusOfCertificated(exportCertificates, 'delivered');
  };
  // To close the popup container
  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    columnsOptionPopUpRef.current.style.display = "none";
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data) => {
    if (popUp === "confirmDelivery") {
      handleExcelExport();
      updateCertificateStatus("delivered");
    } else if (popUp === "filterCategory") {
      data = data.map((obj) => obj._id);
      setFilterCategories(data);
    } else if (popUp === "filterCourse") {
      data = data.map((obj) => obj._id);
      setFilterCourses(data);
    }
    handlePopUpClose();
    // getAllBssForms();
    // setDisableBulkActionBtn(true);
  };

  const moveToFolder = async (folderId) => {
    handlePopUpClose();

    // if (folderId === "ready" || folderId === "dispatch") {
      updateCertificateStatus(folderId);
    //   return;
    // }

    // try {
    //   let authToken = decryptText(localStorage.getItem("aEmediat"));
    //   var payload = {
    //     foldersIdList: folderId,
    //     usersList: listOfCheckedRows.map((row) => ({
    //       userId: row.userId,
    //       purchaseId: row.purchaseId,
    //       type: row.purchaseId ? "purchases" : "unpurchases",
    //     })),
    //     currentFolderId:
    //       activeTab._id === "ready" ||
    //       activeTab._id == "dispatch" ||
    //       activeTab._id == "delivered"
    //         ? null
    //         : activeTab._id,
    //     pageType: "certificate",
    //   };
    //   const response = await FolderServices.moveToFolders(payload, authToken);
    //   if (response.success) {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Users Moved successfully",
    //       showConfirmButton: false,
    //       timer: 2500,
    //     });
    //     getAllFolders();
    //     getCertificates();
    //   } else {
    //     Swal.fire({
    //       icon: "error",
    //       title: response.message,
    //       showConfirmButton: false,
    //       timer: 2500,
    //     });
    //   }
    // } catch (err) {
    //   console.log("Error coming while moving to folders", err);
    // }
  };

  const updateCertificateStatus = async (status) => {
    try {
      let response = await CertificatesServices.updateCertificatesStatus(
        {
          certificateIds: listOfCheckedRows.map((row) => row._id),
          status: status,
        },
        authToken
      );
      if (response.success) {
        setListOfCheckedCertificates([]);
        var tab = folders[0];
        if (status == "dispatch") {
          tab = folders[1];
        } else if (status === "delivered") {
          tab = folders[2];
        }else if (status === "archive") {
          tab = folders[3];
        }
        setActiveTab(tab);
        getAllFolders();
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating the certificates", err);
    }
  };

  const handleChange = (field, value, userId) => {
    var updatedPageData = pageData;
    // Update the appropriate fields based on the field parameter
    updatedPageData.data = updatedPageData.data.map((row) => {
      if (row.userId === userId) {
        if (field === "phoneNumber") {
          row.countryCode = value.countryCode;
          row.contactNumber = value.number;
        } else if (field === "whatsAppNumber") {
          row.whatsAppCountryCode = value.countryCode;
          row.whatsAppNumber = value.number;
        } else if (field === "manualTags") {
          row.userManualTags = value;
        } else if (field === "notes") {
          row.notes = value;
        }
      }
      return row; // Return the updated or unchanged user information
    });
    var deferredResponse = defer({ res: updatedPageData });
    pageData = updatedPageData;
    setDeferredData(deferredResponse);
  };

  const handleFilterPopUp = async (event, index) => {
    if (index === 0) {
      getAllCategories();
      handlePopUpOpen(event, "filterCategory", null);
    } else if (index === 1) {
      await getAllCourses();
      handlePopUpOpen(event, "filterCourse", null);
    }
  };

  const getAllCourses = async () => {
    try {
      if (allCourses.length === 0) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        const response = await CoursesServices.getAllCourses(
          { pageNo: 0, limit: 10000 },
          authToken
        );
        if (response.success) {
          setAllCourses(response.data);
        }
      }
    } catch (err) {
      console.log("Error coming while fetching all course", err);
    }
  };
  const getAllCategories = async () => {
    try {
      if (allCategories.length === 0) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        const response = await CategoryServices.getAllCategories(
          { pageNo: 0, limit: 99999 },
          authToken
        );
        if (response.success) {
          setAllCategories(response.data);
        }
      }
    } catch (err) {
      console.log("Error coming while fetching categories in categories", err);
    }
  };

  const handleClearFilter = (index) => {
    let updatedFilters = produce(filters, (draft) => {
      if (index === 0) {
        draft.searchText = "";
        setSearchText("")
      } else if (index === 1) {
        draft.startDate = "";
        draft.endDate = "";
      } else if (index === 2) {
        draft.categories = [];
      } else if (index === 3) {
        draft.courseIds = [];
      } else if (index === 4) {
        draft.examStatus = null;
      } else if (index === 5) {
        draft.yearTag = "";
        draft.monthTag = "";
        draft.mainTag = "";
        draft.tagStatus = "";
        draft.subTag = "";
        draft.manualTag = "";
      }
    });
    setFilters(updatedFilters);
  };

  return (
    <>
      <SidebarNavigation />
      <CertificatesView
        deferredData={deferredData}
        disableBulkActionBtn={disableBulkActionBtn}
        handleSearch={handleSearch}
        searchText={searchText}
        filters={filters}
        handleFilterPopUp={handleFilterPopUp}
        folders={folders}
        activeTab={activeTab}
        handleTabClick={setActiveTab}
        handleCheckBoxes={handleCheckBoxes}
        handleSort={handleSort}
        selectedTab={selectedTab}
        listOfCheckedCertificates={listOfCheckedCertificates}
        handleTabSelect={handleTabSelect}
        // handleCheckBoxes={handleCheckBoxes}

        handleExcelExport={handleExcelExport}
        undoCertificates={undoCertificates}
        // Start: Control Pop Up
        popUp={popUp}
        popUpPosition={popUpPosition}
        isPopUpVisible={isPopUpVisible}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        //ColumnButton and popup
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        handleColumnBtnClick={handleColumnBtnClick}
        updateCertificateStatus={updateCertificateStatus}
        moveToFolder={moveToFolder}
        handleChange={handleChange}
        allCategories={allCategories}
        allCourses={allCourses}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
}

export default Certificates;
