import React, { useState, useEffect } from "react";
import DetailsView from "./view";
import { getEventElementPosition } from "@helpers/common.js";
function Details(props) {
  const [courseDetails, setCourseDetails] = useState(props.courseDetails);

  const [popUpPosition, setPopUpPosition] = useState({});
  const [isSelectExamPopUpVisible, setIsSelectExamPopUpVisible] =
    useState(false);
  const [isSelectGatewayPopUpVisible, setIsSelectGatewayPopUpVisible] =
    useState(false);
  const [
    isSelectExamQualificationPopUpVisible,
    setIsSelectExamQualificationPopUpVisible,
  ] = useState(false);
  const [
    isSelectCertificateQualificationPopUpVisible,
    setIsSelectCertificateQualificationPopUpVisible,
  ] = useState(false);
  const [isCarouselUploadPopUpVisible, setIsCarouselUploadPopUpVisible] =
    useState(false);
  useEffect(() => {
    setCourseDetails(props.courseDetails);
  }, [props.courseDetails]);

  useEffect(() => {
    props.handleFormDataChange(courseDetails);
  }, [courseDetails]);

  const handleChange = (event, field) => {
    var updatedCourseDetails = { ...courseDetails };
    if (field == "title") {
      updatedCourseDetails.title = event.target.value;
    } else if (field == "internalTitle") {
      updatedCourseDetails.internalTitle = event.target.value;
    } else if (field == "mnemonic") {
      updatedCourseDetails.mnemonic = event.target.value;
    } else if (field == "visibility") {
      updatedCourseDetails.visibility = event.target.value;
    } else if (field === "automaticTagsCheck") {
      if (event.target.checked) {
        updatedCourseDetails.tags = {
          year: "yes",
          main: updatedCourseDetails.tags.main,
          secondary: "",
          month: "yes",
          status: "yes",
        };
      } else {
        updatedCourseDetails.tags = {
          year: null,
          main: updatedCourseDetails.tags.main,
          secondary: null,
          month: null,
          status: null,
        };
      }
    } else if (field === "yearTag") {
      if (event.target.checked) {
        updatedCourseDetails.tags.year = "yes";
      } else {
        updatedCourseDetails.tags.year = null;
      }
    } else if (field === "monthTag") {
      if (event.target.checked) {
        updatedCourseDetails.tags.month = "yes";
      } else {
        updatedCourseDetails.tags.month = null;
      }
    } else if (field === "statusTag") {
      if (event.target.checked) {
        updatedCourseDetails.tags.status = "yes";
      } else {
        updatedCourseDetails.tags.status = null;
      }
    } else if (field === "mainTag") {
      updatedCourseDetails.tags.main = event.target.value;
    } else if (field === "secondaryTag") {
      updatedCourseDetails.tags.secondary = event.target.value;
    } else if (field === "secondaryCheck") {
      if (event.target.checked) {
        updatedCourseDetails.tags.secondary = "";
      } else {
        updatedCourseDetails.tags.secondary = null;
      }
    } else if (field === "examId") {
      updatedCourseDetails.examId = event.target.value;
      setIsSelectExamPopUpVisible(false);
    } else if (field === "paymentGateways") {
      updatedCourseDetails.paymentGateways = event;
      setIsSelectGatewayPopUpVisible(false);
    } else if (field === "eligibleExams") {
      updatedCourseDetails.eligibleExams = event;
      setIsSelectExamQualificationPopUpVisible(false);
    } else if (field === "eligibleCertificates") {
      updatedCourseDetails.eligibleCertificates = event;
      setIsSelectCertificateQualificationPopUpVisible(false);
    } else if (field === "imageCarousel") {
      if (typeof event[0] === "object") {
        if (!updatedCourseDetails.imageCarouselFiles) {
          updatedCourseDetails.imageCarouselFiles = [];
        }
        updatedCourseDetails.imageCarouselFiles.push(...event);
        setIsCarouselUploadPopUpVisible(false);
      } else {
        updatedCourseDetails.imageCarousel.push(...event);
        setIsCarouselUploadPopUpVisible(false);
      }
    } else if (field === "imageCarouselCheck") {
      if (event === null) {
        updatedCourseDetails.imageCarousel = [];
      } else {
        updatedCourseDetails.imageCarousel = null;
      }
    } else if (field === "carouselImageDelete") {
      updatedCourseDetails.imageCarousel.splice(event, 1);
    } else if (field === "carouselImageFilesDelete") {
      updatedCourseDetails.imageCarouselFiles.splice(event, 1);
    } else if (field === "allowToCart") {
      updatedCourseDetails.allowToCart = !event.target.checked;
    } else if (field === "isLegalInfoRequired") {
      updatedCourseDetails.isLegalInfoRequired = event.target.checked;
    } else if (field === "isIdVerificationRequired") {
      updatedCourseDetails.isIdVerificationRequired = event.target.checked;
    } else if (field === "isAddressProofRequired") {
      updatedCourseDetails.isAddressProofRequired = event.target.checked;
    } else if (field === "exemptedDays") {
      updatedCourseDetails.exemptedDays = event.target.value;
    } else if (field === "seriesWatchedPercentage") {
      if (event.target.value <= 100 && event.target.value >= 0) {
        updatedCourseDetails.seriesWatchedPercentage = event.target.value;
      }
    } else if (field === "exemptedDaysCheck") {
      updatedCourseDetails.exemptedDays = 0;
      if (event.target.checked) {
        updatedCourseDetails.exemptedDays = 1;
      }
    } else if (field === "seriesWatchedPercentageCheck") {
      updatedCourseDetails.seriesWatchedPercentage = 0;
      if (event.target.checked) {
        updatedCourseDetails.seriesWatchedPercentage = 1;
      }
    } else if (field === "showImageCarousel") {
      updatedCourseDetails.showImageCarousel = event;
    }
    setCourseDetails(updatedCourseDetails);
  };
  // All Pop Up related functions
  // To close the popup container
  const handlePopUpClose = () => {
    setIsSelectExamPopUpVisible(false);
    setIsSelectGatewayPopUpVisible(false);
    setIsSelectExamQualificationPopUpVisible(false);
    setIsSelectCertificateQualificationPopUpVisible(false);
    setIsCarouselUploadPopUpVisible(false);
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async () => {
    handlePopUpClose();
  };
  // Open popUps
  const handleOpenPopUp = (event, popUp) => {
    handlePopUpClose();
    switch (popUp) {
      case "examSelect":
        setIsSelectExamPopUpVisible(true);
        break;
      case "paymentSelect":
        setIsSelectGatewayPopUpVisible(true);
        break;
      case "examEligibleForSelect":
        setIsSelectExamQualificationPopUpVisible(true);
        break;
      case "certEligibleForSelect":
        setIsSelectCertificateQualificationPopUpVisible(true);
        break;
      case "carouselSelect":
        if (courseDetails.imageCarousel != null) {
          setIsCarouselUploadPopUpVisible(true);
        }
        break;
      default:
        break;
    }

    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };

  return (
    <DetailsView
      courseDetails={courseDetails}
      handleChange={handleChange}
      //PopUp
      handleOpenPopUp={handleOpenPopUp}
      handlePopUpClose={handlePopUpClose}
      handlePopUpSave={handlePopUpSave}
      popUpPosition={popUpPosition}
      isSelectExamPopUpVisible={isSelectExamPopUpVisible}
      allExams={props.allExams}
      isSelectGatewayPopUpVisible={isSelectGatewayPopUpVisible}
      paymentGateways={props.paymentGateways}
      isSelectExamQualificationPopUpVisible={
        isSelectExamQualificationPopUpVisible
      }
      isSelectCertificateQualificationPopUpVisible={
        isSelectCertificateQualificationPopUpVisible
      }
      allQualifications={props.allQualifications}
      isCarouselUploadPopUpVisible={isCarouselUploadPopUpVisible}
    />
  );
}

export default Details;
