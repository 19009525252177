import { useEffect } from 'react';
import SidebarNavigation from '@components/SidebarNavigation';
import DynamicView from './view';

import { useNavigate } from 'react-router-dom';
import { checkLoginAndRole } from '@helpers/Login/';
import { useDispatch, useSelector } from 'react-redux';
function Dynamic() {
	const dispatch = useDispatch();

	const selectedTab = useSelector((state) => state.tabs.DynamiPagTab)
	const navigate = useNavigate();

	useEffect(() => {
        checkLoginAndRole('dynamicPage')
    }, [])

	const handleTabSelect = (eventKey) => {
		dispatch({ type: 'UPDATE_DYNAMIC_TAB', payload: { tab: eventKey } });
		// removeQueryParameter();
	};

	const removeQueryParameter = () => {
		// Remove the 'tab' query parameter
		navigate('.', { replace: true });
	  };

	return (
		<>
			<SidebarNavigation />
			<DynamicView 
			      selectedTab={selectedTab} 
				  handleTabSelect={handleTabSelect} 
				  />
		</>
	);
}

export default Dynamic;
