import React from "react";

import "./accounts.css";
import moment from "moment";
import SortIcon from "@assets/common/sort.png";
import DeleteIcon from "@assets/common/deleteIcon.png";
import ExcelIcon from "@assets/common/excelIcon.png";
import ExpandIcon from "@assets/userDashboard/expand.png";
import SettingIcon from "@assets/userDashboard/setting.png";
import ColumnsIcon from "@assets/userDashboard/columns.png";
import FolderIcon from "@assets/userDashboard/folder.png";
import BlueDropDownIcon from "@assets/common/blueDropDown.png";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import BasicTableGrid from "@components/BasicTableGrid";
import DynamicTabs from "@components/DynamicTabs";
import PhoneNumber from "@components/PhoneNumber";

import PopUp from "@components/PopUp";
import EditNumberPopUp from "@components/EditNumberPopUp";
import SelectionPopUp from "@components/SelectionPopUp";

import Search from "@components/Search";
import Tags from "@components/Tags";
import ButtonBars from "@components/ButtonBars";
import DownIcon from "@assets/common/dropDown.png";
import ActiveSearchFilters from "@components/ActiveSearchFilters";

const expenseOptions = [
  { _id: "course", name: "Course" },
  { _id: "installment", name: "Installment" },
  { _id: "exam", name: "Exam" },
  { _id: "certificate", name: "Certificate" },
];

function AccountsView(props) {
  return (
    <>
      <div
        className="accountsPageMainContainer bg-white"
        ref={props.fullscreenElementRef}
      >
        <Search
          value={props.filters.searchText}
          startDate={props.filters.startDate}
          endDate={props.filters.endDate}
          yearTag={props.filters.yearTag}
          monthTag={props.filters.monthTag}
          mainTag={props.filters.mainTag}
          tagStatus={props.filters.tagStatus}
          subTag={props.filters.subTag}
          manualTag={props.filters.manualTag}
          placeholder={"Search users"}
          isFilterEnabled={true}
          filters={[
            {
              placeholder: "Category",
              value:
                props.filters.categories.length > 0
                  ? `(${props.filters.categories.length}) selected`
                  : "",
              icon: DownIcon,
            },
            {
              placeholder: "Select Course",
              value:
                props.filters.courseIds.length > 0
                  ? `(${props.filters.courseIds.length}) selected`
                  : "",
              icon: DownIcon,
            },
            {
              placeholder: "Expense Type",
              value: props.filters.expenseType,
              icon: DownIcon,
            },
          ]}
          handleFilter={props.handleFilterPopUp}
          isTagFilterEnabled={true}
          onSearch={(data) => props.handleSearch(data)}
        >
          <div>No Filters Added</div>
        </Search>

        <div className="marginLeftContainer d-flex align-items-center mb-2 gap-4">
          <h4 className="heading mb-0 ps-4">Accounts</h4>
          <ActiveSearchFilters
            filters={[
              { icon: "search", value: props.finalFilters.searchText },
              {
                icon: "calender",
                value:
                  props.finalFilters.startDate || props.finalFilters.endDate
                    ? `${props.finalFilters.startDate} - ${props.finalFilters.endDate}`
                    : "",
              },
              {
                icon: "",
                value: props.finalFilters.categories.length
                  ? `Category: (${props.finalFilters.categories.length})`
                  : "",
              },
              {
                icon: "",
                value: props.finalFilters.courseIds.length
                  ? `Course: (${props.finalFilters.courseIds.length})`
                  : "",
              },
              {
                icon: "",
                value: props.finalFilters.expenseType
                  ? `Expense Type: ${props.finalFilters.expenseType}`
                  : "",
              },
              {
                icon: "",
                value: props.tagCount ? `Tags: (${props.tagCount})` : "",
              },
            ]}
            handleClick={props.handleClearFilter}
          />
        </div>
        {/* <div
					className='filterMenu'
					style={{
						visibility: props.isMenuVisible ? 'visible' : 'hidden',
					}}
					onClick={(event) => {
						props.handleMenuVisibility(event, 'main');
					}}>
					<button
						onClick={(event) => {
							props.handleMenuVisibility(event, 'today');
						}}>
						Today
					</button>
					<button
						onClick={(event) => {
							props.handleMenuVisibility(event, 'month');
						}}>
						This Month
					</button>
					<button
						onClick={(event) => {
							props.handleMenuVisibility(event, 'week');
						}}>
						This Week
					</button>
					<button
						onClick={(event) => {
							props.handleMenuVisibility(event, 'year');
						}}>
						This Year
					</button>
					<button
						onClick={(event) => {
							props.handleMenuVisibility(event, 'all');
						}}>
						All Time
					</button>
				</div>
				<div className='allComments_FilterContainer'>
					<img
						onClick={(event) => {
							props.handleMenuVisibility(event);
						}}
						className='sortIcon'
						src={SortIcon}
						alt='SortIcon'
					/>
					<div className='inputDateContainer'>
						<span>From</span>
						<br />
						<input
							onChange={(event) => {
								props.handleFromInputDate(event.target.value, 'start');
							}}
							value={props.fromInputDate}
							type='date'
						/>
					</div>
					<div className='inputDateContainer'>
						<span>To</span>
						<br />
						<input
							onChange={(event) => {
								props.handleFromInputDate(event.target.value, 'end');
							}}
							value={props.toInputDate}
							type='date'
						/>
					</div>
				</div> */}

        <DynamicTabs
          allTabs={props.folders}
          activeTab={props.activeTab}
          onTabClick={props.handleTabClick}
        />
        <div className="d-flex align-items-center w-100 justify-content-between">
          <ButtonBars
            buttons={[
              {
                text: "Course status",
                iconEnd: BlueDropDownIcon,
                disabled: props.disableBulkActionBtn,
                onClick: (event) => {
                  props.handlePopUpOpen(event, "courseStatus");
                },
              },
              {
                text: "Exam status",
                iconEnd: BlueDropDownIcon,
                disabled: props.disableBulkActionBtn,
                onClick: (event) => {
                  props.handlePopUpOpen(event, "examStatus");
                },
              },
              {
                text: "Series status",
                iconEnd: BlueDropDownIcon,
                disabled: props.disableBulkActionBtn,
                onClick: (event) => {
                  props.handlePopUpOpen(event, "seriesStatus");
                },
              },
            ]}
          />

          <div className="d-flex gap-2 controlsRight me-3">
            <button onClick={props.toggleFullscreen}>
              <img src={ExpandIcon}></img>
              <br></br>
              Expand
            </button>
            <button
              onClick={(event) => props.handlePopUpOpen(event, "setting")}
            >
              <img src={SettingIcon}></img>
              <br></br>
              Setting
            </button>
            <button
              onClick={props.moveToArchive}
              disabled={props.disableBulkActionBtn}
            >
              <img src={FolderIcon}></img>
              <br></br>
              Archive
            </button>
            <button onClick={props.handleColumnBtnClick}>
              <img src={ColumnsIcon}></img>
              <br></br>
              Columns
            </button>
          </div>
        </div>
        {/* <div className='searchContainer'>
					<div className='searchBar'>
						<button type='submit'>Search</button>
						<input
							type='search'
							placeholder='For search type and press Enter'
							value={props.searchText}
							onChange={(event) => {
								props.handleSearch(event);
							}}
							onKeyDown={(event) => {
								props.handleSearch(event);
							}}></input>
					</div>
					<img
						onClick={() => {
							props.openConfirmationBox();
						}}
						//onClick={(event) => { props.handleSortContainerVisibility(event) }}
						className='deleteIcon'
						src={DeleteIcon}
						alt='DeleteIcon'
					/>
					<div className='searchBarEnd'>
						<img
							onClick={props.handleExcelExport}
							className='excelIcon'
							src={ExcelIcon}
							alt='ExcelIcon'
						/>
					</div>
				</div> */}
        <BasicTableGrid
          gridId="accounts"
          data={props.deferedData} // Data to be displayed
          fixedCols={2} // How many columns are fixed and not scrolling
          initialSize={[
            35, 221, 150, 150, 150, 150, 150, 150, 150, 116, 221, 185, 246, 962,
          ]}
          initialSorting={[{ id: "Txn. Date/Time", desc: false }]}
          handleSelect={(selectedRows) => {
            props.handleCheckBoxes(selectedRows);
          }}
          length={20}
          pagination={props.pagination}
          setPagination={props.setPagination}
          onSortChange={(data) => props.handleSort(data)}
          columnsOptionPopUpRef={props.columnsOptionPopUpRef}
          columns={[
            {
              header: "Legal Name",
              cell: (data) => {
                const userData = data.row.original;
                return (
                  <span style={{ marginLeft: "10px" }}>
                    {userData?.firstName} {userData.lastName}
                  </span>
                );
              },
            },
            {
              header: "Phone Number",
              cell: (data) => {
                var info = data.row.original;
                return (
                  <PhoneNumber
                    userId={info.userId}
                    countryCode={info.countryCode}
                    number={info.contactNumber}
                    type="phone"
                    onChange={(contactInfo) => {
                      // props.handleChange('phoneNumber', contactInfo, info.userId)
                    }}
                  />
                );
              },
            },
            {
              header: "Expense Type",
              cell: (data) => data.row.original.expenseType,
            },
            {
              header: "Payment Status",
              cell: (data) => data.row.original.paymentStatus,
            },
            {
              header: "Product(s)",
              cell: (data) => data.row.original.coursesMnemonic,
            },
            {
              header: "Txn. Date/Time",
              accessorFn: (row) => {},
              cell: (data) => {
                const userData = data.row.original;

                return userData.createdAt ? (
                  <div className="d-flex gap-1">
                    <div className="date">
                      {moment(userData.createdAt).format("Do MMM, YYYY")}
                    </div>
                    <div className="date">
                      {moment(userData.createdAt).format("HH:mm")}
                    </div>
                  </div>
                ) : (
                  "No Date Found"
                );
              },
            },
            { header: "Txn. Id", cell: (data) => data.row.original.paymentId },
            {
              header: "Net Amount",
              cell: (data) => data.row.original.netTotal,
            },
            {
              header: "Tax",
              cell: (data) => {
                return data.row.original.GST?
                          data.row.original.GST : "_";
              },
            },
            {
              header: "Gross Amount",
              cell: (data) => {
                const order = data.row.original;
                return (
                  order?.paymentMethod ==="ccavenue"? 
                  (order.netTotal -  order?.GST)
                  : (order.netTotal -  order?.paymentDetails?.fee/100) ?
                  (order.netTotal - order?.paymentDetails?.fee/100)
                  :"_"
                );
              },
            },
            {
              header: "Payment Gateway Charges",
              cell: (data) => {
                const order = data.row.original;
                return  order?.paymentMethod ==="ccavenue"? 
                (order?.paymentDetails?.order_fee_flat) 
                : data.row.original.paymentDetails?.fee ? 
                data.row.original.paymentDetails?.fee / 100 : "_";
              },
            },
            {
              header: "Bank Settled Amount",
              cell: (data) => {
                return data.row.original.paymentDetails?.amount?
                data.row.original.paymentDetails?.amount / 100 : "_";
              },
            },
            {
              header: "Bank Settlement Date",
              accessorFn: (row) => {},
              cell: (data) => {
                const userData = data.row.original;

                return userData.settlementAt ? (
                  <div className="d-flex gap-1">
                    <div className="date">
                      {moment(userData.settlementAt).format("Do MMM, YYYY")}
                    </div>
                    <div className="date">
                      {moment(userData.settlementAt).format("HH:mm")}
                    </div>
                  </div>
                ) : (
                  "No Date Found"
                );
              },
            },
            { header: "Closing balance as of [Txn. date]" },
            {
              header: "Tags",
              cell: (data) => {
                const info = data.row.original;
                const userManualTags = data.row.original.userManualTags;
                return (
                  <Tags
                    userId={info.userId}
                    automaticTags={[]} // [{key:value}]
                    manualTags={userManualTags}
                    onChange={(manualTags) => {
                      // props.handleChange('manualTags', manualTags, info.userId)
                    }}
                  />
                );
              },
            },
          ]}
        />

        <PopUp
          visible={props.isPopUpVisible && props.popUp === "setting"}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left - 100}
          handleOutSideClick={props.handlePopUpClose}
        >
          <div style={{ height: "200px", overflow: "auto" }}>
            <div className="settingOption" onClick={props.handleExcelExport}>
              Export to excel
            </div>
            <div className="settingOption" onClick={props.openConfirmationBox}>
              Delete Purchase
            </div>
          </div>
        </PopUp>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={props.showConfirmationBox}
          onHide={props.closeConfirmationBox}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirm Delete!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure want to{" "}
              <span
                style={{
                  color: "#157542",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                delete{" "}
              </span>
              the account entry?
            </p>
            <p>
              Please enter{" "}
              <span
                style={{
                  color: "#157542",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                secret key
              </span>{" "}
              to confirm action.
            </p>
            <input
              type="password"
              style={{ width: "80%" }}
              value={props.keyValue}
              onChange={(e) => props.setKeyValue(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.closeConfirmationBox}>
              Cancel
            </Button>
            <Button variant="success" onClick={props.handleBulkOrderDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END : Pagination */}
        {/* Search Filter PopUp */}
        <div style={{ zIndex: "9999999999999999999999" }}>
          {/* {props.popUp === "filterTag"  &&<PopUp visible={props.isPopUpVisible}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<div>Tag filter</div>
			</PopUp>} */}
          {props.popUp === "filterCategory" && (
            <PopUp
              visible={props.isPopUpVisible}
              top={props.popUpPosition.top}
              left={props.popUpPosition.left}
              handleOutSideClick={props.handlePopUpClose}
            >
              <SelectionPopUp
                data={props.allCategories}
                optionKey={"title"}
                title={"Select Category"}
                searchPlaceholder={"Search category"}
                isSearchEnabled={true}
                height={"300px"}
                width={"400px"}
                selectedItems={props.allCategories.filter((category) =>
                  props.filters.categories.includes(category._id)
                )}
                dataKey={"_id"}
                selectedItemsKey={"_id"}
                selectionType={"multiple"}
                handleCancel={props.handlePopUpClose}
                handleSave={props.handlePopUpSave}
              />
            </PopUp>
          )}
          {props.popUp === "filterCourse" && (
            <PopUp
              visible={props.isPopUpVisible}
              top={props.popUpPosition.top}
              left={props.popUpPosition.left}
              handleOutSideClick={props.handlePopUpClose}
            >
              <SelectionPopUp
                data={props.allCourses}
                optionKey={"title"}
                title={"Select Course"}
                searchPlaceholder={"Search course"}
                isSearchEnabled={true}
                height={"300px"}
                width={"400px"}
                selectedItems={props.allCourses.filter((course) =>
                  props.filters.courseIds.includes(course._id)
                )}
                dataKey={"_id"}
                selectedItemsKey={"_id"}
                selectionType={"multiple"}
                handleCancel={props.handlePopUpClose}
                handleSave={props.handlePopUpSave}
              />
            </PopUp>
          )}
          {props.popUp === "filterExamStatus" && (
            <PopUp
              visible={props.isPopUpVisible}
              top={props.popUpPosition.top}
              left={props.popUpPosition.left}
              handleOutSideClick={props.handlePopUpClose}
            >
              <SelectionPopUp
                data={expenseOptions}
                optionKey={"name"}
                title={""}
                searchPlaceholder={""}
                isSearchEnabled={false}
                height={"auto"}
                width={"auto"}
                selectedItems={expenseOptions.filter(
                  (item) => props.filters.expenseType === item._id
                )}
                dataKey={"_id"}
                selectedItemsKey={"_id"}
                selectionType={"single"}
                handleCancel={props.handlePopUpClose}
                handleSave={props.handlePopUpSave}
              />
            </PopUp>
          )}
        </div>
      </div>
    </>
  );
}

export default AccountsView;
