
import DeleteIcon from '@assets/common/deleteIcon.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import BasicTableGrid from '@components/BasicTableGrid';
import TextAndLinks from '@components/TextAndLinks';
import ButtonBars from '@components/ButtonBars';
import Search from '@components/Search';
import ExpandIcon from '@assets/userDashboard/expand.png';
import ColumnsIcon from '@assets/userDashboard/columns.png';
import PopUp from '@components/PopUp';
import EditVisibilityPopUpView from './EditVisibilityPopUp';
import './dynamicArticles.css';

function MailsView(props) {
	return (
		<div className='customerCareView bg-white d-flex flex-column flex-grow-1'  ref={props.fullscreenElementRef}
		>
			<div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[{
							type: 'round',
							text: '+',
							onClick: (event) => {
								props.handleRedirectToHome(null);
							},
						},
						{
						  text: "Delete",
						  iconEnd: DeleteIcon,
						  disabled: props.isBulkSelected,
						  onClick: (event) => {
							props.handleDeleteBulk(event);
						  },
						},
					]}
					/>

					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						<button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button>
					</div>
				</div>

			{/* <Search
					value={props.searchText}
					placeholder = {"Search Article"}
					onSearch={props.handleSearch}
					onSearchClick={(event=>{ event.key ="Enter"; props.handleSearch(event)})}
					isDefaultChild={true}
					></Search> */}

			<BasicTableGrid 
				gridId='Mails'
				data={props.deferredData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,265,262,288,506]}
				initialSorting={[{"id": "createdAt","desc": false}]}
				handleSelect={(selectedRows) => {
					props.handleCheckBoxes(selectedRows);
				}}
				length={20}
				onSortChange={props.handleSort}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
                    {header: 'Scenario name',
					cell: (data) => {
					const info = data.row.original;
					return <TextAndLinks
						title= {info?.scenario}
						subTitle= {info?.pathUrl}
						images = {[EditPencilIcon]}
						onClick={(event, index)=>{props.handleLinksClick(index, info)}}
						/>
				}},
				{header: 'Internal name',
				cell: (data) => {
				const info = data.row.original;
				return info?.internalTitle
			}},
				{header: 'Internal Description', 
                 cell: (data) => {
					const info = data.row.original;
						return info.internalDescription
					}},
			{header: 'Subject', 
				cell: (data) => {
				const info = data.row.original;
					return info.subject
				}},
			{header: 'Status', 
				cell: (data) => {
				const info = data.row.original;
					return <span className='w-100' style={{color: info.status === 'active' ? 'green' : 'red', cursor: 'pointer'}}
					onClick={(event)=>{props.handleOpenPopUp(event, info)}}					  
					>{info.status === "active"? "Active": "Disabled"}</span>
				}},
				{header: 'Times sent', 
					cell: (data) => {
					const info = data.row.original;
						return info.sentCount
					}}
				]}
				/>
				
				<PopUp
					visible={props.isPopUpVisible}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<EditVisibilityPopUpView
					    template = {props.clickedRow}
	                    handleSave = {props.handlePopUpSave}
						handleClose={props.handlePopUpClose}
					/>
				</PopUp>
		</div>
	);
}

export default MailsView;
