import { useEffect, useState, useRef } from "react";
import SidebarNavigation from "@components/SidebarNavigation";
import LeadsView from "./view";

import { decryptText } from "@library/enc-dec";
import { checkLoginAndRole } from "@helpers/Login/";
import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";

import { navigationData } from "./navigationData";
import { defaultFolders } from "./data";

import { getAllTags } from "@helpers/common";
import * as TagsActions from "@redux/actions/Tags";

import { useNavigate, defer, useLocation } from "react-router-dom";

import * as QueriesServices from "@services/Queries";
import * as LeadsServices from "@services/Leads";
import * as FolderServices from "@services/Folders";
import Swal from "sweetalert2";
import { Hidden } from "@mui/material";
import { current } from "immer";
import { getLeadsFoldersCount } from "../../../services/Leads";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as TabsActions from "@redux/actions/Tabs";
import { statusList } from './data';

var pageData = [];
var listOfCheckedRows = [];
var roleCode, role;

function Leads(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));
  const [deferredData, setDeferredData] = useState({});

  const [searchText, setSearchText] = useState("");
  const [isSorted, setIsSorted] = useState(false);
  const [sortBy, setSortBy] = useState("updatedAt");

  const [folders, setFolders] = useState([]);

  const [activeTab, setActiveTab] = useState(props.tab);

  // START : popup stats
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  // END : popup stats

  // START : states for default button bars
  const fullscreenElementRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  // END : states for default button bars

  // START : states for basic table grid
  // END : states for basic table grid

  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);
  const [disableCallNotes, setDisableCallNotes] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    [role, roleCode] = checkLoginAndRole("leads");
    document.addEventListener("click", handleClickOutside); // code for handleColumnBtnClick
    autoUpdateLeads();
    getTags();
    return () => {
      document.removeEventListener("click", handleClickOutside); // code for handleColumnBtnClick
    };
  }, []);

  useEffect(() => {
    getAllFolders();
  }, [props.access]);

  useEffect(() => {
    getLeadsData();
  }, [page, limit, activeTab, limit, page, isSorted, sortBy, searchText]);

  const getTags = async () => {
    try {
      const allTags = await getAllTags("manual");
      if (allTags) {
        props.updateTags(allTags);
      } else {
        console.log("Error coming while getting all Tags");
      }
    } catch (err) {
      console.log("Error coming while getting all Tags", err);
    }
  };

  const handleClickOutside = (event) => {
    if (
      columnsOptionPopUpRef.current &&
      !columnsOptionPopUpRef.current.contains(event.target)
    ) {
      columnsOptionPopUpRef.current.style.display = "none";
    }
  };
  const getAllFolders = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      pageNo: "0",
      limit: "10000",
      isSorted: true,
      sortBy: "title",
      searchText: "",
      visibility: "active",
    };
    const response = await FolderServices.getAllFolders(payload, authToken);
    var allFolders = response.data;
    allFolders = allFolders
      .map((folder) => (folder.pageType === "leads" ? folder : null))
      .filter(Boolean);

    if (roleCode === 2 || roleCode === "2") {
      if (props.access?.leadsRights) {
        allFolders = allFolders.filter((folder) =>
          props.access.leadsRights.includes(folder._id)
        );
      } else {
        allFolders = [];
      }
    }

    allFolders = [...defaultFolders, ...allFolders];
    const foldersCount = await getLeadsFoldersCount();

    foldersCount?.forEach((folderCount) => {
      allFolders = allFolders.map((folder) => {
        if (
          folder._id === folderCount.folderName ||
          folder.title === folderCount.folderName
        ) {
          if (!folder.titleTag1) {
            folder.titleTag1 = {
              text: "",
              bgColor: "#B6B6B6",
              fontColor: "white",
            };
          }
          folder.titleTag1.text = folderCount.count;
        }

        return folder;
      });
    });

    setFolders(allFolders);
  };

  const getLeadsFoldersCount = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const response = await LeadsServices.getLeadsFoldersCount(authToken);
    if (response.success) {
      return response.data;
    }
    return null;
  };
  function preProcessLeads(response) {
    response.data = response.data?.filter((rowCollection) => rowCollection?.data.length > 0);
    const hiddenLeads = localStorage.getItem("hiddenLeads");
    response.data = response.data?.map((rowCollection) => {
      rowCollection.data = rowCollection.data?.filter((lead) => {
        let isHidden = !hiddenLeads?.includes(lead._id);
        let isInFuture = true;
        // if(isHidden){
        // 	isInFuture = new Date(hiddenLeads?.filter(hiddenLeads => hiddenLeads._id ===lead._id)[0].time) > new Date();
        // }
        return isHidden && isInFuture;
      });
      return rowCollection;
    });

    return response;
  }
  const getLeadsData = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    let payload = {
      isSorted: isSorted,
      sortBy: sortBy,
      searchText: searchText,
      currentFolderName: [
        "myLeads",
        "unClaimedLeads",
        "lost",
        "archive",
        "successfullyClosed",
        "waiting",
        "irrelevant",
      ].includes(activeTab._id)
        ? activeTab._id
        : activeTab.title,
    };
    var deferredResponse = defer({
      res: LeadsServices.getAllLeads(payload, authToken).then(preProcessLeads),
    });
    setDeferredData(deferredResponse);

    deferredResponse.data.res
      .then((response) => {
        pageData = response;
        console.log("REsponsejhsdgjfgskdgfksdgfk",response)
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };

  const autoUpdateLeads = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const response = await LeadsServices.autoUpdateLeads(authToken);
  };

  const updateStatus=async(status)=>{
    
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload ={
            leadIds : selectedRow ? [selectedRow] : listOfCheckedRows.filter(row => row._id !== null && row._id !== undefined).map(row=>row._id),
            status : status[0].key,
            readableStatus:status[0].text
        }
    const response = await LeadsServices.updateLeadStatus(payload, authToken);
    if(response.success)
    {
      if(selectedRow && !['moreCourses','seemsNotInterested', 
        'lessQualified', 'waitingTime', 'irrelevant', 'nonMedico', 'outOfBudget'
        ,'notInterested','lostToCompetitor','requirementsNotMet', 'closed'
      ].includes(status[0].key)){
        handleChange("status",status[0].key, selectedRow);
      }else if(['moreCourses','seemsNotInterested', 
        'lessQualified', 'waitingTime', 'irrelevant', 'nonMedico', 'outOfBudget'
        ,'notInterested','lostToCompetitor','requirementsNotMet', 'closed'
      ].includes(status[0].key)){
        getAllFolders();
        getLeadsData();
        getLeadsFoldersCount();  
      }else{
        getAllFolders();
        getLeadsData();
        getLeadsFoldersCount();
      }
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }else{
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }

  }
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedRows = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
    setDisableCallNotes(selectedRows.length === 1 ? false : true);
  };
  const handlePopUpOpen = (event, popUpName, id) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();

     if(popUpName === "changeStatus"){
        setSelectedRow(id);
     }
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    columnsOptionPopUpRef.current.style.display = "none";
  };
  const handlePopUpSave = (data) => {
    if (["createNew","addToWaiting","addCallLog","hideTill","transferTo"].includes(popUp)) {
      getAllFolders();
      getLeadsData();
    }
    if(popUp === "changeStatus"){
      updateStatus(data)
    }
    handlePopUpClose();
    // getAllBssForms();
    setDisableBulkActionBtn(true);
  };
  const handleColumnBtnClick = (event) => {
    handlePopUpClose();
    columnsOptionPopUpRef.current.style.display = "block";
    var position = getEventElementPosition(event);
    columnsOptionPopUpRef.current.style.top = position.top + "px";
    columnsOptionPopUpRef.current.style.left = position.left - 160 + "px";
    event.stopPropagation();
  };
  const handleSearch = (value) => {
    setSearchText(value.text);
  };
  const handleSearchInput = (data) => {
    setSearchText(data);
    if (data === "") {
      getLeadsData();
    }
  };
  const handleSort = (data) => {
    const mapping = { "Date/Time": "createdAt", "Date Modified": "updatedAt" };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    setSortBy(id);
    setIsSorted(data[0]?.desc ? data[0].desc : false);
  };
  // START : fn for navigation
  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };
  const handleRedirectToHome = (event, queryDocId, defaultComponent) => {
    //Handle setting default page while navigating
    let finalNavigationData = [];
    finalNavigationData = navigationData;
    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      finalNavigationData = setDefaultNavigation("UserLead");
    }


    if (queryDocId === "none") {
      navigate("/users_", { state: { finalNavigationData } });
    } else {
      navigate("/users_/" + queryDocId , {
        state: { finalNavigationData },
      });
      
    }
  };
  // END : fn for navigation
  const handleClaimLead = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      leadIds: listOfCheckedRows.map((lead) => lead._id)?.filter((lead) => lead),
    };
    let response = await LeadsServices.claimTheLeads(payload, authToken);
    if (response.success) {
      if(response.data[0].message)
      {
        Swal.fire({
          icon: "warning",
          title: response.data[0].message,
          showConfirmButton: false,
          timer: 2500,
        });}else{
          
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        props.updateTab(folders[0]);
        navigate(".", { replace: true });
        getAllFolders();
        }
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };
  const handleReleaseLead = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      leadIds: listOfCheckedRows?.map((lead) => lead._id).filter((lead) => lead),
    };
    let response = await LeadsServices.moveToRelease(payload, authToken);
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      getAllFolders();
      getLeadsData();

    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const moveToArchive = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const leadList = listOfCheckedRows?.map((lead) => {
      return {
        leadId: lead._id,
        movedAt: null,
        moveToFolderName: "archive",
        currentFolderName: lead.currentFolderName,
        readableMoveToFolderName: "Archive",
        readableCurrentFolderName: lead.currentFolderName,
      };
    });

    const payload = {
      leadList,
    };

    let response = await LeadsServices.leadMoveToFolder(payload, authToken);
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: "Moved to archive",
        showConfirmButton: false,
        timer: 2500,
      });
      getLeadsData();
      getAllFolders();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const showHiddenLeads = () => {
    localStorage.setItem("hiddenLeads", []);
    getLeadsData();
    getAllFolders();
  };
  const handleTabClick = (tab) => {
    props.updateTab(tab);
    navigate(".", { replace: true });
  };
  useEffect(() => {
    setActiveTab(props.tab);
  }, [props.tab]);

  const handleClearFilter=()=>{
    setSearchText('')
  }
  
  const handleChange=(field, value, id)=>{

    let updatedPageData = pageData
    updatedPageData.data = pageData.data.map((data, index)=>{
          let newdata = data
          newdata.data = data.data.map((innerData, i)=>{
            if(innerData._id === id){
              innerData[field] = value
            }
            return innerData
          })
          return newdata
    })
    var deferredResponse = defer({ res: updatedPageData });
    pageData = updatedPageData;
    setDeferredData(deferredResponse);

  }

  return (
    <>
      <SidebarNavigation />
      <LeadsView
        searchText={searchText}
        folders={folders}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        // START : states for popup
        popUp={popUp}
        isPopUpVisible={isPopUpVisible}
        popUpPosition={popUpPosition}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        // END : states for popup

        disableBulkActionBtn={disableBulkActionBtn}
        disableCallNotes={disableCallNotes}
        // START : states for default button bars
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        handleColumnBtnClick={handleColumnBtnClick}
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        // END : states for default button bars

        // START : states for basic table grid
        handleCheckBoxes={handleCheckBoxes}
        // END : states for basic table grid

        deferredData={deferredData}
        handleRedirectToHome={handleRedirectToHome}
        handleSort={handleSort}
        handleSearch={handleSearch}
        handleSearchInput={handleSearchInput}
        handleClaimLead={handleClaimLead}
        handleReleaseLead={handleReleaseLead}
        listOfCheckedRows={listOfCheckedRows}
        moveToArchive={moveToArchive}
        showHiddenLeads={showHiddenLeads}
        handleClearFilter={handleClearFilter}
        handleChange={handleChange}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tab: state.tabs.LeadsTab,
    access: state.login.access,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTab: TabsActions.updateLeadsTab,
      updateTags: TagsActions.updateTags,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
