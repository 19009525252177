import React from "react";
import { useState, useEffect, useRef } from "react";
import CardView from "./view";

import { getEventElementPosition } from "@helpers/common.js";
import * as StaticAssetsServices from "@services/StaticAssets";
import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";

var editorTextEdit = false;
function Card(props) {
  const [courseDetails, setCourseDetails] = useState(props.courseDetails);
  const colorPickerRef = useRef(null);
  const cardPointerEditorRef = useRef(null);
  const [displayIcons, setDisplayIcons] = useState([]);

  const [popUpType, setPopUpType] = useState(null);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  useEffect(() => {
    // Find the element with the id 'innerSideNavbar'
    const innerSideNavbarElement = document.getElementById("innerSideNavbar");

    // Create a new div element for the InnerComponent
    const newDiv = document.createElement("div");

    // Set an id for the new div (optional)
    newDiv.id = "innerComponentThumbnailImage";
    // newDiv.textContent = 'Hello, this is the new content!';
    newDiv.style.height = "200px";
    newDiv.style.width = "200px";
    newDiv.style.backgroundColor = "#eee";
    newDiv.style.margin = "30px -18px";
    newDiv.style.marginBottom = "-30px";
    newDiv.style.borderRadius = "10px";
    newDiv.style.backgroundPosition = "center center";
    newDiv.style.backgroundSize = "contain";
    newDiv.style.backgroundRepeat = "no-repeat";
    newDiv.style.backgroundImage =
      courseDetails?.squareThumbnailUrl != null
        ? typeof courseDetails.squareThumbnailUrl === "string"
          ? `url(${courseDetails.squareThumbnailUrl})`
          : `url(${URL.createObjectURL(courseDetails.squareThumbnailUrl)})`
        : `url('')`;
    // Append the new div under the 'innerSideNavbar' div
    innerSideNavbarElement.appendChild(newDiv);
    innerSideNavbarElement.insertBefore(
      newDiv,
      innerSideNavbarElement.firstChild
    );

    // Cleanup (optional): Remove the new div when the component unmounts
    return () => {
      innerSideNavbarElement.removeChild(newDiv);
    };
  }, [courseDetails]);

  useEffect(() => {
    getDisplayIcons();
  }, []);
  useEffect(() => {
    setCourseDetails(props.courseDetails);
  }, [props.courseDetails]);

  useEffect(() => {
    props.handleFormDataChange(courseDetails);
  }, [courseDetails]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        colorPickerRef.current.contains(event.target)
      ) {
        // Click outside the colorPickerRef, hide color input
        var colorPicker = document.getElementById("cardColorPicker");
        colorPicker.click();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorPickerRef]);

  const handleChange = (event, field) => {
    var updatedCourseDetails = { ...courseDetails };
    if (field == "cardTitle") {
      updatedCourseDetails.cardTitle = event.target.value;
    } else if (field == "cardDescription") {
      updatedCourseDetails.cardDescription = event.target.value;
    } else if (field == "cardPointers") {
      if (!updatedCourseDetails?.cardPointers || typeof(updatedCourseDetails.cardPointers) === 'string') {
        updatedCourseDetails.cardPointers = { icon: "", pointer: "" };
      }
      updatedCourseDetails.cardPointers.icon = event.icon;
    } else if (field == "cardBackgroundColor") {
      updatedCourseDetails.cardBackgroundColor = event.target.value;
    } else if (
      field == "squareThumbnailUrl" ||
      field === "rectangleThumbnailUrl"
    ) {
      handleOpenPopUp(event, field);
      setPopUpType(field);
    } else if (field == "imageUpload") {
      updatedCourseDetails[popUpType] = event[0];
      handlePopUpClose();
    }
    setCourseDetails(updatedCourseDetails);
  };

  const handleCardPointers = (content) => {
    var updatedCourseDetails = { ...courseDetails };
    if (courseDetails.cardPointers && editorTextEdit) {
      const editorInstance = cardPointerEditorRef.current.getEditor();
      const text = editorInstance.getText().trim(); 
      if (text.length !== 0) {
        if(typeof(updatedCourseDetails.cardPointers) ==='string' || updatedCourseDetails.cardPointers === null)
        {

          updatedCourseDetails.cardPointers = {pointer: content, icon: ""}
        }
        else{
          updatedCourseDetails.cardPointers.pointer = content;

        }
        setCourseDetails(updatedCourseDetails);
      }

    }
    editorTextEdit = false;
  };


  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debounceHandleCardPointers = debounce(handleCardPointers, 1000);

  const handleEditorTextEdit = (keyType) => {
    if (!editorTextEdit) {
      editorTextEdit = true;
    }
  };

  const getDisplayIcons = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: "0",
        limit: "9999999",
        isSorted: false,
        sortBy: "createdAt",
        searchText: "",
        visibility: "active",
        assetType: "icon",
      };

      var response = await StaticAssetsServices.getAllStaticAssets(
        payload,
        authToken
      );
      if (response.success) {
        setDisplayIcons(response.data);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while getting all BSS Forms", err);
    }
  };

  const clearIcons=()=>{
    var updatedCourseDetails = { ...courseDetails };
    updatedCourseDetails.cardIcons = [];
    setCourseDetails(updatedCourseDetails);
  }

  const handleDisplayIcon = (event, icon) => {
    // setFormValuesEmpty(false);
    let isChecked = event.target.checked;
    let iconUrl = icon.assetUrl;
    let iconType = icon.title;
    var updatedCourseDetails = { ...courseDetails };

    if (!updatedCourseDetails.cardIcons) {
      updatedCourseDetails.cardIcons = { icon: "", pointer: "" };
    }

    if (courseDetails.cardIcons) {
      if (isChecked && updatedCourseDetails.cardIcons.length <= 3) {
        updatedCourseDetails.cardIcons.push({
          icon: iconUrl,
          pointer: iconType,
        });
        setCourseDetails(updatedCourseDetails);
      } else {
        updatedCourseDetails.cardIcons = updatedCourseDetails.cardIcons.filter(
          (displayIcon) => displayIcon.pointer !== iconType
        );
        setCourseDetails(updatedCourseDetails);
      }
    }
  };

  // All Pop Up related functions
  // To close the popup container
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async () => {
    handlePopUpClose();
  };
  // Open popUps
  const handleOpenPopUp = (event, popUp) => {
    handlePopUpClose();
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };

  return (
    <CardView
      colorPickerRef={colorPickerRef}
      cardPointerEditorRef={cardPointerEditorRef}
      courseDetails={courseDetails}
      handleChange={handleChange}
      handleCardPointers={debounceHandleCardPointers}
      handleEditorTextEdit={handleEditorTextEdit}
      handleDisplayIcon={handleDisplayIcon}
      handlePopUpClose={handlePopUpClose}
      handlePopUpSave={handlePopUpSave}
      popUpPosition={popUpPosition}
      isPopUpVisible={isPopUpVisible}
      displayIcons={displayIcons}
      clearIcons={clearIcons}
    />
  );
}

export default Card;
