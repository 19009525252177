import React from 'react';

import CopyIcon from '@assets/common/copyIcon2.png';
import PopUp from '@components/PopUp';
import EditNumberPopUp from '@components/EditNumberPopUp';

import './style.css'
function PhoneNumberView(props){
    return(
        <div className='d-flex w-100 gap-1 phoneNumber' style={{position:' static'}} >

            {props.isAlertVisible && 
            <span className="alert alert-success text-center w-100">
              Copied
            </span> }
            
            {!props.isAlertVisible &&
            <>
              <div className='numberView' onDoubleClick={props.handlePopUpOpen}>
                <span>{props.countryCode}</span>
                <span style={{cursor:'pointer', marginLeft: '5px'}}
                    >
                    {props.number? props.number : 'Add Number'}
                </span>
              </div>
                <span className='copyIcon'>
                    <img
                        src={CopyIcon}
                        alt='CopyIcon'
                        className='clipboard'
                        style={{cursor:'pointer'}}
                        onClick={props.handleCopyClick}
                    />
                </span>
            </> }
            <PopUp
                visible={props.isPopUpVisible}
                top={30}
                left={0}
                handleOutSideClick={props.handlePopUpClose}
                >
                <EditNumberPopUp
					userId={props.props.userId}
					currentCountryCode={props.countryCode}
					currentPhoneNumber={props.number}
					numberType={props.props.type}
					handleClose={props.handlePopUpClose}
					handleSave={props.handlePopUpSave}
				/>
             </PopUp>

        </div>
        
    )
};

export default PhoneNumberView