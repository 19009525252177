import React from 'react';

import PopUp from '@components/PopUp';
import SelectionPopUp from '@components/SelectionPopUp';
import PlusIcon from '@assets/common/plusCircleIcon.png';

function TagsView(props){
    return(
        <div className='tagDiv w-100 h-100 align-items-center'>
            <div className='autoTagDiv'>
                {props.props.automaticTags ? Object.entries(props.props.automaticTags)?.map(([key, value]) =>{
							if(value && value!='no')
							{
								return <span className={key === 'status'
									? value === 'active'
										? 'autoTag Green'
										: 'autoTag Red'
											: 'autoTag'
									}
									key={key}
									>

										{value.toUpperCase()}
								</span>
							}
						  }) : ''}</div>
            <div className='manualTagDiv'>
                {props.manualTags?.map((tag, index)=>{
                    return <span key={index}
                                className="manualTag"
                                style={{
                                    backgroundColor: tag.backgroundColor,
                                    color: tag.textColor
                                }}
                            > 
                            {tag.title}
                            </span>
                })
                }
            </div>
            <div><img src={PlusIcon} style={{height:'18px', cursor:'pointer'}} className='ms-2 addBtn' onClick={props.handlePopUpOpen}/>
            <PopUp
                visible={props.isPopUpVisible}
                top={30}
                left={0}
                handleOutSideClick={(event)=>{props.handlePopUpClose()}}>
                <div className='hideImage'>
                <SelectionPopUp 
				  data = {props.allTags}
				  optionKey= {"title"}
				  title={"Add/Remove tags"}
				  searchPlaceholder={"Search for tag"}
				  isSearchEnabled={true}
				  height= {"200px"}
				  width= {"285px"}
				  selectedItems={props.manualTags}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"multiple"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
                </div>  
             </PopUp>
             </div>
        </div>        
    )
};

export default TagsView