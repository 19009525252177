export const defaultTabs = [
  {
    _id: "all",
    title: "All",
    isActive: true,
  },
  {
    _id: "razorpay",
    title: "RazorPay",
    isActive: false,
  },
  {
    _id: "stripe",
    title: "Stripe",
    isActive: false,
  },
  {
    _id: "eazypay",
    title: "EazyPay",
    isActive: false,
  },
  {
    _id: "idfc",
    title: "IDFC",
    isActive: false,
  },
  {
    _id: "axis",
    title: "AXIS",
    isActive: false,
  },
  {
    _id: "ccavenue",
    title: "HDFC",
    isActive: false,
  },
];
