import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import "./App.css";
import store from "@redux/store";

import Login from "./pages/Login";
import Home from "@pages/Home";
import Content from "@pages/Content/Main";
import CourseCategoryHome from "@pages/Content/CourseCategories/CourseCategory/Home";
import VideosHome from "@pages/Content/Videos/Video/Home";
import CourseHome from "@pages/Content/Courses/Course/Main";
import SeriesHome from "@pages/Content/Series/Series/Main";

import ExamHome from "@pages/Content/Exams/Exam/Home";
import TestHome from "@pages/Content/Tests/Test/Home";
import FaqHome from "@pages/Content/FAQ/FAQ/Home";

import Accounts from "./pages/Accounts";
import Verification from "./pages/Verification";

import CommentPage from "@pages/Comments/main";
import OffersPages from "@pages/Offers/Offer/Home";
import Offers from "@pages/Offers/Main";

import Dynamic from "./pages/Dynamic/Main";
import DynamicHomeTab from "@pages/Dynamic/Home";
import DynamicArticle from "@pages/Dynamic/Articles/Article";
import DynamicMail from "@pages/Dynamic/Mails/MailContent";

import Users from "@pages/Users/Main";
import UserHome from "@pages/Users/Home";

import CustomerCare from "@pages/CustomerCare/Main";
import QueriesDetails from "@pages/CustomerCare/Details/Main";

import Leads from "@pages/Leads/Main";
import LeadDetails from "@pages/Users/LeadDetails/Main";

import Certificates from "@pages/Certificates";

import Notification from "@pages/Notification";
import InvoicePDF from "./pages/Invoice";

import BSS from "@pages/BSS/Main";

import SuperAdmin from "@pages/SuperAdmin/Main";
import AdminUserHome from "@pages/SuperAdmin/AdminUsers/AdminUser/Home";
import RequestDetails from "@pages/SuperAdmin/Approvals/Request";

import Analytics from "@pages/Analytics/Main";
import StripeInvoice from "@components/Invoice/StripeInvoice";
import HDFCInvoice from "@components/Invoice/HDFCInvoice";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/content" element={<Content />} />
          <Route
            path="/course-category/:id?"
            element={<CourseCategoryHome />}
          />
          <Route path="/videos/:id?" element={<VideosHome />} />
          <Route path="/course/:id?" element={<CourseHome />} />
          <Route path="/series/:id?" element={<SeriesHome />} />
          <Route path="/exam/:id?" element={<ExamHome />} />
          <Route path="/test/:id?" element={<TestHome />} />

          <Route path="/faq/:id?" element={<FaqHome />} />
          <Route path="/comments" element={<CommentPage />} />

          <Route path="/accounts" element={<Accounts />} />
          <Route path="/verification" element={<Verification />} />

          <Route path="/offers" element={<Offers />} />
          <Route path="/offers_" element={<OffersPages />} />
          <Route path="/offers/:id?" element={<OffersPages />} />

          <Route path="/users/:id?" element={<Users />} />
          <Route path="/users_/:id?" element={<UserHome />} />

          <Route path="/dynamic" element={<Dynamic />} />
          <Route path="/dynamic_/:id?" element={<DynamicHomeTab />} />
          <Route path="/DynamicArticle" element={<DynamicArticle />} />
          <Route path="/DynamicArticle/:id?" element={<DynamicArticle />} />
          <Route path="/DynamicMail/" element={<DynamicMail />} />
          <Route path="/DynamicMail/:id?" element={<DynamicMail />} />

          <Route path="/customer-care" element={<CustomerCare />} />
          <Route path="/query/:id?" element={<QueriesDetails />} />

          <Route path="/leads" element={<Leads />} />
          <Route path="/lead/:id?" element={<LeadDetails />} />

          <Route path="/certificates" element={<Certificates />} />

          <Route path="/notification" element={<Notification />} />
          <Route path="/invoice" element={<InvoicePDF />} />

          <Route path="/bss" element={<BSS />} />

          <Route path="/superAdmin" element={<SuperAdmin />} />
          <Route path="/adminUser" element={<AdminUserHome />} />
          <Route path="/adminUser/:id?" element={<AdminUserHome />} />
          <Route path="/requestDetails/:id?" element={<RequestDetails />} />

          <Route path="/analytics" element={<Analytics />} />
          
          <Route path="/stripeInvoice" element={<StripeInvoice />} />
          <Route path="/hdfcInvoice" element={<HDFCInvoice />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
