import React from 'react';

import PlusIcon from '@assets/common/plusIcon.png';
import UploadIcon from '@assets/common/uploadIcon.png';

import './thumbnailPopup.css';
function ThumbnailPopupView(props) {
	return (
		<>
			<div
				onDoubleClick={(event) => {
					props.handleDoubleClick(event);
				}}
				className='thumbnailContainer'
				style={{backgroundImage: props.thumbnailFileObj ?
					`url(${URL.createObjectURL(props.thumbnailFileObj)})`:
					`url(${props.displayThumbnailFileName})`
					
				}}>
				<img 
				// src={props.thumbnailFileName
				// 	? props.thumbnailFileName
				// 	: props.displayThumbnailFileName}
				src={PlusIcon} 
				alt='PlusIcon' />
			</div>
			{/* <p style={{ margin: '10px' }}>
				{props.thumbnailFileName
					? props.thumbnailFileName
					: props.displayThumbnailFileName}
			</p> */}

			{/* Start : thubmnailPopupContainer */}
			{props.is_ThubmnailPopupContainerRef_Visible && (
				<div
					ref={props.thubmnailPopupContainerRef}
					style={{
						position: 'absolute',
						top: props.thubmnailPopupContainerRef_Position.top,
						left: props.thubmnailPopupContainerRef_Position.left,
					}}
					className='thubmnailPopupContainer'>
					<div className='thubmnailPopupContainer_inner'>
						<img
							src={UploadIcon}
							id='coverImage_uploadBtn'
							onClick={(event) => props.handleFileUpload(event)}
							alt='UploadIcon'
						/>

						<p id='coverImage_name'>
							{props.thumbnailFileName !== ''
								? props.thumbnailFileName
								: 'Upload cover picture'}
						</p>

						<input
							type='file'
							className='d-none'
							name='proof'
							accept='image/jpeg,image/jpg,image/png,application/pdf'
							id='coverImage'
							onChange={(event) => props.convertToBase64(event)}
						/>
					</div>

					{props.thumbnailError && (
						<div className='alert-danger'>
							<span>Thumbnail is required* </span>
						</div>
					)}

					<div style={{ float: 'right', marginTop: '10px' }}>
						<button
							onClick={(event) => {
								props.handleThumbnailCancleBtnClick(event);
							}}
							className='cancleBtn'>
							Close
						</button>

						<button
							id='saveThumbnailBtn'
							onClick={(event) => {
								props.handleSaveThumbnail(event);
							}}
							className='saveBtn'>
							Save
						</button>
					</div>
				</div>
			)}
			{/* End : thubmnailPopupContainer */}
		</>
	);
}

export default ThumbnailPopupView;
